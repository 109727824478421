import { LeaveType, RecordStatus, ShiftStatus } from "types";

//

export const getShiftStatus = (shift: {
  target_date: string;
  starting_time: string;
  ending_time: string;
  employee_id: string;
  is_approved: boolean;
}) => {
  const { target_date, starting_time, ending_time, employee_id, is_approved } =
    shift;

  const startTime = new Date(`${target_date} ${starting_time}`).getTime();
  const endTime = new Date(`${target_date} ${ending_time}`).getTime();
  const currentTime = new Date().getTime();

  const isOngoing = startTime <= currentTime && currentTime <= endTime;

  if (!employee_id) return "unassigned";
  else if (is_approved) return "approved";
  return isOngoing ? "ongoing" : "pending";
};

export const getLeaveStatus = (request: {
  leaving_from_date: string;
  resume_date: string;
  is_approved: boolean;
}) => {
  const { leaving_from_date, resume_date, is_approved } = request;

  if (is_approved) return "approved";

  const startTime = Number(new Date(leaving_from_date));
  const endTime = Number(new Date(resume_date));
  const currentTime = Number(new Date());
  const isOngoing = startTime <= currentTime && currentTime <= endTime;

  return isOngoing ? "ongoing" : "pending";
};

export const getAvailabilityStatus = (availability: {
  availability_status: any;
}) => {
  return availability.availability_status;
}

export const getStatusColor = (status: RecordStatus) => {
  switch (status) {
    case "ongoing":
      return "#E0F2FE";
    case "pending":
      return "#FFF7ED";
    case "active":
      return "#DCFCE7";
    case "approved":
      return "#DCFCE7";
    case "unassigned":
      return "#9ca3af";
    case "Unavailable":
      return "#FEE2E2";
  }
};
export const getStatusBackgroundColor = (status: RecordStatus) => {
  switch (status) {
    case "ongoing":
      return "#0284C7";
    case "pending":
      return "#EA580C";
    case "active":
      return "#16A34A";
    case "approved":
      return "#16A34A";
    case "unassigned":
      return "#FFFFFF";
    case "Unavailable":
      return "#ed707d";
  }
};

export const getCalenderLeaveStatusTextColor = (status: LeaveType) => {
  switch (status) {
    case "sickLeave":
      return "#DC262D";
    case "personalLeave":
      return "#16A34A";
    case "annualLeave":
      return "#16A34A";
  }
};
export const getCalenderLeaveStatusBackgroundColor = (status: LeaveType) => {
  switch (status) {
    case "sickLeave":
      return "#FFEDD5";
    case "personalLeave":
      return "#DCFCE7";
    case "annualLeave":
      return "#16A34A";
  }
};

export const getCalenderShiftStatusTextColor = (status: ShiftStatus) => {
  switch (status) {
    case "rejected":
      return "#EA3C5B";
    case "unassigned":
      return "#AFB0AF";
    case "approved":
      return "#19A46A";
    case "ongoing":
      return "#007AE5";
    case "pending":
      return "#FBB328";
    case "open":
      return "#65A30D";
    case "nostart":
      return "#FBB328";
    case "earlyFinish":
      return "#0284C7";
    case "overtime":
      return "#4F46E5";
    case "earlyStart":
      return "#4F46E5";
    case "lateStart":
      return "#D97706";
    case "upcoming":
      return "#16A34A";
    case "unavailable":
      return "#4F46E5";
  }
};
export const getCalenderShiftStatusBackgroundColor = (status: ShiftStatus) => {
  switch (status) {
    case "rejected":
      return "#FFC7D1";
    case "unassigned":
      return "#F3F3F3";
    case "approved":
      return "#CEFFEA";
    case "ongoing":
      return "#CBE7FF";
    case "pending":
      return "#FFF1D4";
    case "open":
      return "#ECFCCB";
    case "nostart":
      return "#FEF3C7";
    case "earlyFinish":
      return "#E0F2FE";
    case "overtime":
      return "#E0E7FF";
    case "earlyStart":
      return "#E0E7FF";
    case "lateStart":
      return "#FEF3C7";
    case "upcoming":
      return "#DCFCE7";
    case "unavailable":
      return "#FEE2E2";
  }
};

export const dateFormatConvertor = (date: string) => {
  return `${
    date.substring(8, 10) +
    "/" +
    date.substring(5, 7) +
    "/" +
    date.substring(0, 4)
  }`;
};
