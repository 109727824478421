import { useLocation } from "react-router-dom";
import { Box, IconButton, Stack } from "@mui/material";
import {
  HeaderNotifications,
  HeaderRightMenu,
  HeaderSearch,
} from "./sub-components";
import { HamMenuIcon } from "assets/svg";

const Header = (props: { toggleSideNavbar: VoidFunction }) => {
  const { pathname } = useLocation();
  const isMobileSearchDisabled = [
    "/news-feed",
    "/profile",
    "/settings",
  ].includes(pathname);

  return (
    <Box
      component="header"
      p={{ xs: "5px 16px 16px", md: "6px 32px 30px" }}
      borderBottom="1px solid #E5E7EB"
      bgcolor="#FFFFFF" // Change background color to FFFFFF
      height="50px" // Set height to 50px
    >
      <Stack
        justifyContent="space-between"
        gap={3}
        height="100%" // Set height to 100%
      >
        <IconButton
          onClick={props.toggleSideNavbar}
          sx={{
            display: { xs: "block", md: "none" },
            width: "40px",
            height: "40px",
          }}
        >
          <HamMenuIcon />
        </IconButton>
        <Box
          display={{ xs: "none", sm: "block" }}
          flex="1"
          maxWidth={{ xs: "400px", md: "600px" }}
        >
          <HeaderSearch />
        </Box>
        <Stack
          alignItems="center"
          justifyContent="center" // Set justifyContent to center
          gap={1.25}
          height="100%" // Set height to 100%
          mt={1.5} // Add margin-top to push it lower down
        >
          <HeaderNotifications />
          <HeaderRightMenu />
        </Stack>
      </Stack>
      {isMobileSearchDisabled ? null : (
        <Box display={{ xs: "block", sm: "none" }} mt={2}>
          <HeaderSearch />
        </Box>
      )}
    </Box>
  );
};

export default Header;
