import { ReactElement, useState } from "react";
import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";

import { FormInput } from "components";
import { FormFieldCommonProps } from "types";
import {
  EmailIcon,
  OpenedEyeIcon,
  PasswordLock,
  ShowHideIcon,
} from "assets/svg";

//

type AuthInputProps = {
  label: string;
  placeholder?: string;
  endIcon?: ReactElement;
  required?: string | boolean;
  textFieldProps?: TextFieldProps;
  showHideIcon?: ReactElement;
} & FormFieldCommonProps;

export default function AuthInput({
  placeholder,
  endIcon,
  showHideIcon,
  textFieldProps,
  ...props
}: AuthInputProps) {
  return (
    <FormInput
      {...props}
      noLabelStar
      labelSx={{
        color: "#202422",
        fontSize: 15,
        lineHeight: "22px",
        mb: 1,
        ...props.labelSx,
      }}
      textFieldProps={{
        sx: {
          "& .MuiInputBase-root": {
            backgroundColor: "#F6F6F6",
            borderRadius: "10px",
            "&.Mui-error": {
              backgroundColor: "#F6F6F6",
            },
          },
          "& input": {
            height: 50,
            boxSizing: "border-box",
          },
          "& fieldset": {
            borderColor: "#EDEDED",
            borderRadius: "10px",
          },
        },
        ...textFieldProps,
        placeholder,
        InputProps: {
          startAdornment: (
            <InputAdornment sx={{}} position="start">
              {endIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{showHideIcon}</InputAdornment>
          ),
          ...textFieldProps?.InputProps,
        },
      }}
    />
  );
}

//

export const EmailInput = ({ label }: { label?: string }) => (
  <AuthInput
    name="email"
    endIcon={<EmailIcon />}
    label={label || "Email"}
    required="Email Required"
    textFieldProps={{ type: "email" }}
    placeholder="Enter Email Address"
    // inputSx={{ px: 0 }}
  />
);

//

export const PasswordInput = ({
  isConfirm,
  validations,
  topSpacing,
}: {
  isConfirm?: boolean;
  validations?: any;
  topSpacing?: number;
}) => {
  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
  //
  return (
    <AuthInput
      name={isConfirm ? "confirm_password" : "password"}
      label={isConfirm ? "Confirm Password" : "Password"}
      textFieldProps={{
        type: isPasswordVisible ? "text" : "password",
        autoComplete: "false",
      }}
      validations={{
        minLength: { value: 8, message: "minumum 8 charachters required" },
        ...validations,
      }}
      labelSx={{ mt: topSpacing || 0 }}
      required="Password required"
      placeholder="Enter Password..."
      showHideIcon={
        <IconButton
          aria-label="toggle password visibility"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => setPasswordVisibility((prev) => !prev)}
          sx={{ p: 0, svg: { height: "auto" } }}
        >
          {isPasswordVisible ? <ShowHideIcon /> : <OpenedEyeIcon />}
        </IconButton>
      }
      endIcon={<PasswordLock />}
    />
  );
};
