import { ReactElement, ReactNode } from "react";
import { useMutation } from "@tanstack/react-query";
import { Box, Grid, Stack, SxProps, Typography } from "@mui/material";
import { Pagination, Controller, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { GoogleLoginBtn } from "components";
import { signInWithGoogle } from "api/auth";
import "styles/auth-layout.scss";
import { MinBrandLogoNew } from "assets/svg";
import AuthSlideImg6 from "assets/images/auth-layout-slide-img-6.png";
import AuthSlideImg7 from "assets/images/auth-layout-slide-img-7.png";
import GoBack from "assets/images/go-back.png";
import BackgroundTexture from "assets/images/BackgroundTexture.png";
//
type SliderInfoBlockProps = {
  desc: string;
  title: string;
  rootSx?: SxProps;
  descMaxW?: number;
  titleMaxW?: number;
  icon?: ReactElement;
  bottomSpacing?: number;
};
const SliderInfoBlock = ({
  title,
  desc,
  titleMaxW,
  descMaxW,
  bottomSpacing,
  icon,
  rootSx,
}: SliderInfoBlockProps) => (
  <Stack
    direction="column"
    alignItems="center"
    color="white"
    textAlign="center"
    pb={bottomSpacing || 5}
    px={2}
    sx={rootSx}
  >
    {icon || <></>}
    <Typography
      maxWidth={titleMaxW || "none"}
      fontSize={25}
      fontWeight={700}
      lineHeight="35px"
    >
      {title}
    </Typography>
    <Typography maxWidth={descMaxW || "none"} fontSize={12} mt={1}>
      {desc}
    </Typography>
  </Stack>
);
//
type LayoutProps = {
  heading?: string;
  isLogin?: boolean;
  isForgot?: boolean;
  canGoBack?: boolean;
  layoutMaxW?: number;
  children: ReactElement;
  isRecentLogin?: boolean;
  hideGmailBlock?: boolean;
  subHeading?: string | ReactNode;
  goBackHandler?: VoidFunction | null;
  formStep?: number;
  isAdmin?: boolean;
  incrementFormSteps?: VoidFunction | null;
  setBlockNextStep?: VoidFunction | null;
};
const AuthLayout = (props: LayoutProps) => {
  const { mutate: mutateGoogleLogin } = useMutation(signInWithGoogle);
  const clickHandler = () => {
    props.incrementFormSteps && props.incrementFormSteps();
    props.setBlockNextStep && props.setBlockNextStep();
  };
  //
  return (
    <Box bgcolor={"#fefefe"} sx={{ pt: "1.5vh" }}>
      <Box
        width={"70%"}
        margin={"0 auto"}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={5}
            sx={{
              bgcolor: "white",
              borderRadius: "16px 16px 16px 16px",
              height: "97vh",
              mb: "1vh",
              overflow: "auto",
              boxShadow: "10px 10px 10px 10px #f5f5f5",
              "&::-webkit-scrollbar": { display: "none" },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            <Box alignItems={"center"} width="70%" margin={"0 auto"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "100%",
                  paddingTop: "20px",
                }}
              >
                {!props.isRecentLogin && props.canGoBack && (
                  <>
                    <Typography
                      onClick={() =>
                        props.goBackHandler && props.goBackHandler()
                      }
                      {...(props.goBackHandler
                        ? {}
                        : { component: Link, to: "/auth/sign-in" })}
                      sx={{
                        width: "fit-content",
                        color: "#202422",
                        display: "flex",
                        cursor: "pointer",
                        mb: 1,
                      }}
                    >
                      <Box component="img" src={GoBack} alt="" />
                      {"Back"}
                    </Typography>
                    {!props.isAdmin && props.formStep === 1 ? (
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => clickHandler()}
                      >
                        Skip
                      </Typography>
                    ) : null}
                  </>
                )}
              </Box>
              <Box>
                <Stack
                  direction={{ xs: "column-reverse", sm: "column" }}
                  mb={props.canGoBack ? 0.625 : 3.75}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <MinBrandLogoNew viewBox="0 0 130 14" height="105" />
                </Stack>
                {props.isRecentLogin ? null : (
                  <>
                    <Typography
                      variant="h1"
                      fontSize={{ sm: 14 }}
                      fontWeight={500}
                      color="#007AE5"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "5px",
                      }}
                    >
                      {props.isLogin ? "Welcome Back!" : ""}
                    </Typography>
                    <Typography
                      variant="h1"
                      fontSize={!props.isLogin ? 26 : { xs: 28, sm: 22 }}
                      lineHeight={1.867}
                      fontWeight={700}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "20px",
                        marginTop: "10px",
                      }}
                    >
                      {props.heading ||
                        (props.isLogin ? "Login with your email" : "Sign Up")}
                    </Typography>
                    {!props.isLogin ? (
                      <Typography
                        color={"#484B4A"}
                        variant="h1"
                        fontSize={16}
                        fontWeight={400}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingLeft: "20px",
                        }}
                      >
                        {"Choose your account type"}
                      </Typography>
                    ) : null}
                  </>
                )}
              </Box>
              {props.children}
              {props.hideGmailBlock ? null : (
                <>
                  <Typography
                    color="rgba(0, 0, 0, 0.6)"
                    fontSize={14}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "16px",
                    }}
                  >
                    {props.isLogin ? "Or Login" : "Sign Up"} with
                  </Typography>
                  <GoogleLoginBtn
                    text="Continue With Google"
                    mutate={mutateGoogleLogin}
                  />
                  {/* <Stack alignItems="center" gap={0.375} mt={2}>
												 <Box flex={1} height={2} bgcolor="#818393" />
												<Box flex={1} height={2} bgcolor="#818393" /> 
											</Stack> */}
                  <Typography
                    color="#202422"
                    fontSize={14}
                    mt={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    Don't have an account?
                    <Box
                      //temporary fix cant get Space to work.
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: 600,
                        textDecoration: "underline",
                      }}
                    >
                      {"I"}
                    </Box>
                    <Box
                      component={Link}
                      to="/auth/sign-up"
                      sx={{
                        color: "#007AE5",
                        fontWeight: 600,
                        textDecoration: "underline",
                      }}
                    >
                      Sign Up
                    </Box>
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              position: "relative",
              height: "97vh",
              mb: "1vh",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
              borderRadius: "16px 16px 16px 16px",
              overflow: "hidden",
            }}
          >
            <Box sx={{}}>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 30,
                  right: 0,
                  bottom: 0,
                  background: "linear-gradient(to bottom, #007AE5, #52AEFF)",
                  borderRadius: "16px 16px 16px 16px",
                  zIndex: 0,
                }}
              />
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 16,
                  right: 0,
                  bottom: 0,
                  backgroundImage: `url(${BackgroundTexture})`,
                  backgroundSize: "cover",
                  backgroundPosition: "right",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "16px 16px 16px 16px",
                  zIndex: 1,
                  transform: "translateX(-60px)",
                }}
              />
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 16,
                  right: 0,
                  bottom: 0,
                  backgroundImage: BackgroundTexture,
                  backgroundSize: "cover",
                  backgroundPosition: "right",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "16px 16px 16px 16px",
                  zIndex: 1,
                }}
              />
            </Box>
            {/* <Swiper
              slidesPerView={1}
              autoplay={{ disableOnInteraction: false }}
              modules={[Controller, Pagination, Autoplay]}
              pagination={{ dynamicBullets: true, clickable: true }}
              navigation
            >
              <SwiperSlide>
                <Box
                  component="img"
                  src={AuthSlideImg6}
                  alt=""
                  sx={{ maxHeight: "100%", marginRight: "0px" }}
                />
                <SliderInfoBlock
                  // descMaxW={420}
                  title="Take Control of Your Schedule."
                  rootSx={{
                    svg: { fill: "white", width: 40, height: "auto", },
                  }}
                  desc=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  component="img"
                  src={AuthSlideImg7}
                  alt=""
                  sx={{ maxHeight: "100%", marginRight: "0px" }}
                />
                <SliderInfoBlock
                  // descMaxW={420}
                  title="Take Control of Your Schedule."
                  rootSx={{
                    svg: { fill: "white", width: 40, height: "auto",},
                  }}
                  desc=""
                />
              </SwiperSlide>
            </Swiper> */}
            <Swiper
              slidesPerView={1}
              autoplay={{ disableOnInteraction: false }}
              modules={[Controller, Pagination, Autoplay]}
              pagination={{ dynamicBullets: true, clickable: true }}
              navigation
            >
              <SwiperSlide>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    height: "100%",
                  }}
                >
                  <img
                    src={AuthSlideImg6}
                    alt=""
                    style={{ maxHeight: "100%", width: "auto" }}
                  />
                </Box>
                <SliderInfoBlock
                  title="Take Control of Your Schedule."
                  rootSx={{
                    svg: { fill: "white", width: 40, height: "auto" },
                  }}
                  desc=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    height: "100%",
                  }}
                >
                  <img
                    src={AuthSlideImg7}
                    alt=""
                    style={{ maxHeight: "100%", width: "auto" }}
                  />
                </Box>
                <SliderInfoBlock
                  title="Take Control of Your Schedule."
                  rootSx={{
                    svg: { fill: "white", width: 40, height: "auto" },
                  }}
                  desc=""
                />
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default AuthLayout;
