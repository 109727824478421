import { useState } from "react";
import { Box, Button, Stack, TextField, Tooltip, Typography } from "@mui/material";

import { BadgeBtn } from "sections";

//

type BadgesBlockProps = {
	label: string;
	list: string[] | null;
	addBadge: (item: string) => void;
	removeBadge: (item: string) => void;
};

const BadgesBlock = ({ list, label, addBadge, removeBadge }: BadgesBlockProps) => {
	const [newBadge, setNewBadge] = useState<string>("");
	const [isCreating, setCreating] = useState<boolean>(false);

	return (
		<Box gridColumn={{ sm: "span 2 / span 2" }}>
			<Stack alignItems="center" justifyContent="space-between" gap={2} flexWrap="wrap">
				<Typography sx={{ fontSize: 14, color: "#202422", lineHeight: "18px", mb: 1 }}>{label}</Typography>

				{isCreating ? null : (
					<Tooltip title={`Let the employers know about your ${label}`} placement="left" arrow>
						<Button
							size="small"
							variant="text"
							onClick={() => setCreating(true)}
							sx={{ p: 0, ":hover": { textDecoration: "underline", bgcolor: "transparent" } }}
						>
							Add New
						</Button>
					</Tooltip>
				)}
			</Stack>

			{isCreating && (
				<Box maxWidth={400} bgcolor="#f4f4f4" p={1.4} borderRadius="8px">
					<TextField
						value={newBadge}
						placeholder={`Add a ${label?.slice(0, -1)}...`}
						onChange={(e) => setNewBadge(e.currentTarget.value)}
						sx={{ input: { height: 40, py: 0, bgcolor: "white" } }}
					/>

					<Stack justifyContent="center" gap={1} mt={2} sx={{ button: { py: 0.45, borderRadius: "4px" } }}>
						<Button
							fullWidth
							variant="outlined"
							onClick={() => {
								setCreating(false);
								setNewBadge("");
							}}
						>
							Cancel
						</Button>
						<Button
							fullWidth
							variant="contained"
							onClick={() => {
								addBadge(newBadge);
								setNewBadge("");
								setCreating(false);
							}}
						>
							Add
						</Button>
					</Stack>
				</Box>
			)}

			<Stack flexWrap="wrap" gap="5px">
				{list?.map((item, i) => (
					<BadgeBtn key={i} name={item} onRemove={() => removeBadge(item)} />
				))}
			</Stack>
		</Box>
	);
};

export default BadgesBlock;
