import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowSelectionModel,
  gridClasses as gCl,
} from "@mui/x-data-grid";
import {
  Box,
  Paper,
  Stack,
  Button,
  Typography,
  buttonClasses,
  OutlinedInput,
  svgIconClasses,
  checkboxClasses,
  inputBaseClasses,
  iconButtonClasses,
  typographyClasses,
  inputAdornmentClasses,
  Select,
  MenuItem,
  FormControl,
  TextFieldProps,
  TextField,
} from "@mui/material";
import { SkeletonLoading } from "components";
import { formatStr } from "utils/data-helpers";
import { PlusIcon } from "assets/svg";
import Filter from "assets/images/filter.png";
import ActiveFilter from "assets/images/active-filter.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
type FilterChangeParams = { name: string; value: string | number };
type TableFilter = { label?: string; code: string; value?: any };
type TablePropTypes = {
  bodyRows: any[];
  isLoading: boolean;
  headers: GridColDef[];
  rootMinWidth?: number;
  filtersList: (TableFilter | string)[];
  rowClickHandler?: GridEventListener<"rowClick">;
  selectionRejectHandler?: (param: number[]) => void;
  selectionApproveHandler?: (param: number[]) => void;
  setEditModal1?: (value: boolean) => void;
  pendingLeaves?: number;
};
//
const StyledDataGrid = styled(DataGrid)(() => ({
  border: "none",
  overflow: "auto",
  [`.${gCl.main}`]: { minWidth: 1000 },
  [`.${gCl.columnHeaders}`]: {
    paddingLeft: 0,
    backgroundColor: "#007AE5",
    color: "white",
  },
  [`.${gCl.columnHeader}`]: { [`.MuiCheckbox-root`]: { color: "white" } },
  [`.${gCl.columnHeaderCheckbox} > *`]: {},
  [`.${gCl.cell}.email-cell`]: { textDecoration: "underline" },
  [`.${gCl.columnHeaderTitle}`]: { fontSize: 14, fontWeight: 600 },
  [`.${gCl.virtualScrollerRenderZone}, .${gCl.row}`]: { width: "100%" },
  [`.${checkboxClasses.root} .${svgIconClasses.root}`]: {
    width: 17,
    height: 17,
  },
  [`.${gCl.columnHeader}, .${gCl.cellCheckbox}, .${gCl.cell}`]: {
    ":focus, :focus-within": { outline: "none" },
  },
  [`.${gCl.columnHeaderCheckbox}, .${gCl.cellCheckbox}`]: {
    // minWidth: "36px !important",
    // width: "36px !important",
  },
  [`.${gCl.cell}`]: {
    "&.name-cell": { fontWeight: 600, color: "#253334", paddingLeft: 0 },
    "&:not(.name-cell)": {
      fontSize: 13.1,
      color: "#464255",
    },
  },
  [`.${gCl.iconButtonContainer}`]: {
    width: "auto",
    visibility: "visible",
    [`.${iconButtonClasses.root}`]: {
      padding: 0,
      marginLeft: 10,
      color: "white",
      [`.${gCl.sortIcon}`]: { opacity: 1, width: 22, height: 22 },
    },
  },
}));
const FilterField = ({ label, ...props }: any) => {
  const isDropdown = props.code === "type";
  const isDate = props.code === "from" || props.code === "to";
  return (
    <Box sx={{ display: "inline-flex", alignItems: "center", mr: 4, my: 2.5 }}>
      <Box>
        <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
          {label}
        </Typography>
        {isDate && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...props}
              renderInput={(inputProps: TextFieldProps) => (
                <TextField
                  {...inputProps}
                  error={false}
                  sx={{
                    pl: 0,
                    flex: 1,
                    fontSize: 14,
                    borderRadius: 2,
                    fieldset: {},
                    [`.${inputBaseClasses.input}`]: {
                      minWidth: 200,
                      padding: "10px 10px",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )}
        {isDropdown && (
          <>
            <FormControl>
              <Select
                notched={false}
                {...props}
                value={props.value || ""}
                label={label}
                displayEmpty
                placeholder={`Select ${label}`}
                aria-describedby={`data-table-column-${label}-filter`}
                inputProps={{
                  "aria-label": `data-table-column-${label}-filter`,
                }}
                sx={{
                  pl: 0,
                  flex: 1,
                  fontSize: 14,
                  borderRadius: 2,
                  fieldset: { border: "1px solid #D9D9D9" },
                  [`.${inputBaseClasses.input}`]: {
                    minWidth: 200,
                    padding: "10px 10px",
                  },
                }}
              >
                <MenuItem disabled value="">
                  Please Select
                </MenuItem>
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {!isDropdown && !isDate && (
          <FormControl>
            <OutlinedInput
              {...props}
              placeholder={`Enter ${label}`}
              label={label}
              notched={false}
              aria-describedby={`data-table-column-${label}-filter`}
              inputProps={{ "aria-label": `data-table-column-${label}-filter` }}
              sx={{
                pl: 0,
                flex: 1,
                fontSize: 14,
                borderRadius: 2,
                fieldset: { border: "1px solid #D9D9D9" },
                [`.${inputBaseClasses.input}`]: {
                  minWidth: 200,
                  height: 40,
                  padding: "0 10px",
                },
                [`.${inputAdornmentClasses.root}`]: {
                  mr: 0,
                  px: 1.5,
                  height: 1,
                  bgcolor: "#FAFAFA",
                  [`.${typographyClasses.root}`]: {
                    fontSize: 14,
                    color: "#595959",
                    textTransform: "capitalize",
                  },
                },
              }}
            />
          </FormControl>
        )}
      </Box>
    </Box>
  );
};
const ColumnFiltersBlock = (props: {
  filters: TableFilter[];
  filterChangeHandler: (params: FilterChangeParams) => void;
  searchHandler: () => void;
  setRowsListing1: () => void;
  setFilters1: () => void;
}) => (
  <Box>
    {props.filters.map(({ code, label, value }) => (
      <FilterField
        key={code}
        value={value}
        code={code}
        label={label || code}
        onChange={(e: any) => {
          let val;
          switch (code) {
            case "type":
              val = e.target.value;
              break;
            case "from":
            case "to":
              val = dayjs(e.$d).format("MM/DD/YYYY");
              break;
            default:
              val = e.currentTarget.value;
              break;
          }
          props.filterChangeHandler({
            name: code,
            value: val,
          });
        }}
      />
    ))}
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        gap: 1,
      }}
    >
      <Button
        color="inherit"
        variant="outlined"
        sx={{ color: "#007AE5", borderColor: "#007AE5", fontWeight: 400 }}
        onClick={() => {
          props.setRowsListing1();
          props.setFilters1();
        }}
      >
        Clear
      </Button>
      <Button
        variant="contained"
        sx={{ fontWeight: 400 }}
        onClick={props.searchHandler}
      >
        Search
      </Button>
    </Box>
  </Box>
);
export const LeaveRequestTable = ({
  filtersList,
  headers,
  bodyRows,
  rowClickHandler,
  isLoading,
  selectionApproveHandler,
  selectionRejectHandler,
  setEditModal1,
  pendingLeaves,
}: TablePropTypes) => {
  const refinedFilters = useMemo(() => {
    return filtersList.map((el) => {
      return typeof el === "string"
        ? { code: el, value: "" }
        : { ...el, value: null };
    });
  }, [filtersList]);
  // const tableRef = useGridApiRef();
  const [showDiv, setShowDiv] = useState(false);
  const [rowsListing, setRowsListing] = useState<any[]>([]);
  const [filters, setFilters] = useState<TableFilter[]>(refinedFilters);
  const [selectedRowIdList, setSelectedRowIdList] =
    useState<GridRowSelectionModel>([]);
  useEffect(() => {
    if (bodyRows.length > 0) {
      setRowsListing(bodyRows);
    }
  }, [bodyRows, bodyRows.length]);
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };
  const skeletonProps = useMemo(() => {
    return {
      height: 40,
      width: 300,
    };
  }, []);
  const filterChangeHandler = (params: FilterChangeParams) => {
    const { name, value } = params;
    if (name === "from" || name === "to") {
      setFilters((prev) => {
        const update = [...prev];
        const matchIndex = update.findIndex((el) => el.code === name);
        if (matchIndex !== -1) {
          update[matchIndex] = { ...update[matchIndex], value };
        } else {
          update.push({ code: name, value });
        }
        return update;
      });
    } else {
      setFilters((prev) => {
        const update = prev.map((el) =>
          el.code === name ? { ...el, value } : el
        );
        return update;
      });
    }
  };
  const setRowsListing1 = () => {
    setRowsListing(bodyRows);
  };
  const setFilters1 = () => {
    setFilters(refinedFilters);
  };
  const searchHandler = () => {
    const noFilterAdded = filters.every(({ value }) => !value);
    if (noFilterAdded) setRowsListing(bodyRows);
    else if (bodyRows.length > 0) {
      setRowsListing(() => {
        return bodyRows.filter((row) => {
          return Object.entries(row).some(([key, value]) => {
            return filters.some(
              (filter) =>
                filter.code === key &&
                filter.value &&
                formatStr(value).includes(formatStr(filter.value))
            );
          });
        });
      });
    }
  };
  return (
    <>
      <Box
        elevation={0}
        component={Paper}
        sx={{
          position: "relative",
          borderRadius: "20px",
          overflow: "hidden",
          px: 3,
          py: 3,
          mb: 3,
        }}
      >
        <Stack
          flexWrap="wrap"
          className="top-header-block"
          justifyContent="space-between"
          sx={{
            rowGap: 1.5,
            columnGap: 1,
            [`.${buttonClasses.root}`]: {
              height: 32,
              borderRadius: "10px",
              lineHeight: 0.8,
            },
          }}
        >
          <Stack flexWrap="wrap" alignItems="center" columnGap={2} rowGap={0.5}>
            <Box>
              <Typography
                variant="h2"
                fontSize={24}
                fontWeight={700}
                lineHeight="32px"
              >
                Employee Leave Request
              </Typography>
              <Typography
                variant="h2"
                fontSize={16}
                fontWeight={400}
                lineHeight="32px"
                color="#EF4444"
              >
                {pendingLeaves !== undefined && pendingLeaves > 0
                  ? `${pendingLeaves} Leave request pending`
                  : null}
              </Typography>
            </Box>
          </Stack>
          <Stack gap={0} justifyContent={"center"} alignItems={"center"}>
            {selectedRowIdList.length > 1 &&
            selectionApproveHandler &&
            selectionRejectHandler ? (
              <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
                <Typography variant="h6" fontSize={14}>
                  {`${selectedRowIdList.length} Selected`}{" "}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() =>
                    selectionApproveHandler(selectedRowIdList as number[])
                  }
                  sx={{
                    bgcolor: "#BBF7D0",
                    color: "#16A34A" ,
                    ":hover": {boxShadow: 'none',  bgcolor: "#127c50", color: "#BBF7D0"},
                    fontSize: 12,
                    fontWeight: 500,
                    py: 2.4,
                    boxShadow: 'none', 
                  }}
                  // startIcon={
                  //   <Box sx={{ svg: { width: 15, height: "auto" } }}>
                  //     <TickIcon />
                  //   </Box>
                  // }
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    selectionRejectHandler(selectedRowIdList as number[])
                  }
                  sx={{
                    bgcolor: "#FFEDD5",
                    color: "#EA580C",
                    ":hover": { boxShadow: 'none', bgcolor: "#EA580C" ,color: "#FFEDD5" },
                    fontSize: 12,
                    fontWeight: 500,
                    py: 2.4,
                    boxShadow: 'none', 
                  }}
                  // startIcon={
                  //   <Box sx={{ svg: { width: 15, height: "auto" } }}>
                  //     <CrossIcon />
                  //   </Box>
                  // }
                >
                  Reject
                </Button>
              </Stack>
            ) : null}
            <Box
              onClick={() => toggleDiv()}
              sx={{
                cursor: "pointer",
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mx: 1,
              }}
            >
              {showDiv ? (
                <img src={ActiveFilter} alt="filterIcon" />
              ) : (
                <img src={Filter} alt="filterIcon" />
              )}
            </Box>
            <Button
              onClick={() => {
                if (setEditModal1) {
                  setEditModal1(true);
                }
              }}
              size="large"
              variant="outlined"
              sx={{
                color: "#FFFFFF",
                fontWeight: 600,
                py: 2.2,
                backgroundColor: "#000000",
                borderColor: "#000000",
                "&:hover": {
                  backgroundColor: "#333333",
                  borderColor: "#333333"
                },
              }}
              startIcon={<PlusIcon viewBox="0 0 24 24" />}
            >
              Create New Leave
            </Button>
          </Stack>
        </Stack>
        {showDiv && (
          <ColumnFiltersBlock
            {...{
              filters,
              filterChangeHandler,
              searchHandler,
              setRowsListing1,
              setFilters1,
            }}
          />
        )}
      </Box>
      <Box
        elevation={0}
        component={Paper}
        sx={{ position: "relative", borderRadius: "5px", overflow: "hidden" }}
      >
        <Box className="header" px={{ xs: 2, sm: 3.75 }}></Box>
        <StyledDataGrid
          autoHeight
          columns={headers}
          rows={rowsListing}
          columnHeaderHeight={52}
          onRowClick={rowClickHandler}
          rowCount={rowsListing?.length}
          rowSelectionModel={selectedRowIdList}
          initialState={{
            pagination: { paginationModel: { pageSize: 25, page: 0 } },
          }}
          onRowSelectionModelChange={setSelectedRowIdList}
          hideFooter={rowsListing?.length <= 10}
          disableRowSelectionOnClick
          paginationMode="client"
          pageSizeOptions={[25]}
          checkboxSelection
          disableColumnMenu
          rowHeight={isLoading ? 260 : 47}
          pagination
          slots={{
            columnSortedDescendingIcon: HiChevronUp,
            columnSortedAscendingIcon: HiChevronDown,
            noRowsOverlay: () =>
              isLoading || !rowsListing ? (
                <Stack
                  alignItems="center"
                  justifyContent="between"
                  gap={3}
                  ml={2}
                >
                  {["Name", "From", "To", "Type", "Status"].map(
                    (item, index1) => {
                      return (
                        <Box key={index1} textAlign={"center"}>
                          <SkeletonLoading {...skeletonProps} count={10} />
                        </Box>
                      );
                    }
                  )}
                </Stack>
              ) : (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nothing Found Here
                </Stack>
              ),
          }}
        />
      </Box>
    </>
  );
};