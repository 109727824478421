// import { useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { Database } from "./supabase-types";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// const SupabaseClientProvider = () => {
// 	const [rememberUser, setRememberUser] = useState<boolean>(false);
// };

const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
	realtime: { params: { eventsPerSecond: 10 } },
	auth: { persistSession: true },
});

// supabase
//   .channel('shift_approval')
//   .on('postgres_changes', { event: '*', schema: 'public', table: 'shifts' }, payload => {
//     console.log('Change received!', payload)
//   })
//   .subscribe()

export default supabase;
