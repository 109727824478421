import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, UseMutateFunction } from "@tanstack/react-query";
import {
  Grid,
  Stack,
  Button,
  Checkbox,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Dialog,
} from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs, { Dayjs } from "dayjs";
import "styles/quill.scss";
import {
  MapToDisplay,
  DateTimePicker,
  FormInput,
  SelectField,
  LoadingOverlay,
} from "components";
import {
  getFullName,
  nullifyEmptyStrings,
  timeWithDayjs,
} from "utils/data-helpers";
import { ShiftDefaults } from "constants/FormDefaultValues";
import { getUpdateValues } from "utils/api-helpers";
import { getAllEmployees } from "api/employee";
import { getEmployeeShifts } from "api/shifts";
import { UserContext } from "context/UserContext";
import { postShift } from "api/shifts";
import { Shift } from "types";


type PropTypes = {
  isOpen: boolean;
  isLoading?: boolean;
  mustAssign?: boolean;
  postHandler?: Function;
  closeModal: VoidFunction;
  refetchListing?: Function;
  shiftToEdit?: Shift | null;
  updateHandler?: UseMutateFunction<any, unknown, { id: number; props: any }>;
  selectedEmployee?: string;
};

export const CompanyShiftEditModal = (props: PropTypes) => {
  const { id: uid, company_id } = useContext(UserContext) || {};
  const [mapLocation, setMapLocation] = useState<any | null>(null);
  const form = useForm<Shift>({ defaultValues: ShiftDefaults });
  const selectedEmployeeId = form.watch("employee_id");

  const { data: employeeShifts } = useQuery(
    ["employeeShifts", selectedEmployeeId],
    () => getEmployeeShifts(selectedEmployeeId),
    {
      enabled: !!selectedEmployeeId,
    }
  );

  const filteredShiftsByTargetDate = useMemo(() => {
    return (
      employeeShifts?.map((shift) => ({ target_date: shift.target_date })) || []
    );
  }, [employeeShifts]);

  useEffect(() => {
    if (props.shiftToEdit) form.reset(props.shiftToEdit);
    if (props.selectedEmployee)
      form.setValue("employee_id", props.selectedEmployee);
  }, [props.shiftToEdit, props.selectedEmployee]);

  useEffect(() => {
    if (props.selectedEmployee)
      form.setValue("employee_id", props.selectedEmployee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, props.selectedEmployee, props.shiftToEdit]);

  const isEmployeeSelected = !!form.watch("employee_id");

  const closeAndReset = () => {
    form.reset(ShiftDefaults);
    setMapLocation(null);
    props.closeModal();
    if (props.selectedEmployee)
      form.setValue("employee_id", props.selectedEmployee);
  };

  const { data: employeesList, isInitialLoading: areEmployeesLoading } =
    useQuery({ queryFn: () => getAllEmployees({}) });

  const employeeSelectOptions =
    employeesList?.map((e) => ({
      label: getFullName(e?.profile),
      value: e?.profile?.id,
    })) || [];

  const { mutate: mutatePost, isLoading: isPostLoading } = useMutation(
    postShift,
    {
      onSuccess: () => {
        if (props.refetchListing instanceof Function) props.refetchListing();
        closeAndReset();
      },
    }
  );

  useEffect(() => {
    if (props.shiftToEdit) {
      const { location, latitude, longitude } = props.shiftToEdit;
      setMapLocation({
        place_name: location,
        geometry: { coordinates: [longitude, latitude] },
      });
    }
  }, [props.shiftToEdit]);

  const submitHandler = ({ employee, breaks, ...vals }: Shift) => {
    if (!mapLocation || mapLocation?.error) {
      setMapLocation({ error: true });
      return null;
    }

    const shiftDuration =
      (Number(timeWithDayjs(vals?.ending_time)) -
        Number(timeWithDayjs(vals?.starting_time))) /
      1000 /
      60 /
      60;

    const payload = {
      company_id,
      ...nullifyEmptyStrings(vals),
      location: mapLocation?.place_name?.trim(),
      latitude: mapLocation?.geometry?.coordinates?.[1],
      longitude: mapLocation?.geometry?.coordinates?.[0],
      target_date: dayjs(vals?.target_date).format("YYYY-MM-DD"),
      total_price: (vals?.hourly_charge * shiftDuration).toFixed(2),
      ending_time: timeWithDayjs(vals?.ending_time)?.format("HH:mm:ss"),
      starting_time: timeWithDayjs(vals?.starting_time)?.format("HH:mm:ss"),
      assign_user_id: uid,
    };
    if (props.shiftToEdit && props.updateHandler) {
      props.updateHandler(
        {
          id: props.shiftToEdit?.id,
          props: getUpdateValues(props.shiftToEdit, payload),
        },
        { onSettled: () => closeAndReset() }
      );
    } else if (props.postHandler) {
      props.postHandler(payload);
      closeAndReset();
    } else mutatePost(payload);
  };

  return (
    <Dialog
  open={props.isOpen}
  onClose={closeAndReset}
  fullWidth
  maxWidth="md"
  PaperProps={{
    sx: {
      borderRadius: '15px', // Adjust this value as needed
    },
  }}
>
      <Stack justifyContent="space-between">
        <Stack alignItems="center" gap={1.5} sx={{ px: 4.25, pt: 3.25 }}>
          <Typography variant="h5" fontSize="1.5rem" fontWeight={700}>
            Create Shifts
          </Typography>
          <Typography
            fontSize="1rem"
            fontWeight={600}
            color="primary.main"
            sx={{ cursor: "pointer" }}
            onClick={() => form?.reset()}
          >
            Clear
          </Typography>
        </Stack>
        <IconButton
          onClick={closeAndReset}
          sx={{ height: "30px", fontSize: "20px", p: 0.5, mt: 1, mr: 1 }}
        >
          <MdOutlineClose />
        </IconButton>
      </Stack>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)}>
          <DialogContent
            sx={{
              pt: 1.5,
              px: 4.25,
              overflow: "hidden",
              fieldset: { borderRadius: "10px" },
              ".MuiFormLabel-asterisk": { display: "none" },
              label: { fontSize: 18, fontWeight: 600, mb: 0.65 },
            }}
          >
            {/* Shift Details */}
            <Stack direction="column" spacing={2}>
              <FormInput required name="title" label="Shift Title" />
              <DateTimePicker
                name="target_date"
                label="When"
                filteredShiftsByTargetDate={filteredShiftsByTargetDate}
              />
              <Grid
                container
                columns={5}
                spacing={3}
                sx={{
                  mb: 2,
                  ".MuiInputBase-root": {
                    pr: 3,
                    ".MuiInputBase-input": { pl: 2.5 },
                    "svg.MuiSelect-icon": { right: 17, fontSize: "22px" },
                  },
                }}
              >
                <Grid item xs={2}>
                  <Stack direction="column" spacing={2}>
                    <DateTimePicker
                      name="starting_time"
                      label="Start Time"
                      onlyPickTime
                    />
                    <DateTimePicker
                      onlyPickTime
                      name="ending_time"
                      label="Finish Time"
                      validations={{
                        validate: (val: Dayjs) => {
                          const startingTime = Number(
                            form.watch("starting_time")
                          );
                          return startingTime
                            ? Number(val) > startingTime ||
                                "Must be after starting time"
                            : true;
                        },
                      }}
                    />
                    <FormInput
                      required
                      name="break_allowance"
                      label="Break Allowance (min)"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <MapToDisplay
                    selectedResult={mapLocation}
                    setSelectedResult={setMapLocation}
                  />
                </Grid>
              </Grid>
            </Stack>

            {/* Pay Rate and Approval */}
            <Stack direction="column" spacing={2}>
              <FormInput required name="hourly_charge" label="Pay Rate ($/hr)" />
              <Stack alignItems="center" gap={3}>
                <SelectField
                  label="Employee"
                  name="employee_id"
                  options={employeeSelectOptions}
                  required={!!props.mustAssign}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      {...form.register("is_approved")}
                      disabled={!isEmployeeSelected}
                    />
                  }
                  label={`Is Approved ${
                    isEmployeeSelected ? "" : "(Select Employee)"
                  }`}
                  sx={{ display: "block", width: 200, pt: 3.65 }}
                  labelPlacement="start"
                />
              </Stack>
            </Stack>

            {/* Additional Notes */}
            <Stack direction="column" spacing={2}>
              <ReactQuill
                theme="snow"
                value={form.watch("manager_note") || undefined}
                onChange={(val) => form.setValue("manager_note", val)}
              />
            </Stack>

            {/* Error Handling for Map Location */}
            {mapLocation?.error && (
              <Typography color="error">
                Must search and select a location from the map...
              </Typography>
            )}
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "flex-start", px: 4.25, pb: 3.75 }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: 300,
                height: 60,
                fontSize: 20,
                borderRadius: "10px",
                textTransform: "capitalize",
                border: mapLocation?.error ? "3px solid #f06363" : "none",
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>

      <LoadingOverlay
        open={props.isLoading || isPostLoading || areEmployeesLoading}
      />
    </Dialog>
  );
};
