import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowSelectionModel,
  gridClasses as gCl,
} from "@mui/x-data-grid";
import {
  Box,
  Paper,
  Stack,
  Button,
  Typography,
  buttonClasses,
  OutlinedInput,
  svgIconClasses,
  checkboxClasses,
  inputBaseClasses,
  iconButtonClasses,
  typographyClasses,
  OutlinedInputProps,
  inputAdornmentClasses,
} from "@mui/material";
import { PopoverMenu, SkeletonLoading } from "components";
import Filter from "assets/images/filter.png";
import ActiveFilter from "assets/images/active-filter.png";
import AddUserIcon from "assets/svg/add-user-icon.svg";
import { formatStr } from "utils/data-helpers";
import { TickIcon, CrossIcon, InviteUserIcon } from "assets/svg";
import { InviteUserModal } from "sections";
//
type FilterChangeParams = { name: string; value: string | number };
type TableFilter = { label?: string; code: string; value?: string | number };
type TablePropTypes = {
  bodyRows: any[];
  isLoading: boolean;
  headers: GridColDef[];
  rootMinWidth?: number;
  filtersList: (TableFilter | string)[];
  rowClickHandler?: GridEventListener<"rowClick">;
  selectionRejectHandler?: (param: number[]) => void;
  selectionApproveHandler?: (param: number[]) => void;
  setCreateModal1?: (value: boolean) => void;
  setInviteModal1?: (value: boolean) => void;
  companyID: string;
};
//
const StyledDataGrid = styled(DataGrid)(() => ({
  border: "none",
  overflow: "auto",
  [`.${gCl.main}`]: { minWidth: 1000 },
  [`.${gCl.columnHeaders}`]: {
    paddingLeft: 0,
    backgroundColor: "#007AE5",
    color: "white",
  },
  [`.${gCl.columnHeader}`]: { [`.MuiCheckbox-root`]: { color: "white" } },
  [`.${gCl.columnHeaderCheckbox} > *`]: {},
  [`.${gCl.cell}.email-cell`]: { textDecoration: "underline" },
  [`.${gCl.columnHeaderTitle}`]: { fontSize: 14, fontWeight: 600 },
  [`.${gCl.virtualScrollerRenderZone}, .${gCl.row}`]: { width: "100%" },
  [`.${checkboxClasses.root} .${svgIconClasses.root}`]: {
    width: 17,
    height: 17,
  },
  [`.${gCl.columnHeader}, .${gCl.cellCheckbox}, .${gCl.cell}`]: {
    ":focus, :focus-within": { outline: "none" },
  },
  [`.${gCl.columnHeaderCheckbox}, .${gCl.cellCheckbox}`]: {
    // minWidth: "36px !important",
    // width: "36px !important",
  },
  [`.${gCl.cell}`]: {
    "&.name-cell": { fontWeight: 600, color: "#253334", paddingLeft: 0 },
    "&:not(.name-cell)": {
      fontSize: 13.1,
      color: "#464255",
    },
  },
  [`.${gCl.iconButtonContainer}`]: {
    width: "auto",
    visibility: "visible",
    [`.${iconButtonClasses.root}`]: {
      padding: 0,
      marginLeft: 10,
      color: "white",
      [`.${gCl.sortIcon}`]: { opacity: 1, width: 22, height: 22 },
    },
  },
}));
const FilterField = ({ label, ...props }: OutlinedInputProps) => (
  <Box sx={{ display: "inline-flex", alignItems: "center", mr: 4, my: 2.5 }}>
    <Box>
      <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
        {label}
      </Typography>
      <OutlinedInput
        {...props}
        placeholder={`Enter ${label}`}
        label={label}
        notched={false}
        aria-describedby={`data-table-column-${label}-filter`}
        inputProps={{ "aria-label": `data-table-column-${label}-filter` }}
        // startAdornment={<InputAdornment position="start">{label}</InputAdornment>}
        sx={{
          pl: 0,
          flex: 1,
          fontSize: 14,
          borderRadius: 2,
          fieldset: { border: "1px solid #D9D9D9" },
          [`.${inputBaseClasses.input}`]: {
            minWidth: 200,
            height: 40,
            padding: "0 10px",
          },
          [`.${inputAdornmentClasses.root}`]: {
            mr: 0,
            px: 1.5,
            height: 1,
            bgcolor: "#FAFAFA",
            [`.${typographyClasses.root}`]: {
              fontSize: 14,
              color: "#595959",
              textTransform: "capitalize",
            },
          },
        }}
      />
    </Box>
  </Box>
);
const ColumnFiltersBlock = (props: {
  filters: TableFilter[];
  filterChangeHandler: (params: FilterChangeParams) => void;
  searchHandler: () => void;
  setRowsListing1: () => void;
  setFilters1: () => void;
}) => (
  <Box>
    {props.filters.map(({ code, label, value }) => (
      <FilterField
        key={code}
        value={value}
        label={label || code}
        onChange={(e) =>
          props.filterChangeHandler({
            name: code,
            value: e.currentTarget.value,
          })
        }
      />
    ))}
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        gap: 1,
      }}
    >
      <Button
        color="inherit"
        variant="outlined"
        sx={{ color: "#007AE5", borderColor: "#007AE5", fontWeight: 400 }}
        onClick={() => {
          props.setRowsListing1();
          props.setFilters1();
        }}
      >
        Clear
      </Button>
      <Button
        variant="contained"
        sx={{ fontWeight: 400 }}
        onClick={props.searchHandler}
      >
        Search
      </Button>
    </Box>
  </Box>
);
export const AdminTable = ({
  filtersList,
  headers,
  bodyRows,
  rowClickHandler,
  isLoading,
  selectionApproveHandler,
  selectionRejectHandler,
  setCreateModal1,
  setInviteModal1,
  companyID,
}: TablePropTypes) => {
  const refinedFilters = useMemo(() => {
    return filtersList.map((el) =>
      typeof el === "string" ? { code: el, value: "" } : { ...el, value: "" }
    );
  }, [filtersList]);
  const skeletonProps = useMemo(() => {
    return {
      height: 40,
      width: 250,
    };
  }, []);
  const [showDiv, setShowDiv] = useState(false);
  const [rowsListing, setRowsListing] = useState<any[]>([]);
  const [filters, setFilters] = useState<TableFilter[]>(refinedFilters);
  const [selectedRowIdList, setSelectedRowIdList] =
    useState<GridRowSelectionModel>([]);
  useEffect(() => {
    if (bodyRows.length > 0) {
      setRowsListing(bodyRows);
    }
  }, [bodyRows, bodyRows.length]);
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };
  const filterChangeHandler = (params: FilterChangeParams) => {
    setFilters((prev) => {
      const update = [...prev];
      const matchIndex = update.findIndex((el) => el.code === params.name);
      if (matchIndex !== -1)
        update[matchIndex] = { ...update[matchIndex], value: params.value };
      return update;
    });
  };
  const setRowsListing1 = () => {
    setRowsListing(bodyRows);
  };
  const setFilters1 = () => {
    setFilters(refinedFilters);
  };
  const searchHandler = () => {
    const noFilterAdded = filters.every(({ value }) => !value);
    if (noFilterAdded) setRowsListing(bodyRows);
    else if (bodyRows.length > 0) {
      setRowsListing(() => {
        return bodyRows.filter((row) => {
          return Object.entries(row).some(([key, value]) => {
            return filters.some(
              (filter) =>
                filter.code === key &&
                filter.value &&
                formatStr(value).includes(formatStr(filter.value))
            );
          });
        });
      });
    }
  };
  return (
    <>
      <Box
        elevation={0}
        component={Paper}
        sx={{
          position: "relative",
          borderRadius: "20px",
          overflow: "hidden",
          px: 3,
          py: 3,
          mb: 3,
        }}
      >
        <Stack
          flexWrap="wrap"
          className="top-header-block"
          justifyContent="space-between"
          sx={{
            rowGap: 1.5,
            columnGap: 1,
            [`.${buttonClasses.root}`]: {
              height: 32,
              borderRadius: "10px",
              lineHeight: 0.8,
            },
          }}
        >
          <Stack flexWrap="wrap" alignItems="center" columnGap={2} rowGap={0.5}>
            <Box>
              <Typography
                variant="h2"
                fontSize={24}
                fontWeight={700}
                lineHeight="32px"
              >
                Users
              </Typography>
              <Typography
                variant="h2"
                fontSize={16}
                fontWeight={400}
                lineHeight="32px"
              >
                Manage your Users
              </Typography>
            </Box>
            {selectedRowIdList.length > 1 &&
            selectionApproveHandler &&
            selectionRejectHandler ? (
              <Stack gap={1}>
                <Button
                  variant="contained"
                  onClick={() =>
                    selectionApproveHandler(selectedRowIdList as number[])
                  }
                  sx={{
                    bgcolor: "#19A46A",
                    ":hover": { bgcolor: "#127c50" },
                    fontSize: 12,
                    fontWeight: 500,
                    py: 2.0,
                  }}
                  startIcon={
                    <Box sx={{ svg: { width: 15, height: "auto" } }}>
                      <TickIcon />
                    </Box>
                  }
                >
                  Approve Selected
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    selectionRejectHandler(selectedRowIdList as number[])
                  }
                  sx={{
                    bgcolor: "#FF5454",
                    ":hover": { bgcolor: "#d84646" },
                    fontSize: 12,
                    fontWeight: 500,
                    py: 2.0,
                  }}
                  startIcon={
                    <Box sx={{ svg: { width: 15, height: "auto" } }}>
                      <CrossIcon />
                    </Box>
                  }
                >
                  Reject Selected
                </Button>
              </Stack>
            ) : null}
          </Stack>

          
          <Stack gap={0} justifyContent={"center"} alignItems={"center"}>
            <PopoverMenu
              btnChild={
                <Box
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: 'Black',
                  borderRadius: '100%',
                }}
              >
                <img
                  src={AddUserIcon}
                  alt="filterIcon"
                  style={{ width: '35%', height: 'auto' }}
                />
              </Box>
              }
              menuId={`user-row-menu`}
              btnId={`user-row-menu-button`}
              btnSx={{ fontSize: 20, borderRadius: "50%", p: 0.5, }}
              menuChild={() => (
                <Box sx={{ p: 1.5 }}>
                  <Typography>coming soon...</Typography>
                </Box>
              )}
            />
            <Box
              onClick={() => toggleDiv()}
              sx={{
                cursor: "pointer",
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mx: 1,
              }}
            >
              {showDiv ? (
                <img src={ActiveFilter} alt="filterIcon" />
              ) : (
                <img src={Filter} alt="filterIcon" />
              )}
            </Box>
            <PopoverMenu
              btnChild={
                <Button
                  size="large"
                  color="inherit"
                  variant="outlined"
                  sx={{
                    color: "#007AE5",
                    borderColor: "#007AE5",
                    fontWeight: 600,
                    py: 2.2,
                  }}
                  startIcon={<InviteUserIcon viewBox="0 0 24 24" />}
                >
                  Invite User
                </Button>
              }
              menuId={`user-row-menu`}
              btnId={`user-row-menu-button`}
              btnSx={{ fontSize: 20, borderRadius: "50%", p: 0.5 }}
              menuChild={() => (
                <Box>
                  <InviteUserModal companyID={companyID} />
                </Box>
              )}
            />
          </Stack>
        </Stack>
        {showDiv && (
          <ColumnFiltersBlock
            {...{
              filters,
              filterChangeHandler,
              searchHandler,
              setRowsListing1,
              setFilters1,
            }}
          />
        )}
      </Box>
      <Box
        elevation={0}
        component={Paper}
        sx={{ position: "relative", borderRadius: "5px", overflow: "hidden" }}
      >
        <Box className="header" px={{ xs: 2, sm: 3.75 }}></Box>
        <StyledDataGrid
          autoHeight
          columns={headers}
          rows={rowsListing}
          columnHeaderHeight={52}
          onRowClick={rowClickHandler}
          rowCount={rowsListing?.length}
          rowSelectionModel={selectedRowIdList}
          initialState={{
            pagination: { paginationModel: { pageSize: 25, page: 0 } },
          }}
          onRowSelectionModelChange={setSelectedRowIdList}
          hideFooter={rowsListing?.length <= 10}
          disableRowSelectionOnClick
          paginationMode="client"
          pageSizeOptions={[25]}
          checkboxSelection
          disableColumnMenu
          rowHeight={isLoading ? 260 : 47}
          pagination
          slots={{
            columnSortedDescendingIcon: HiChevronUp,
            columnSortedAscendingIcon: HiChevronDown,
            noRowsOverlay: () =>
              isLoading || !rowsListing ? (
                <Stack
                  alignItems="center"
                  justifyContent="between"
                  gap={3}
                  ml={2}
                >
                  {[
                    "Name",
                    "Phone",
                    "Email",
                    "Role",
                    "Account Type",
                    "Status",
                  ].map((item, index1) => {
                    return (
                      <Box key={index1} textAlign={"center"}>
                        <SkeletonLoading {...skeletonProps} count={10} />
                      </Box>
                    );
                  })}
                </Stack>
              ) : (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nothing Found Here
                </Stack>
              ),
          }}
        />
      </Box>
    </>
  );
};
