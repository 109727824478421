import { Shift, ShiftInsert, ShiftUpdate } from "types";
import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";
import dayjs from "dayjs";
//
const shiftColumns = `*, employee:employee_id(avatar_url, email, first_name, last_name, time_zone ), breaks(id,start_date_time, end_date_time, break_overtime, overtime_reason)`;
export const getCompanyShifts = async (
  company_id: string
): Promise<Shift[]> => {
  return await reqHandler(() =>
    supabase.from("shifts").select(shiftColumns).match({ company_id })
  );
};
export const getEmployeeShifts = async (id: string): Promise<Shift[]> => {
  return await reqHandler(() =>
    supabase
      .from("shifts")
      .select(shiftColumns)
      .eq("employee_id", id)
      .order("target_date", { ascending: false })
  );
};
export const postShift = async (shift: ShiftInsert) => {
  // return await reqHandler(() => supabase.from("shifts").insert(shift));
  const { data: shift_data } = await supabase
    .from("shifts")
    .insert(shift)
    .select();
  const employee_id = shift_data![0]?.employee_id;
  if (employee_id) {
    await supabase.rpc("create_new_notification", {
      user_id: employee_id,
      is_read: false,
      notification_type_id: 14,
      shift_id: shift_data![0]?.id,
    });
  }
};
export const updateShift = async ({
  id,
  props,
}: {
  id: number;
  props: ShiftUpdate;
}) => {
  const { employee, ...data } = props as any;

  const { data: shift_data } = await supabase
    .from("shifts")
    .update({ ...data })
    .match({ id })
    .select();

  let notification_type = 17;
  let user_id = shift_data![0]?.employee_id;
  if (data.is_approved === true) {
    notification_type = 15;
    user_id = shift_data![0]?.employee_id;
  }
  if (data.is_approved === false) {
    notification_type = 18;
    user_id = shift_data![0]?.employee_id;
  }

  if (data.is_archived === true) {
    //notification_type = /* Set appropriate notification type for archived */;
    user_id = shift_data![0]?.employee_id;
  }

  if (shift_data![0]?.id) {
    const { data: n_data } = await supabase
      .from("notifications")
      .select("id")
      .eq("user_id", user_id)
      .eq("shift_id", shift_data![0]?.id)
      .eq("notification_type_id", notification_type)
      .select();
    if (n_data![0]?.id) {
      await supabase
        .from("notifications")
        .update({ is_read: false, notification_type_id: notification_type })
        .match({
          id: n_data![0]?.id,
        });
    } else {
      await supabase.rpc("create_new_notification", {
        user_id: user_id,
        is_read: false,
        notification_type_id: notification_type,
        shift_id: shift_data![0]?.id,
      });
    }
  }
};

export const updateShiftStart = async ({ id }: { id: number }) => {
  let currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  return await reqHandler(() => {
    return supabase
      .from("shifts")
      .update({ actual_start_time: currentDate }) // doesn't update without destructuring for unkown reason..
      .match({ id });
  });
};
export const updateShiftEnd = async ({ id }: { id: number }) => {
  let currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  return await reqHandler(() => {
    return supabase
      .from("shifts")
      .update({ actual_end_time: currentDate })
      .match({ id });
  });
};
export const updateEarlyShiftEnd = async (item: any) => {
  const { id, actual_end_time, end_reason, end_status, shift_status } = item;
  const { data: shift_data } = await supabase
    .from("shifts")
    .update({
      actual_end_time: actual_end_time,
      end_reason: end_reason,
      end_status: end_status,
      shift_status: shift_status,
    })
    .match({ id })
    .select();
  let notification_type = 7;
  let user_id = shift_data![0]?.assign_user_id;
  if (item.end_status === "late") {
    notification_type = 8;
    user_id = shift_data![0]?.assign_user_id;
  }
  if (item.end_status === "early") {
    notification_type = 7;
    user_id = shift_data![0]?.assign_user_id;
  }
  // let currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss')

  if (shift_data![0]?.id) {
    const { data: n_data } = await supabase
      .from("notifications")
      .select("id")
      .eq("user_id", user_id)
      .eq("shift_id", shift_data![0]?.id)
      .eq("notification_type_id", notification_type)
      .select();
    if (n_data![0]?.id) {
      await supabase
        .from("notifications")
        .update({ is_read: false, notification_type_id: notification_type })
        .match({
          id: n_data![0]?.id,
        });
    } else {
      await supabase.rpc("create_new_notification", {
        user_id: user_id,
        is_read: false,
        notification_type_id: notification_type,
        shift_id: shift_data![0]?.id,
      });
    }
  }
};
export const updateEarlyShiftStart = async (item: any) => {
  const { id, ...rest } = item;
  const { data: shift_data } = await supabase
    .from("shifts")
    .update(rest)
    .match({ id })
    .select();
  let notification_type = 5;
  let user_id = shift_data![0]?.assign_user_id;
  if (item.start_status === "late") {
    notification_type = 4;
    user_id = shift_data![0]?.assign_user_id;
  }
  if (item.start_status === "early") {
    notification_type = 5;
    user_id = shift_data![0]?.assign_user_id;
  }

  if (shift_data![0]?.id) {
    const { data: n_data } = await supabase
      .from("notifications")
      .select("id")
      .eq("user_id", user_id)
      .eq("shift_id", shift_data![0]?.id)
      .eq("notification_type_id", notification_type)
      .select();
    if (n_data![0]?.id) {
      await supabase
        .from("notifications")
        .update({ is_read: false, notification_type_id: notification_type })
        .match({
          id: n_data![0]?.id,
        });
    } else {
      await supabase.rpc("create_new_notification", {
        user_id: user_id,
        is_read: false,
        notification_type_id: notification_type,
        shift_id: shift_data![0]?.id,
      });
    }
  }
};
export const updateMultipleShifts = async (shiftList: Partial<Shift>[]) => {
  return await reqHandler(() => {
    return supabase.rpc("multiple_shifts_approval_handler", {
      payload: shiftList,
    });
  });
};
export const deleteShift = async (id: number | string) =>
  await reqHandler(() => supabase.from("shifts").delete().match({ id }));
