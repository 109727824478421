import dayjs from "dayjs";
import { ReactElement, useEffect, useState } from "react";
import { Stack, Dialog, paperClasses, Box, Typography, MenuItem } from "@mui/material";
import { FaRegClock, FaRegHourglass } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";

import { Shift } from "types";
// import { getShiftStatus } from "utils/records-helpers";
import { DbImage, MapToDisplay, PopoverMenu } from "components";
import { formatTimeStr, getFullName } from "utils/data-helpers";
import { CalenderIcon, PaperEditIcon, TeaPotIcon, WalletIcon } from "assets/svg";

//

const InfoBlock = ({ icon, text, isTextGrey, clickHandler }: { icon: ReactElement; text: string; clickHandler?: VoidFunction; isTextGrey?: boolean }) => (
	<Stack
		alignItems="center"
		onClick={clickHandler}
		border="1px solid #2533341a"
		sx={{ p: 1, gap: 2, borderRadius: "6px", cursor: clickHandler ? "pointer" : "default", svg: { minWidth: 14, width: 14, height: "auto" } }}
	>
		{icon}
		<Typography fontSize={14} color={isTextGrey ? "#9BA1CC" : "#273B4A"} lineHeight={1.3} pt={0.2}>
			{text}
		</Typography>
	</Stack>
);

// const NoteField = (props: OutlinedInputProps) => {
// 	const [isEditable, setEditable] = useState<boolean>(!!props.value);
// 	//
// 	return (
// 		<OutlinedInput
// 			{...props}
// 			placeholder="Add a note"
// 			onClick={() => !isEditable && setEditable(true)}
// 			startAdornment={<PaperEditIcon />}
// 			maxRows={3}
// 			multiline
// 			sx={{
// 				p: 1,
// 				fieldset: { borderRadius: "6px", ...(!isEditable && { border: "none" }) },
// 				[`.${inputBaseClasses.input}`]: { fontSize: 14, lineHeight: 1.3, ml: 2, ":placeholder": { color: "#9BA1CC" } },
// 			}}
// 		/>
// 	);
// };

const titleIcon = (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			opacity="0.4"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.1073 -6.10352e-05H3.89274C1.56432 -6.10352e-05 0 1.63647 0 4.07173V9.92815C0 12.3634 1.56432 13.9999 3.89274 13.9999H10.1065C12.4349 13.9999 14 12.3634 14 9.92815V4.07173C14 1.63647 12.4357 -6.10352e-05 10.1073 -6.10352e-05Z"
			fill="#9BA1CC"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.66443 5.63273H4.33779C4.032 5.63273 3.78516 5.38517 3.78516 5.08012C3.78516 4.77507 4.032 4.52749 4.33779 4.52749H9.66443C9.96948 4.52749 10.2171 4.77507 10.2171 5.08012C10.2171 5.38517 9.96948 5.63273 9.66443 5.63273ZM9.66443 9.4702H4.33779C4.032 9.4702 3.78516 9.22262 3.78516 8.91757C3.78516 8.61252 4.032 8.36494 4.33779 8.36494H9.66443C9.96948 8.36494 10.2171 8.61252 10.2171 8.91757C10.2171 9.22262 9.96948 9.4702 9.66443 9.4702Z"
			fill="#9BA1CC"
		/>
	</svg>
);

//

type PropTypes = {
	opened: boolean;
	shift: Shift | null;
	closeModal: VoidFunction;
};

export const CompanyScheduleDetailsModal = ({ opened, shift, closeModal }: PropTypes) => {
	const [mapLocation, setMapLocation] = useState<any | null>(null);
	// const [notes, setNotes] = useState<any | null>(null);

	useEffect(() => {
		// if (shift?.manager_note) setNotes(shift.manager_note);
		if (shift?.latitude) {
			const { location, latitude, longitude } = shift;
			setMapLocation({ place_name: location, geometry: { coordinates: [longitude, latitude] } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shift]);

	if (!shift) return <></>;

	const closeAndReset = () => {
		setMapLocation(null);
		closeModal();
	};

	const openEditModal = () => {
		closeAndReset();
	};

	// const updateShift = (props: ShiftUpdate) => mutateUpdateShift({ id: shift.id, props });

	// const status = getShiftStatus(shift);
	const employeeName = getFullName(shift.employee) || "";

	return (
		<Dialog
			open={opened}
			onClose={closeAndReset}
			sx={{ [`.${paperClasses.root}`]: { maxWidth: 520, flexDirection: "row", gap: 2, px: 2, pt: 1.5, pb: 4, borderRadius: "10px" } }}
			hideBackdrop
			fullWidth
		>
			<Stack flex={0.45} direction="column" gap={1}>
				<InfoBlock icon={<CalenderIcon />} text={dayjs(shift.target_date).format("ddd, D MMM")} />
				<InfoBlock icon={<FaRegClock color="#9BA1CC" />} text={`${formatTimeStr(shift.starting_time)} - ${formatTimeStr(shift.ending_time)}`} />
				<InfoBlock icon={titleIcon} text={shift.title} />
				<InfoBlock icon={<WalletIcon />} text={`$${shift.total_price}`} />
				<InfoBlock icon={<FaRegHourglass color="#9BA1CC" />} text={`$${shift.hourly_charge} per Hour`} />
				<InfoBlock icon={<TeaPotIcon />} text={`${shift.break_allowance} Min Break`} />
				<InfoBlock
					icon={<DbImage src={shift.employee.avatar_url} name={employeeName} loaderSize={8} sx={{ width: 14, height: 14 }} />}
					text={employeeName}
				/>
				<InfoBlock icon={<PaperEditIcon />} text="Add a Note" clickHandler={openEditModal} isTextGrey />
				{/* <NoteField value={notes} onChange={(ev) => setNotes(ev.currentTarget.value)} /> */}
			</Stack>

			<Box flex={0.55}>
				<MapToDisplay selectedResult={mapLocation} setSelectedResult={setMapLocation} style={{ height: "85%", borderRadius: "6px" }} />

				<Stack justifyContent="flex-end" gap={1} mt={2}>
					<PopoverMenu
						menuSx={{ zIndex: 1500 }} // higher than modal root 1300..
						btnChild={<BsThreeDots />}
						menuId={`shift-${shift.id}-row-menu`}
						btnId={`shift-${shift.id}-row-menu-button`}
						btnSx={{ fontSize: 20, color: "#9BA1CC", p: 0.5 }}
						btnProps={{ variant: "contained", color: "inherit" }}
						menuProps={{
							anchorOrigin: { vertical: "top", horizontal: "center" },
							transformOrigin: { vertical: "bottom", horizontal: "right" },
						}}
						menuChild={(closePopover) => (
							<div>
								{/* <MenuItem onClick={openEditModal}>Edit Shift</MenuItem> */}
								<Box sx={{
									borderBottom: "1px solid #2533341a"
								}}>
									<MenuItem>Repeat for tomorrow</MenuItem>
									<MenuItem>Repeat for rest of the week</MenuItem>
									<MenuItem>Repeat for specific days</MenuItem>
								</Box>
								<Box sx={{
									borderBottom: "1px solid #2533341a"
								}}>

									<MenuItem>Delete Shift</MenuItem>
									<MenuItem>Duplicate Shift</MenuItem>
								</Box>
								<Box sx={{
									color: "#9BA1CC"
								}}>

									<MenuItem>Send Offers(Coming Soon)</MenuItem>
									<MenuItem>Shift History(Coming Soon)</MenuItem>
								</Box>

								{/* {status === "unassigned" || (
									<MenuItem
										sx={{ color: status === "approved" ? "red" : "initial" }}
										// onClick={() => updateShift({ is_approved: !shift.is_approved })}
									>
										{status === "approved" ? "Reject" : "Approve"} Shift
									</MenuItem>
								)}

								<MenuItem onClick={() => closePopover()}>View Shift History</MenuItem> */}
							</div>
						)}
					/>

					{/* <Button
						variant="contained"
						size="small"
						onClick={() => {
							if (notes !== shift.manager_note) updateShift({ manager_note: notes });
						}}
					>
						Save
					</Button> */}
				</Stack>
			</Box>
		</Dialog>
	);
};
