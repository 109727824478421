import { Stack, Typography, Box, Grid } from "@mui/material";
import { AppleLaptopComputerIcon } from "assets/svg";
import mobile from "../../../assets/images/mobile.png";
import { getUserAgent } from "utils/data-helpers";
type PropTypes = {
  i: number;
  device: any;
};
function DevicesInfo({ i, device }: PropTypes) {
  const { platform } = getUserAgent(device?.device_info);
  let svg_icon = <img src={mobile} alt="mobile" />;
  if (platform === "Desktop") {
    svg_icon = <AppleLaptopComputerIcon />;
  }
  return (
    <Grid item xs={6}>
      <Stack
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #ECECEC",
          marginTop: 3,
          borderRadius: 3,
          paddingY: 3,
          paddingX: 3,
        }}
      >
        <Stack sx={{ justifyContent: "start", alignItems: "center" }}>
          {svg_icon}
          <Box sx={{ marginLeft: 2 }}>
            <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
              {platform}
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              {device?.ip_data?.city}, {device?.ip_data?.region}{" "}
              {device?.ip_data?.ip}
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            border: "1px solid #ECECEC",
            borderRadius: 10,
            px: 3,
            py: 1,
          }}
        >
          <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: "#0DD350",
              }}
            ></Box>
            <Typography
              sx={{
                ml: 1,
              }}
            >
              Online
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Grid>
  );
}
export default DevicesInfo;
