import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useState, memo } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { DataGrid, GridColDef, GridEventListener, GridRowSelectionModel, gridClasses as gCl } from "@mui/x-data-grid";
import {
	Box,
	Paper,
	Stack,
	Button,
	Typography,
	buttonClasses,
	OutlinedInput,
	svgIconClasses,
	InputAdornment,
	checkboxClasses,
	inputBaseClasses,
	iconButtonClasses,
	typographyClasses,
	OutlinedInputProps,
	inputAdornmentClasses,
} from "@mui/material";

import { LoadingOverlay } from "components";
import { formatStr } from "utils/data-helpers";

//

type FilterChangeParams = { name: string; value: string | number };

type TableFilter = { label?: string; code: string; value?: string | number };

type TablePropTypes = {
	bodyRows: any[];
	isLoading: boolean;
	headers: GridColDef[];
	rootMinWidth?: number;
	filtersList: (TableFilter | string)[];
	rowClickHandler?: GridEventListener<"rowClick">;
	selectionRejectHandler?: (param: number[]) => void;
	selectionApproveHandler?: (param: number[]) => void;
};

//

const StyledDataGrid = styled(DataGrid)(() => ({
	border: "none",
	overflow: "auto",
	[`.${gCl.main}`]: { minWidth: 1000 },
	[`.${gCl.columnHeader}.name-header`]: { paddingLeft: 0 },
	[`.${gCl.columnHeaderCheckbox} > *`]: { display: "none" },
	[`.${gCl.cell}.email-cell`]: { textDecoration: "underline" },
	[`.${gCl.columnHeaderTitle}`]: { fontSize: 14, fontWeight: 600 },
	[`.${gCl.virtualScrollerRenderZone}, .${gCl.row}`]: { width: "100%" },
	[`.${checkboxClasses.root} .${svgIconClasses.root}`]: { width: 17, height: 17 },
	[`.${gCl.columnHeader}, .${gCl.cellCheckbox}, .${gCl.cell}`]: { ":focus, :focus-within": { outline: "none" } },
	[`.${gCl.columnHeaderCheckbox}, .${gCl.cellCheckbox}`]: { minWidth: "36px !important", width: "36px !important" },
	[`.${gCl.cell}`]: {
		"&.name-cell": { fontWeight: 600, color: "#253334", paddingLeft: 0 },
		"&:not(.name-cell)": { fontSize: 13.1, lineHeight: "11px", color: "#464255" },
	},
	[`.${gCl.iconButtonContainer}`]: {
		width: "auto",
		visibility: "visible",
		[`.${iconButtonClasses.root}`]: {
			padding: 0,
			marginLeft: 10,
			[`.${gCl.sortIcon}`]: { opacity: 1, width: 22, height: 22 },
		},
	},
}));

const FilterField = ({ label, ...props }: OutlinedInputProps) => {
		// console.log(label);
	return (
	<OutlinedInput
		{...props}
		placeholder={`Select ${label}`}
		aria-describedby={`data-table-column-${label}-filter`}
		inputProps={{ "aria-label": `data-table-column-${label}-filter` }}
		startAdornment={<InputAdornment position="start">{label}</InputAdornment>}
		sx={{
			pl: 0,
			flex: 1,
			fontSize: 14,
			fieldset: { border: "1px solid #D9D9D9" },
			[`.${inputBaseClasses.input}`]: { minWidth: 100, height: 32, borderLeft: "1px solid #D9D9D9", padding: "0 10px" },
			[`.${inputAdornmentClasses.root}`]: {
				mr: 0,
				px: 1.5,
				height: 1,
				bgcolor: "#FAFAFA",
				[`.${typographyClasses.root}`]: { fontSize: 14, color: "#595959", textTransform: "capitalize" },
			},
		}}
	/>
)};

const ColumnFiltersBlock = (props: { filters: TableFilter[]; filterChangeHandler: (params: FilterChangeParams) => void }) => {
	// console.log(props);
	return(
	<Box display="grid" gridTemplateColumns="repeat(auto-fill,minmax(250px, 1fr))" gap={{ xs: 1, md: 2 }}>
		{props.filters.map(({ code, label, value }) => (
			<FilterField
				key={code}
				value={value}
				label={label || code}
				onChange={(e) => props.filterChangeHandler({ name: code, value: e.currentTarget.value })}
			/>
		))}
	</Box>
)};

//

export const ShiftRequestTable = memo(({ filtersList, headers, bodyRows, rowClickHandler, isLoading, selectionApproveHandler, selectionRejectHandler }: TablePropTypes) => {
	// transforming string type filter-items into { code: "filter-column-name" }..
	const refinedFilters = useMemo(() => {
		return filtersList.map((el) => (typeof el === "string" ? { code: el, value: "" } : { ...el, value: "" }));
	}, [filtersList]);

	// const tableRef = useGridApiRef();
	const [rowsListing, setRowsListing] = useState<any[]>([]);
	const [filters, setFilters] = useState<TableFilter[]>(refinedFilters);
	const [selectedRowIdList, setSelectedRowIdList] = useState<GridRowSelectionModel>([]);
	// console.log('bodyRows',bodyRows);
	useEffect(() => {
		if (bodyRows.length > 0) setRowsListing(bodyRows);
	}, [bodyRows, bodyRows.length]);

	// handler functions..

	const filterChangeHandler = (params: FilterChangeParams) => {
		setFilters((prev) => {
			const update = [...prev];
			const matchIndex = update.findIndex((el) => el.code === params.name);
			if (matchIndex !== -1) update[matchIndex] = { ...update[matchIndex], value: params.value };
			return update;
		});
	};

	const searchHandler = () => {
		const noFilterAdded = filters.every(({ value }) => !value);
		if (noFilterAdded) setRowsListing(bodyRows);
		else if (bodyRows.length > 0) {
			setRowsListing(() => {
				return bodyRows.filter((row) => {
					// console.log("row",row);
					return Object.entries(row).some(([key, value]) => {
						return filters.some((filter) => filter.code === key && filter.value && formatStr(value).includes(formatStr(filter.value)));
					});
				});
			});
		}
	};

	// jsx..

	return (
		<Box elevation={2} component={Paper} sx={{ position: "relative", borderRadius: "20px", overflow: "hidden" }}>
			<Box className="header" px={{ xs: 2, sm: 3.75 }} pt={{ xs: 2, sm: 3.75 }} pb={{ xs: 1, sm: 2 }}>
				<Stack
					flexWrap="wrap"
					className="top-header-block"
					justifyContent="space-between"
					sx={{ rowGap: 1.5, columnGap: 1, mb: 2.5, [`.${buttonClasses.root}`]: { height: 32, borderRadius: "10px", lineHeight: 0.8 } }}
				>
					<Stack flexWrap="wrap" alignItems="center" columnGap={2} rowGap={0.5}>
						<Typography fontSize={17} fontWeight={700}>
							Search And Filter
						</Typography>

						{selectedRowIdList.length > 1 && selectionApproveHandler && selectionRejectHandler ? (
							<Stack gap={1}>
								<Button
									variant="contained"
									onClick={() => selectionApproveHandler(selectedRowIdList as number[])}
									sx={{ bgcolor: "#19A46A", ":hover": { bgcolor: "#127c50" }, fontSize: 12, fontWeight: 500 }}
								>
									Approve Selected
								</Button>
								<Button
									variant="contained"
									onClick={() => selectionRejectHandler(selectedRowIdList as number[])}
									sx={{ bgcolor: "#FF5454", ":hover": { bgcolor: "#d84646" }, fontSize: 12, fontWeight: 500 }}
								>
									Reject Selected
								</Button>
							</Stack>
						) : null}
					</Stack>

					<Stack gap={1}>
						<Button
							color="inherit"
							variant="outlined"
							sx={{ color: "#007AE5", borderColor: "#007AE5", fontWeight: 400 }}
							onClick={() => {
								setRowsListing(bodyRows);
								setFilters(refinedFilters);
							}}
						>
							Clear
						</Button>
						<Button variant="contained" sx={{ fontWeight: 400 }} onClick={searchHandler}>
							Search
						</Button>
					</Stack>
				</Stack>

				<ColumnFiltersBlock {...{ filters, filterChangeHandler }} />
			</Box>

			<StyledDataGrid
				autoHeight
				columns={headers}
				rows={rowsListing}
				columnHeaderHeight={52}
				onRowClick={rowClickHandler}
				rowCount={rowsListing?.length}
				rowSelectionModel={selectedRowIdList}
				initialState={{ pagination: { paginationModel: { pageSize: 10, page: 0 } } }}
				onRowSelectionModelChange={setSelectedRowIdList}
				hideFooter={rowsListing?.length <= 10}
				disableRowSelectionOnClick
				paginationMode="client"
				pageSizeOptions={[]}
				checkboxSelection
				disableColumnMenu
				rowHeight={47.5}
				pagination
				slots={{
					columnSortedDescendingIcon: HiChevronUp,
					columnSortedAscendingIcon: HiChevronDown,
					noRowsOverlay: () => (
						<Stack height="100%" alignItems="center" justifyContent="center">
							Nothing Found Here
						</Stack>
					),
				}}
			/>

			{/* overlay loader */}

			<LoadingOverlay open={isLoading} loaderProps={{ size: 45, color: "primary" }} sx={{ bgcolor: "#00000020" }} isAbsolute />
		</Box>
	);
});
