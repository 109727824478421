import { Box, MenuItem, Paper, Stack, Typography } from "@mui/material";
import { MdAccessTime, MdHourglassEmpty } from "react-icons/md";
import { AiOutlineIssuesClose } from "react-icons/ai";
import dayjs from "dayjs";

import { Shift } from "types";
import { StatusBtn, PopoverMenu } from ".";
import { formatTimeStr } from "utils/data-helpers";
import { getShiftStatus } from "utils/records-helpers";
import { DoubleDotsIcon, LocationIcon, WalletIcon } from "assets/svg";

//

const WeekDay = (props: { display: {}; date: string }) => (
	<Typography display={props.display} variant="body1" fontSize={{ xs: 18, sm: 10 }} fontWeight={500} color="app.greyblue">
		{new Date(props.date)?.toLocaleDateString("en-US", { weekday: "long" })}
	</Typography>
);

const PriceTag = (props: { display?: {}; price: number }) => (
	<Stack display={props.display} minHeight="44px" alignItems="center" gap={{ xs: 0.8, sm: 1.5 }} mb={1}>
		<WalletIcon />
		<Typography variant="h2" fontSize={{ xs: 25, sm: 32 }} fontWeight={700} lineHeight={{ xs: "34px", sm: "44px" }}>
			${props.price}
		</Typography>
	</Stack>
);

//

const ShiftCard = (props: Shift & { viewShiftModal: VoidFunction }) => {
	const shiftStatus = getShiftStatus(props);
	//
	return (
		<Paper elevation={3}  sx={{ height: "80%", width: "80%" , display: "flex", maxWidth:"350px",flexDirection: "column", borderRadius: 2, py: { xs: 1.75, sm: 3.75 }, pl: 2.5, pr: 1.5 }}>
			<Stack justifyContent="space-between" alignItems="center" mb={{ sm: 1 }}>
				<Stack gap={1.5}>
					{/* <WeekDay display={{ xs: "block", sm: "none" }} date={props.target_date} /> */}
					<Typography
							variant="h2"
							fontWeight={400}
							fontSize={{ xs: 26, xxs: 30, sm: 28 }}
							sx={{ mt: { xs: 0.625, sm: 0 }, mb: 0.625, lineHeight: { xs: "40px", sm: "30px" } }}
						>
							{props.title}
					</Typography>
					
				</Stack>

				<PopoverMenu
					rootSx={{ pointerEvents: "none" }}
					btnChild={<DoubleDotsIcon />}
					menuId={`shift-card-${props.id}-menu`}
					btnId={`shift-card-${props.id}-menu-btn`}
					btnSx={{ width: 23, height: 23, p: 0.75 }}
					menuChild={(closePopover) => (
						<MenuItem
							onClick={() => {
								props.viewShiftModal();
								closePopover();
							}}
						>
							<AiOutlineIssuesClose />
							View Details
						</MenuItem>
					)}
				/>
			</Stack>


			<Stack sx={{ flex: 1, justifyContent: "space-between", columnGap: 2, rowGap: 1, "@media(max-width:430px)": { flexWrap: "wrap" } }}>
				<Stack direction="column" justifyContent="space-between" flex={{ sm: 1 }}>
					<PriceTag price={props.total_price} display={{ xs: "none", sm: "flex" }} />
		        	<WeekDay display={{ xs: "none", sm: "block" }} date={props.target_date} />
					<Typography variant="body1" fontSize={{ xs: 18, sm: 15 }} fontWeight={500}>
						{dayjs(props.target_date).format("DD MMM YYYY")}
					</Typography>
					

				
						<Stack alignItems="center" gap={0.625}
						sx={{ mt: { xs: 0.4, sm: 1 }, svg: { minWidth: { xs: 14, sm: 10 } } }}>
							<MdHourglassEmpty size={13} color="#9BA1CC" />
							<Typography variant="body1" fontSize={{ xs: 15, sm: 10 }} noWrap>
								${props.hourly_charge} per hour
							</Typography>
						</Stack>
							
						<Stack alignItems="center" gap={0.625}
						sx={{ mt: { xs: 0.4, sm: 1 }, svg: { minWidth: { xs: 14, sm: 10 } } }}>
							<MdAccessTime size={13} color="#9BA1CC" />
							<Typography variant="body1" fontSize={{ xs: 15, sm: 10 }} noWrap>
								{formatTimeStr(props.starting_time)}
								{" - "}
								{formatTimeStr(props.ending_time)}
							</Typography>
					   </Stack>


					<Stack
						gap={0.6}
						mb={{ sm: 1.5 }}
						alignItems="center"
						sx={{ mt: { xs: 0.4, sm: 1 }, svg: { minWidth: { xs: 14, sm: 10 } }, "path:first-of-type": { fill: "#9BA1CC" } }}
					>
						<LocationIcon />
						<Typography variant="body1" fontSize={{ xs: 15, sm: 10 }}>
							{props.location}
						</Typography>
					</Stack>
				</Stack>

				{/* mobile price & status block */}
				<Box display={{ xs: "block", sm: "none" }} sx={{ "@media(min-width:430px)": { mt: 2.5 } }}>
					<PriceTag price={props.total_price} />
					<StatusBtn status={shiftStatus} />
				</Box>
			</Stack>

			<StatusBtn sx={{ display: { xs: "none", sm: "flex" } }} status={shiftStatus} />
		</Paper>
	);
};

export default ShiftCard;
