import { Button } from "@mui/material";
import { FcGoogle } from "react-icons/fc";

//

const GoogleLoginBtn = (props: { text?: string; mutate: VoidFunction }) => (
	<Button
		fullWidth
		type="button"
		variant="outlined"
		onClick={props.mutate}
		startIcon={<FcGoogle />}
		sx={{
			height: 50,
			fontSize: 16,
			fontWeight: 500,
			marginLeft: "10px",
			width: "95%",
			borderRadius: "10px",
			borderColor:'#EDEDED',
			color: "#202422",
			backgroundColor: "#F6F6F6",
			".MuiButton-startIcon": { transform: "scale(0.9)", mr: 1 },
		}}
	>
		{props.text || "Link Google Account"}
	</Button>
);

export default GoogleLoginBtn;
