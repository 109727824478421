import { Button } from "@mui/material";
export interface SimpleDialogProps {
  submitHandler: Function;
}
const StartButton = (props: SimpleDialogProps) => {
  const submit = () => {
    props.submitHandler();
  };
  return (
    <Button
      variant="contained"
      sx={{
        fontWeight: 700,
        fontSize: { xs: 13, sm: 14, md: 16, lg: 18, xl: 20 },
        px: { xs: 0.5, sm: 1, md: 2, lg: 3, xl: 4 },
        py: { xs: 1.5, xl: 1.5 },
      }}
      onClick={submit}
    >
      Start Now
    </Button>
  );
};
export default StartButton;
