import { useMutation } from "@tanstack/react-query";
import { deleteImage, updateImage, uploadImage } from "api/image-files";

//

const useDbImageHandler = (bucketName: string, uploadPath?: string) => {
	const { mutate: postDbImg, isLoading: isPostingImg } = useMutation(["post-image"], (file: File) => uploadImage(bucketName, file, uploadPath));

	const { mutate: updateDbImg, isLoading: isUpdatingImg } = useMutation(["update-image"], {
		mutationFn: (params: { file: File; pathName: string }) => updateImage(bucketName, params.file, params.pathName),
	});

	const { mutate: deleteDbImg, isLoading: isDeletingImg } = useMutation(["delete-image"], (pathList: string[]) => deleteImage(bucketName, pathList));

	const dbImgHandler = (file: File, existingPath: string | null | undefined, onSuccess: (res: any) => void) => {
		if (existingPath) updateDbImg({ file, pathName: existingPath }, { onSuccess });
		else postDbImg(file, { onSuccess });
	};

	return { dbImgHandler, deleteDbImg, areImageOperationsLoading: isPostingImg || isDeletingImg || isUpdatingImg };
};

export default useDbImageHandler;
