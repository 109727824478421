import { useControl } from "react-map-gl";
import { Dispatch, SetStateAction } from "react";
import MapBoxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

//

type PropTypes = { map: any; selectedResult: any; setSelectedResult: Dispatch<SetStateAction<any>> };

const Geocoder = ({ map, selectedResult, setSelectedResult }: PropTypes) => {
	const ctrl = new MapBoxGeocoder({
		accessToken: process.env.REACT_APP_MAPBOX_TOKEN || "",
		placeholder: selectedResult?.place_name,
		marker: map ? true : false,
		collapsed: false,
		mapboxgl: map,
	});

	useControl(() => ctrl);

	ctrl.on("result", (e: any) => {
		setSelectedResult(e.result);
	});

	return null;
};

export default Geocoder;
