import { Employee } from "types";
import { FormProvider, useForm } from "react-hook-form";
import { EmployeeDefaults } from "constants/FormDefaultValues";
import { SelectField } from "components";
import { nullifyEmptyStrings } from "utils/data-helpers";
import { DbImage } from "components";
import {
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  FormControl,
} from "@mui/material";
type PropTypes = {
  open: boolean;
  onClose: VoidFunction;
  employee: any;
  updateEmployeeRole: Function;
};
const EditUserPermissionModal = (props: PropTypes) => {
  const { employee, open, onClose, updateEmployeeRole } = props;
  const form = useForm<Employee>({ defaultValues: EmployeeDefaults });
  const submitHandler = ({ ...vals }: Employee) => {
    const payload = {
      ...nullifyEmptyStrings(vals),
      role: vals?.role,
      employee_id: employee?.profile?.id,
      company_id: employee?.company?.id,
    };
    updateEmployeeRole(payload);
    props.onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ ".MuiPaper-root": { width: "20%", borderRadius: "10px" } }}
    >
      <Box sx={{ my: 2, mx: 2 }}>
        {/* <DialogTitle> */}
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Change Permissions
          </Typography>
        {/* </DialogTitle> */}
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitHandler)}>
            <DialogContent>
              <Box sx={{ textAlign: "center" }}>
                <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DbImage src={employee?.profile.avatar_url} name={employee?.profile.first_name} sx={{ width: { xs: 44, lg: 50 }, height: { xs: 44, lg: 50 }, borderRadius: "10px" }} loaderSize={18} />
                  <Box sx={{ textAlign: "start", ml: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {employee?.profile.first_name}{" "}
                      {employee?.profile.last_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {employee?.role}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <FormControl
                sx={{
                  mt: 4,
                }}
              >
                <SelectField
                  required
                  label="Role"
                  name="role"
                  options={["Admin", "Manager", "Employee"]}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: "100%",
                  // height: 60,
                  fontSize: 18,
                  borderRadius: 2,
                  textTransform: "capitalize",
                }}
              >
                Save New Permissions
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Box>
    </Dialog>
  );
};
export default EditUserPermissionModal;
