export const SignupUserDefaults = {
  email: "",
  phone: "",
  password: "",
  location: "",
  time_zone: null,
  birth_date: null,
  personal_address: "",
  last_name: "",
  first_name: "",
  confirm_password: "",
};

export const CompanyDefaults = {
  name: "",
  email: "",
  phone: "",
  location: "",
  logo_url: "",
  tax_number: "",
  acn: "",
};
export const EmployeeDefaults = { role: "", employee_id: "", company_id: "" };

export const ProfileSettingDefaults = {
  about_me: "",
  avatar_url: "",
  birth_date: null,
  caption: "",
  email: "",
  first_name: "",
  last_name: "",
  gender: "",
  languages_list: [],
  location: "",
  phone: "",
  postcode: null,
  profession: "",
  skills_list: [],
  website: "",
};

export const LeaveRequestDefaults = {
  company_id: "",
  employee_id: "",
  resume_date: "",
  notify_manager_id: "",
  leaving_from_date: "",
  is_approved: false,
  type_of_leave: "",
  availability: "",
  comment: "",
};

export const ShiftDefaults = {
  title: "",
  location: "",
  shift_count: 1,
  total_price: 0,
  target_date: "",
  ending_time: "",
  employee_id: "",
  manager_note: "",
  hourly_charge: 30,
  starting_time: "",
  is_approved: false,
  break_allowance: 0,
  start_status: "",
  start_reason: "",
  end_status: "",
  end_reason: "",
};
