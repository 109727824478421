import { useEffect } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import AppRoutes from "routes/AppRoutes";
import supabase from "supabase/supabaseClient";
import { UserContextProvider } from "context/UserContext";

//

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { refetchOnWindowFocus: false, staleTime: 20 * 60 * 1000 },
	},
});

const App = () => {
	useEffect(() => {
		supabase.auth.onAuthStateChange((ev) => {
			if (ev === "SIGNED_OUT") queryClient.invalidateQueries();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<UserContextProvider>
				<AppRoutes />
				<ReactQueryDevtools initialIsOpen={false} />
			</UserContextProvider>
		</QueryClientProvider>
	);
};

export default App;
