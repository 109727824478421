import { useEffect } from "react";
import { FieldArrayWithId } from "react-hook-form";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { ListItemIcon, listItemIconClasses, ListItemText, listItemTextClasses, MenuItem, menuItemClasses, MenuList, Paper } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";

import { CompanySchedulerFormData, ContextMenuInfoType } from "types";

//

type PropTypes = {
	onClose: Function;
	menu: ContextMenuInfoType;
	updateHandler: (id: number) => void;
	// deleteHandler: (id: number) => void;
	eventItem: FieldArrayWithId<CompanySchedulerFormData, "shifts", "id"> | FieldArrayWithId<CompanySchedulerFormData, "leaves", "id"> | undefined;
};

const SchedulerContextMenu = (props: PropTypes) => {
	useEffect(() => {
		window.addEventListener("resize", () => props.onClose());
		return () => window.removeEventListener("resize", () => props.onClose());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return props.menu ? (
		<ClickAwayListener onClickAway={() => props.onClose()}>
			<Paper
				sx={{
					width: 120,
					maxWidth: "100%",
					position: "fixed",
					top: props.menu?.y,
					left: props.menu?.x,
					[`.${menuItemClasses.root}`]: { py: 0.65 },
					[`li .${listItemTextClasses.primary}`]: { fontSize: 12 },
					[`li .${listItemIconClasses.root}`]: { minWidth: 22, fontSize: 11, color: "inherit" },
				}}
			>
				<MenuList sx={{ py: 0 }}>
					<MenuItem
						sx={props.eventItem?.is_approved ? { color: "green" } : {}}
						onClick={() => {
							if (props.eventItem) {
								props.updateHandler(props.eventItem?.id);
								props.onClose();
							}
						}}
					>
						<ListItemIcon>
							<FaRegEdit />
						</ListItemIcon>
						<ListItemText>{props.eventItem?.is_approved ? "Disapprove" : "Approve"}</ListItemText>
					</MenuItem>

					{/* <MenuItem
						sx={{ color: "red" }}
						onClick={() => {
							if (props.eventItem) props.deleteHandler(props.eventItem?.db_id);
						}}
					>
						<ListItemIcon>
							<FaRegTrashAlt />
						</ListItemIcon>
						<ListItemText>Delete</ListItemText>
					</MenuItem> */}
				</MenuList>
			</Paper>
		</ClickAwayListener>
	) : (
		<></>
	);
};

export default SchedulerContextMenu;
