import { ReactElement } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  SxProps,
  Typography,
  TypographyProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Loader } from "components";
import { planIcons } from "sections";
import { fetchSubscription } from "api/subscriptions";
import { KingIcon, StarIcon, AmExIcon } from "assets/svg";

const BillingSubscriptionDetails = ({
  companyID,
  isAdmin,
}: {
  companyID: string;
  isAdmin: boolean;
}) => {
  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  const { data, isInitialLoading } = useQuery(
    ["subscription-details", companyID],
    () => fetchSubscription(companyID),
    {
      enabled: Boolean(companyID && isAdmin),
    }
  );

  return isInitialLoading ? (
    <Loader />
  ) : (
    <Box>
      {/* <Stack direction={{ xs: "column", sm: "row" }} gap={2.5} pt={1.75}>
        <Text flex={1}>Subscriptions And Add-ons</Text>
        <Stack flex={1} justifyContent="space-between" gap={1.5}>
          <Text>Your Total Subscription Cost</Text>
          <Text>${totalCost}</Text>
        </Stack>
      </Stack> */}
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Box sx={{ justifyContent: "space-between", display: "flex" }}>
            <Typography
              variant="h3"
              fontSize={22}
              fontWeight={700}
              lineHeight="24.5px"
              sx={{
                paddingY: 0.5,
                paddingX: 1,
                borderRadius: "5.5px",
                borderLeft: "5px solid",
                borderLeftColor: "#FBB328",
              }}
            >
              Subscriptions And Add-ons
            </Typography>
            <Typography
              variant="h3"
              fontSize={22}
              fontWeight={700}
              lineHeight="24.5px"
              sx={{
                paddingY: 0.5,
                borderRadius: "5.5px",
              }}
            >
              $3140.00
            </Typography>
          </Box>
          <Box sx={{ justifyContent: "space-between", display: "flex" }}>
            <Typography
              variant="h3"
              fontSize={14}
              fontWeight={400}
              lineHeight="26px"
              sx={{
                paddingX: 1.7,
              }}
            >
              Manage Your Subscription
            </Typography>
            <Typography>Your Total Subscription Cost</Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#e5f2fc",
                padding: "10px 20px",
                borderRadius: "20px",
                color: "#007AE5",
                fontWeight: 600,
                fontSize: 16,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <KingIcon viewBox="0 0 18 20" width="24" height="24" />
              Ultimate
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  width: { xs: 250, lg: 250 },
                  height: { xs: 42, md: 48 },
                  fontWeight: 400,
                  fontSize: 16,
                }}
              >
                Manage Subscription
              </Button>
            </Box>
          </Box>
          <Box mt={3}>300 Users</Box>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              sx={{
                mt: 1,
              }}
            >
              <Typography
                component="span"
                sx={{ fontWeight: 800, fontSize: 28 }}
              >
                $15.00
              </Typography>{" "}
              per user / month
            </Typography>
            <Typography sx={{ fontSize: 28 }}>
              Total{" "}
              <Typography
                component="span"
                sx={{ fontSize: 28, fontWeight: 800 }}
              >
                $3000.00
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Payment And Billing Details
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            Manage your Payment methods and billing settings
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #ECECEC",
                  borderRadius: 3,
                  padding: 3,
                  mt: 5,
                  height: 460,
                }}
              >
                <Box
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    borderBottom: "1px solid #ECECEC",
                    paddingBottom: 3,
                  }}
                >
                  <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
                    Payment And Billing Details
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    // color={
                    //   Object.keys(form.formState.errors)?.length
                    //     ? "error"
                    //     : "primary"
                    // }
                    sx={{
                      // height: 50,
                      borderRadius: 10,
                      fontWeight: 600,
                      fontSize: { xs: 16, sm: 16 },
                      px: 5,
                    }}
                  >
                    View all
                  </Button>
                </Box>
                <Stack
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    border: "1px solid #ECECEC",
                    marginTop: 3,
                    borderRadius: 3,
                    paddingY: 3,
                    paddingX: 3,
                  }}
                >
                  <StarIcon />
                  <Box sx={{ marginLeft: 2 }}>
                    <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
                      National Australia Bank
                    </Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                      **** **** **** 1234
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    justifyContent: "start",
                    alignItems: "center",
                    border: "1px solid #ECECEC",
                    marginTop: 3,
                    borderRadius: 3,
                    paddingY: 3,
                    paddingX: 3,
                  }}
                >
                  <AmExIcon />
                  <Box sx={{ marginLeft: 2 }}>
                    <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
                      American Express Platinum Card
                    </Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                      **** **** **** 1234
                    </Typography>
                  </Box>
                </Stack>
                <Stack sx={{ justifyContent: "space-between", marginTop: 5 }}>
                  <Button
                    // fullWidth
                    type="submit"
                    variant="outlined"
                    sx={{
                      height: 50,
                      fontWeight: 600,
                      fontSize: { xs: 18, sm: 20 },
                      px: 5,
                    }}
                  >
                    Link Card or Bank
                  </Button>
                  <Button
                    // fullWidth
                    type="submit"
                    variant="contained"
                    color={"primary"}
                    sx={{
                      height: 50,
                      fontWeight: 600,
                      fontSize: { xs: 18, sm: 20 },
                      px: 5,
                    }}
                  >
                    Manage Cards or Bank
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #ECECEC",
                  borderRadius: 3,
                  padding: 3,
                  mt: 5,
                  height: 460,
                }}
              >
                <Box
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    borderBottom: "1px solid #ECECEC",
                    paddingBottom: 3,
                  }}
                >
                  <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
                    Activities
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    // color={
                    //   Object.keys(form.formState.errors)?.length
                    //     ? "error"
                    //     : "primary"
                    // }
                    sx={{
                      borderRadius: 10,
                      fontWeight: 600,
                      fontSize: { xs: 16, sm: 16 },
                      px: 5,
                    }}
                  >
                    View all
                  </Button>
                </Box>
                <Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Dessert (100g serving)</TableCell>
                          <TableCell align="right">Calories</TableCell>
                          <TableCell align="right">Fat&nbsp;(g)</TableCell>
                          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                          <TableCell align="right">Protein&nbsp;(g)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default BillingSubscriptionDetails;
