import dayjs from "dayjs";
import ReactQuill from "react-quill";
import { useMutation } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { ReactElement, useEffect, useState, memo } from "react";
import { Stack, Button, Typography, DialogActions, DialogContent, Dialog, Box, CircularProgress, paperClasses } from "@mui/material";
import { HiClock, HiLocationMarker } from "react-icons/hi";

import "react-quill/dist/quill.snow.css";
import "styles/quill.scss";

import { Shift } from "types";
import { FormInput, MapToDisplay } from "components";
import { ShiftDefaults } from "constants/FormDefaultValues";
import { ActiveCalenderIcon, LetterTIcon, WalletTotalIcon } from "assets/svg";
import { formatTimeStr } from "utils/data-helpers";
import { MdTimer } from "react-icons/md";
import { updateShift } from "api/shifts";

//

const InfoBlock = (props: { icon: ReactElement; label: string; value: string }) => (
	<Stack
		alignItems="center"
		borderRadius="10px"
		gap={{ xs: 1, sm: 2 }}
		boxShadow="0 4px 10px 0 #00000026"
		sx={{ pl: { xs: 1.5, sm: 2, md: 2.5 }, py: { xs: 1.5, sm: 2, md: 2.5 }, pr: 1 }}
	>
		<Stack
			sx={{
				alignItems: "center",
				bgcolor: "#007AE51A",
				color: "primary.main",
				borderRadius: "15px",
				justifyContent: "center",
				width: { xs: 36, sm: 48 },
				height: { xs: 36, sm: 48 },
				minWidth: { xs: 36, sm: 48 },
				svg: { width: { xs: 15, sm: 18 }, height: "auto" },
			}}
		>
			{props.icon}
		</Stack>

		<Box>
			<Typography variant="h6" fontSize={{ xs: 9, sm: 11 }} color="app.greyblue">
				{props.label}
			</Typography>
			<Typography fontSize={{ xs: 14, sm: 20 }} fontWeight={700} lineHeight={{ xs: "16.4px", sm: "27.3px" }} mt={{ xs: "2px", sm: 0.5 }}>
				{props.value}
			</Typography>
		</Box>
	</Stack>
);

type PropTypes = {
	isOpen: boolean;
	shiftInfo: Shift | null;
	closeModal: VoidFunction;
	refetchShifts: VoidFunction;
};

const ShiftForm = memo((props: PropTypes) => {
	const [mapLocation, setMapLocation] = useState<any | null>(null);
	const form = useForm<Shift>({ defaultValues: ShiftDefaults });
	const fV = form.getValues();

	// component side effects..

	useEffect(() => {
		if (props.shiftInfo) form.reset(props.shiftInfo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.shiftInfo]);

	useEffect(() => {
		if (props.shiftInfo) {
			const { location, latitude, longitude } = props.shiftInfo;
			setMapLocation({ place_name: location, geometry: { coordinates: [longitude, latitude] } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.shiftInfo]);

	// mutations..

	const updateMutation = useMutation(updateShift, {
		onSuccess: () => {
			props.refetchShifts();
			props.closeModal();
		},
	});

	// helpers..

	const submitHandler = (vals: Shift) => {
		if (props.shiftInfo?.id) {
			updateMutation.mutate({ id: props.shiftInfo.id, props: { employee_comment: vals?.employee_comment } });
		}
	};

	// jsx..

	return (
		<Dialog
			fullWidth
			open={props.isOpen}
			onClose={props.closeModal}
			sx={{ [`.${paperClasses.root}`]: { width: "100%", maxWidth: 1000, m: { xs: 3, sm: 4 }, borderRadius: "10px" } }}
		>
			<Box
				sx={{
					display: "grid",
					gap: { xs: 2, sm: 2.5 },
					pt: { xs: 2, sm: 3, md: 6.25 },
					px: { xs: 2, sm: 3, md: 6.25 },
					gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(3, 1fr)" },
					"@media(max-width: 400px)": { gridTemplateColumns: "repeat(1, 1fr)" },
				}}
			>
				<InfoBlock icon={<LetterTIcon />} label="Title" value={fV?.title} />

				<InfoBlock icon={<ActiveCalenderIcon />} label="Day" value={dayjs(fV?.target_date).format("dddd")} />

				<InfoBlock icon={<ActiveCalenderIcon />} label="Date" value={dayjs(fV?.target_date).format("DD/MM/YYYY")} />

				<InfoBlock icon={<HiClock />} label="Start/Finish Time" value={`${formatTimeStr(fV?.starting_time)} - ${formatTimeStr(fV?.ending_time)}`} />

				<InfoBlock label="Hourly Rate" icon={<MdTimer />} value={`$${fV?.hourly_charge}`} />

				<InfoBlock label="Total Pay For Shift" icon={<WalletTotalIcon />} value={`$${fV?.total_price}`} />
			</Box>

			{/* form section */}

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(submitHandler)}>
					<DialogContent sx={{ pt: 2.5, px: { xs: 2, sm: 3, md: 6.25 }, fieldset: { borderRadius: "10px" } }}>
						<FormInput
							label="Notes"
							name="manager_note"
							labelSx={{ fontSize: { xs: 15, sm: 18 }, color: "inherit", mb: { xs: 1, sm: 1.5 } }}
							textFieldProps={{ multiline: true, disabled: true, minRows: 2, maxRows: 3, title: "You can only read manager notes" }}
						/>

						<Typography fontSize={{ xs: 15, sm: 18 }} fontWeight={600} mt={{ xs: 2, sm: 2.5 }} mb={{ xs: 1, sm: 1.5 }}>
							Comments
						</Typography>
						<ReactQuill
							theme="snow"
							placeholder="Leave your message here"
							value={form.watch("employee_comment") || undefined}
							onChange={(val) => form.setValue("employee_comment", val)}
						/>

						<Box mt={{ xs: 2, sm: 2.5 }}>
							<Typography fontSize={{ xs: 15, sm: 18 }} fontWeight={600} mb={{ xs: 1, sm: 1.5 }}>
								Location
							</Typography>
							<Box position="relative" height={268}>
								<MapToDisplay noSearchBar selectedResult={mapLocation} setSelectedResult={setMapLocation} style={{ borderRadius: "20px" }} />

								<Stack
									minHeight={40}
									bgcolor="white"
									alignItems="center"
									position="absolute"
									borderRadius="10px"
									sx={{ gap: 1, left: { xs: 12, sm: 20 }, top: { xs: 12, sm: 20 }, mr: { xs: 1.5, sm: 2.5 }, px: 1.75, py: 1 }}
								>
									<HiLocationMarker />
									<Typography fontSize={12} fontWeight={500}>
										{fV?.location}
									</Typography>
								</Stack>
							</Box>
						</Box>
					</DialogContent>

					<DialogActions sx={{ justifyContent: "flex-start", px: { xs: 2, sm: 3, md: 6.25 }, pb: 3.75 }}>
						<Button type="submit" variant="contained" sx={{ width: "100%", height: 60, maxWidth: { sm: 300 }, fontSize: 18, borderRadius: "10px" }}>
							{updateMutation.isLoading ? <CircularProgress size={30} sx={{ color: "white" }} /> : "Save"}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
});

export default ShiftForm;
