import { useContext, useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Typography, Box, Button, Divider } from "@mui/material";
import supabase from "supabase/supabaseClient";
import { AuthLayout } from "layouts";
import { UserContext } from "context/UserContext";
import { signinHandler, updateUser } from "api/auth";
import { getPrevAccounts } from "utils/data-helpers";
import { MuiOtpInput } from "mui-one-time-password-input";
const QRScan = () => {
  const [factorId, setFactorId] = useState("");
  const [qr, setQR] = useState(""); // holds the QR code image SVG
  const [verifyCode, setVerifyCode] = useState(""); // contains the code entered by the user
  const [error, setError] = useState(""); // holds an error message
  const [errormessage, setErrorMessage] = useState("");
  let history = useHistory();
  const handleChange = (newValue: any) => {
    setVerifyCode(newValue);
  };
  const onEnableClicked = () => {
    setError("");
    if (verifyCode.length < 6) {
      setErrorMessage("Please enter 6 digits");
      return;
    }
    (async () => {
      const challenge = await supabase.auth.mfa.challenge({ factorId });
      if (challenge.error) {
        setError(challenge.error.message);
        return;
      }
      const challengeId = challenge.data.id;
      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode,
      });
      if (verify.error) {
        setError(verify.error.message);
        return;
      }
      history.push("/");
    })();
  };
  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: "totp",
      });
      if (error) {
        return;
      }
      setFactorId(data.id);
      // Supabase Auth returns an SVG QR code which you can convert into a data
      // URL that you can place in an <img> tag.
      setQR(data.totp.qr_code);
    })();
  }, []);
  return (
    <Box>
      <Typography>
        1. Download and install &nbsp;
        <span
          style={{
            color: "#007AE5",
            textDecoration: "underline",
            marginLeft: "2px",
          }}
        >
          Authy
        </span>
        &nbsp; or Google &nbsp;
        <span style={{ color: "#007AE5", textDecoration: "underline" }}>
          Authenticator
        </span>
      </Typography>
      <Box marginY={2}>
        <Divider />
      </Box>
      <Typography display={"flex"}>
        2. Open the authenticator app and scan the QR code using your phone
        camera
      </Typography>
      <Box display={"flex"} justifyItems={"center"} paddingY={1}>
        <img width={120} height={120} style={{ margin: "auto" }} src={qr} />
        {/* <img width={120} height={120} style={{margin:'auto',}} 
            src="https://randomqr.com/assets/images/randomqr-256.png" alt="" /> */}
      </Box>
      <Box marginY={2}>
        <Divider />
      </Box>
      <Typography display={"flex"}>
        3. Login with your QR code Enter your 6 digit code
      </Typography>
      <Box marginY={1}>
        <MuiOtpInput
          style={{
            width: "400px",
            height: "100px",
            gap: "0px",
          }}
          TextFieldsProps={{ placeholder: (index) => `${index + 1}` }}
          length={6}
          autoFocus
          value={verifyCode}
          onChange={handleChange}
        />
      </Box>
      <Typography
        color={"red"}
        style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}
      >
        {errormessage}
      </Typography>
      <Typography
        justifyContent={"center"}
        justifyItems={"center"}
        textAlign={"center"}
      >
        By Continuing, You agree To Our
        <span style={{ color: "#007AE5" }}> terms of Service </span>
      </Typography>
      <Box
        display={"flex"}
        justifyItems={"center"}
        textAlign={"center"}
        alignItems={"center"}
      >
        <Button
          onClick={onEnableClicked}
          style={{
            width: "90%",
            marginTop: "10px",
            background: "#007AE5",
            color: "white",
            fontWeight: 700,
            marginLeft: "20px",
          }}
        >
          Next
        </Button>
      </Box>
      <Link to={"/"} style={{ color: "gray", marginTop: "40px" }}>
        Skip & setup Later
      </Link>
    </Box>
  );
};
const AuthMFA = () => {
  const [verifyCode, setVerifyCode] = useState("");
  const [error, setError] = useState("");
  let history = useHistory();
  const handleChange = (newValue: any) => {
    setVerifyCode(newValue);
  };
  const onSubmitClicked = () => {
    setError("");
    (async () => {
      const factors = await supabase.auth.mfa.listFactors();
      if (factors.error) {
        // return
        throw factors.error;
      }
      const totpFactor = factors.data.totp[0];
      if (!totpFactor) {
        // return
        throw new Error("No TOTP factors found!");
      }
      const factorId = totpFactor.id;
      const challenge = await supabase.auth.mfa.challenge({ factorId });
      if (challenge.error) {
        setError(challenge.error.message);
        // return
        throw challenge.error;
      }
      const challengeId = challenge.data.id;
      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode,
      });
      if (verify.error) {
        setError(verify.error.message);
        // return
        throw verify.error;
      }
      history.push("/dashboard");
    })();
  };
  return (
    <>
      <div>Please enter the code from your authenticator app.</div>
      {error && <div className="error">{error}</div>}
      <Box marginY={1}>
        <MuiOtpInput
          style={{
            width: "400px",
            height: "100px",
            gap: "0px",
          }}
          TextFieldsProps={{ placeholder: (index) => `${index + 1}` }}
          length={6}
          autoFocus
          value={verifyCode}
          onChange={handleChange}
        />
      </Box>
      <Box
        display={"flex"}
        justifyItems={"center"}
        textAlign={"center"}
        alignItems={"center"}
      >
        <Button
          onClick={onSubmitClicked}
          style={{
            width: "90%",
            marginTop: "10px",
            background: "#007AE5",
            color: "white",
            fontWeight: 700,
            marginLeft: "20px",
          }}
        >
          Next
        </Button>
      </Box>
    </>
  );
};
const MultiAuthentication = () => {
  const history = useHistory();
  const user = useContext(UserContext);
  const [exitedRecents, setExitedRecents] = useState<boolean>(false);
  const form = useForm({
    defaultValues: { email: user?.email || "", password: "" },
  });
  // mutations..
  const mutationEffects = { onSuccess: () => history.push("/dashboard") };
  const signinMutation = useMutation(signinHandler, mutationEffects);
  const userUpdate = useMutation(
    (password: string) => updateUser({ mainData: { password } }),
    mutationEffects
  );
  const isLoading = userUpdate.isLoading || signinMutation.isLoading;
  // handlers..
  const hasRecentLogins = getPrevAccounts()?.length > 0 && !exitedRecents;
  const submitHandler = form.handleSubmit((vals) =>
    user?.email
      ? userUpdate.mutate(vals?.password)
      : signinMutation.mutate(vals)
  );
  // jsx..
  const [readyToShow, setReadyToShow] = useState(false);
  const [showMFAScreen, setShowMFAScreen] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const { data, error } =
          await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
        if (error) {
          // return
          throw error;
        }
        if (data.nextLevel === "aal2" && data.nextLevel === data.currentLevel) {
          history.push("/dashboard");
        }
        if (data.nextLevel === "aal2" && data.nextLevel !== data.currentLevel) {
          setShowMFAScreen(true);
        }
      } finally {
        setReadyToShow(true);
      }
    })();
  }, [history]);
  return (
    <AuthLayout hideGmailBlock={true} isRecentLogin={true}>
      <Box>
        <Typography fontSize={40} fontWeight={600} variant="h2">
          Sign Up
        </Typography>
        <Typography fontSize={12} paddingY={1} fontWeight={200} variant="h2">
          Setup 2 Factor Auth
        </Typography>
        {readyToShow ? showMFAScreen ? <AuthMFA /> : <QRScan /> : null}
      </Box>
    </AuthLayout>
  );
};
export default MultiAuthentication;
