import { Box, Radio, Stack, Typography, radioClasses, svgIconClasses } from "@mui/material";
import { Dispatch, ReactElement, SetStateAction } from "react";
import { HiCheckCircle } from "react-icons/hi";

import { PremiumPlanType } from "types";
import { featuresList, planFeatures, subscriptionPlanPrices } from "constants/subscriptions";
import { CompanyBuildingIcon, CrownIcon, MediumBuildingIcon, SmallHomeIcon } from "assets/svg";

//

export const planIcons: { [key in PremiumPlanType]: ReactElement } = {
	small: <SmallHomeIcon />,
	medium: <MediumBuildingIcon />,
	large: <CompanyBuildingIcon />,
	ultimate: <CrownIcon />,
};

const CheckBlock = (props: { name: string; isSelected: boolean; onClick: VoidFunction; icon: ReactElement; cost: number }) => (
	<Stack
		direction="column"
		alignItems="center"
		onClick={props.onClick}
		sx={{ width: "fit-content", cursor: "pointer", "& > svg": { width: "auto", height: 42, fill: props.isSelected ? "#007AE5" : "#9BA1CC" } }}
	>
		<Typography fontSize={14} fontWeight={800} lineHeight="22px" mb={0.875}>
			${props.cost}
		</Typography>

		{props.icon}

		<Typography color="#00000099" lineHeight="22px" textTransform="capitalize" mt={1} mb={0.5}>
			{props.name}
		</Typography>

		<Radio
			checked={props.isSelected}
			inputProps={{ "aria-label": props.name }}
			sx={{
				p: 0,
				[`.${svgIconClasses.root}:first-of-type`]: { fill: "#0000003B" },
				[`&.${radioClasses.checked} .${svgIconClasses.root}:last-of-type`]: { transform: "scale(1.3)" },
			}}
		/>
	</Stack>
);

const FeatureTick = ({ ticked, name }: { ticked: boolean; name: string }) => (
	<Stack alignItems="center" gap={1} sx={{ "& > svg": { fontSize: { xs: 13, sm: 17 } } }}>
		<HiCheckCircle color={ticked ? "#007AE5" : "#bfc3e3"} />
		<Typography fontFamily="Lato, Nunito, sans-serif" fontSize={{ xs: 12, sm: 15 }} color="#2D3748" lineHeight="20.8px">
			{name}
		</Typography>
	</Stack>
);

//

const PremiumPlanStep = ({ selectedPlan, setPlan }: { selectedPlan: PremiumPlanType; setPlan: Dispatch<SetStateAction<PremiumPlanType>> }) => {
	const getCheckProps = (name: PremiumPlanType) => ({
		name,
		icon: planIcons[name],
		onClick: () => setPlan(name),
		isSelected: selectedPlan === name,
		cost: subscriptionPlanPrices[name],
	});

	return (
		<Box mb={1.25}>
			<Stack justifyContent="space-between">
				{["small", "medium", "large", "ultimate"].map((type, i) => (
					<CheckBlock key={i} {...getCheckProps(type as PremiumPlanType)} />
				))}
			</Stack>

			<Typography color="#00000099" lineHeight="22px" mt={3.5}>
				The Ultimate Plan is the highest Tier Plan offered by the Shifti Platform, by signing up for the ultimate plan you get access to all add ons and
				everything available to large plan and enterprise plans. as-well as 24/7 support full comprehensive support
			</Typography>

			<Box
				sx={{
					display: "grid",
					gap: { xs: 1.25, sm: 2.5 },
					border: "1px solid #0000001A",
					p: { xs: 1.25, sm: "8px 12px 16px 16px" },
					"@media(min-width:325px)": { gridTemplateColumns: "repeat(2, 1fr)" },
					borderRadius: "20px",
					mt: 5,
				}}
			>
				{featuresList?.map((feature, i) => {
					const isTicked = selectedPlan === "ultimate" || planFeatures[selectedPlan]?.some((f) => f === feature);
					return <FeatureTick key={i} name={feature} ticked={isTicked} />;
				})}
			</Box>
		</Box>
	);
};

export default PremiumPlanStep;
