import React, { useState, memo, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  MobileStepper,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import SwipeableViews from "react-swipeable-views";
type PropTypes = {
  children: JSX.Element[];
  itemsPerView: number;
  title?: string;
  startWeek?: string;
  endWeek?: string;
};
const SwipeableMobileStepper = memo(
  ({ children, itemsPerView, title, startWeek, endWeek }: PropTypes) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = Math.ceil(children.length / itemsPerView);
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStepChange = (step: number) => {
      setActiveStep(step);
    };
    return (
      <Fragment>
        <Box
          justifyContent={"space-between"}
          alignItems={"center"}
          display={"flex"}
        >
          <Typography variant="h2" fontSize={24} fontWeight={700} mb={1.25}>
            {title}
          </Typography>

          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Typography variant="h2" fontSize={18} fontWeight={700} sx={{ backgroundColor: "#FFFFFF", px: 2, py: 1, borderRadius: "20px", border: "1px solid #000000" }}>
              {startWeek} - {endWeek}
            </Typography>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <IconButton
                  sx={{ backgroundColor: "#FFFFFF", width: 40, height: 40 }}
                  size="large"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {theme.direction === "rtl" ? (
                    <BsChevronLeft size={20} />
                  ) : (
                    <BsChevronRight size={20} />
                  )}
                </IconButton>
              }
              backButton={
                <IconButton
                  size="large"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: 40,
                    height: 40,
                    mr: 1,
                  }}
                >
                  {theme.direction === "rtl" ? (
                    <BsChevronRight size={20} />
                  ) : (
                    <BsChevronLeft size={20} />
                  )}
                </IconButton>
              }
              sx={{
                // width: "100%",
                // flex: 1,
                justifyContent: "right",
                // alignItems: "right",
                backgroundColor: "transparent",
                "& .MuiMobileStepper-dots": {
                  display: "none",
                },
              }}
            />
          </Stack>
        </Box>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {[...Array(maxSteps)].map((_, index) => {
            return (
              <Box
                key={`step-${index}`}
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "stretch",
                  // justifyContent: "space-evenly",
                }}
              >
                {children
                  .slice(
                    index * itemsPerView,
                    index * itemsPerView + itemsPerView
                  )
                  .map((child: any, childIndex: any) => {
                    return (
                      <Box width={"413px"} key={`step-${index}-${childIndex}`}>
                        {React.cloneElement(child as JSX.Element, {})}
                      </Box>
                    );
                  })}
              </Box>
            );
          })}
        </SwipeableViews>
      </Fragment>
    );
  }
);
export default SwipeableMobileStepper;
