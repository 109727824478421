import { Button, Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Shift } from "types";
import { FormProvider, useForm } from "react-hook-form";
import { ShiftDefaults } from "constants/FormDefaultValues";
export interface SimpleDialogProps {
  open: boolean;
  endShiftBreakConfirm: Function;
  onClose: (value: string) => void;
}
const DialogBreakClose = (props: SimpleDialogProps) => {
	const { onClose, open } = props;
  const form = useForm<Shift>({ defaultValues: ShiftDefaults });
  const handleClose = () => {
    onClose("false");
  };
  const submitHandler = ({ ...vals }: Shift) => {
    props.endShiftBreakConfirm();
    onClose("false");
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          textAlign: "center",
          mx: 10,
          fontSize: 28,
          fontWeight: "bold",
        }}
      >
        End Break? Confirm?
      </DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)}>
          <DialogContent>
            <Stack justifyContent="space-between" marginY={2}>
              <Button
                variant="contained"
                color="error"
                sx={{
                  px: 5,
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  px: 5,
                }}
              >
                Confirm
              </Button>
            </Stack>
          </DialogContent>
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default DialogBreakClose;
