import { Dispatch, SetStateAction } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";

import { KeyBgFillIcon } from "assets/svg";

type PropTypes = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  error?: string;
};

const EmployerInviteCodeStep = ({ value, setValue, error }: PropTypes) => (
  <Box mt={2}>
    <Typography sx={{ fontSize: "14px", fontWeight: 500, color: "#202422", mb: 1.5 }}>
      Enter your Invite Code
    </Typography>

    <FormControl>
      <OutlinedInput
        fullWidth
        value={value}
        error={!!error}
        placeholder="e.g., ABC123"
        onChange={(e) => setValue(e.target.value)}
        sx={{
          input: { height: 50, p: "0 6px" },
          "&, fieldset": { borderRadius: "10px", borderColor: "#EDEDED" },
          backgroundColor: "#F6F6F6",
        }}
        startAdornment={
          <InputAdornment position="end">
            <KeyBgFillIcon />
          </InputAdornment>
        }
      />

      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  </Box>
);

export default EmployerInviteCodeStep;
