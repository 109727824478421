import { Button } from "@mui/material";
export interface SimpleDialogProps {
  startShiftBreakHandler: Function;
}
const StartBreak = (props: SimpleDialogProps) => {
  const startShiftBreakHandler = () => {
    props.startShiftBreakHandler();
  };
  return (
    <Button
      variant="contained"
      color="primary"
      sx={{
        fontWeight: 600,
        fontSize: { xs: 13, sm: 14, md: 16, lg: 18, xl: 20 },
        px: { xs: 0.5, sm: 1, md: 2, lg: 3, xl: 4 },
        py: { xs: 1.5, xl: 1.5 },
        color: "#FFF",
        height: '52px',
        borderRadius:'8px',
        backgroundColor: '#84CC16',
        boxShadow: 'none' // Add this line to ensure no box sha

      }}
      onClick={startShiftBreakHandler}
    >
      Start Break
    </Button>
  );
};
export default StartBreak;
