import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";
import {
  LeaveRequest,
  LeaveRequestInsert,
  AvailabilityInsert,
  AvailabilityUpdate,
  Availability,
} from "types";
export const getLeaveRequestList = async (props: {
  company_id?: string;
  employee_id?: string;
}): Promise<LeaveRequest[]> => {
  const profileCols = "email, first_name, last_name, avatar_url";
  const colsToFetch = `*, employee:employee_id(${profileCols}), manager:notify_manager_id(${profileCols})`;
  let currentDate = new Date().toJSON().slice(0, 10);
  return await reqHandler(() =>
    supabase
      .from("leave_requests")
      .select(colsToFetch)
      .gte("resume_date", currentDate)
      .match(props)
  );
};
export const getAllLeaveRequest = async (): Promise<LeaveRequest[]> => {
  const profileCols = "email, first_name, last_name, avatar_url";
  const colsToFetch = `*, employee:employee_id(${profileCols}), manager:notify_manager_id(${profileCols})`;
  return await reqHandler(() =>
    supabase.from("leave_requests").select(colsToFetch)
  );
};
export const getEmployeeLeaveRequests = async (props: {
  employee_id?: string;
}): Promise<LeaveRequest[]> => {
  const profileCols = "email, first_name, last_name, avatar_url";
  const colsToFetch = `*, employee:employee_id(${profileCols}), manager:notify_manager_id(${profileCols})`;
  return await reqHandler(() =>
    supabase.from("leave_requests").select(colsToFetch).match(props)
  );
};
export const getLeaveHistoryRequestList = async (props: {
  company_id?: string;
  employee_id?: string;
}): Promise<LeaveRequest[]> => {
  const profileCols = "email, first_name, last_name, avatar_url";
  const colsToFetch = `*, employee:employee_id(${profileCols}), manager:notify_manager_id(${profileCols})`;
  let currentDate = new Date().toJSON().slice(0, 10);
  return await reqHandler(() =>
    supabase
      .from("leave_requests")
      .select(colsToFetch)
      .lt("resume_date", currentDate)
      .match(props)
  );
};
export const postLeaveRequest = async (request: LeaveRequestInsert) => {
  return await reqHandler(() =>
    supabase.from("leave_requests").insert(request)
  );
};
export const updateRequest = async ({
  id,
  props,
}: {
  id: number | string;
  props: any;
}) => {
  return await reqHandler(() => {
    return supabase
      .from("leave_requests")
      .update({ ...props })
      .match({ id });
  });
};
export const deleteRequest = async (id: number | string) => {
  return await reqHandler(() =>
    supabase.from("leave_requests").delete().match({ id })
  );
};
export const postAvailability = async (request: AvailabilityInsert) => {
  return await reqHandler(() => supabase.from("availability").insert(request));
};
export const updateAvailability = async (request: AvailabilityUpdate) => {
  return await reqHandler(() =>
    supabase
      .from("availability")
      .update({ availability_status: request!.availability_status })
      .eq("id", request!.id!)
  );
};
export const getEmployeeAvailability = async (props: {
  employee_id?: string;
}): Promise<Availability[]> => {
  const employeeId = props?.employee_id ?? "";
  return await reqHandler(() =>
    supabase.from("availability").select("*").eq("employee_id", employeeId)
  );
};
export const getAllAvailability = async (): Promise<Availability[]> => {
	const profileCols = "email, first_name, last_name, avatar_url";
  const colsToFetch = `*, employee:employee_id(${profileCols})`;
  return await reqHandler(() => supabase.from("availability").select(colsToFetch).eq("availability_status", "Unavailable"));
};
