import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { replaceText, formatTimeStr, getFullName } from "utils/data-helpers";
import { DbImage } from "components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Notification } from "types";
import {
  ShiftApproveIcon,
  ShiftRejectedIcon,
  ShiftStartSoonIcon,
  ShiftUpdatedIcon,
  NewShiftAssignIcon,
  LateStartIcon,
  EarlyStartIcon,
  EarlyFinishIcon,
} from "assets/svg";

dayjs.extend(relativeTime); // Extend dayjs with the relativeTime plugin

type PropTypes = {
  mutateUpdateNotification: (update: { id: number; is_read: boolean; status?: number }) => void;
  notification: Notification;
  i: number;
};

function NotificationCard(props: PropTypes) {
  const { notification, i, mutateUpdateNotification } = props;

  let availableText = {
    user_name: getFullName(notification?.employee),
    minutes: "",
    company_name: "",
    approver_name: getFullName(notification?.assign_user),
    num_shifts: "",
    start_date: notification?.shift?.target_date ?? "",
    end_date: notification?.shift?.target_date ?? "",
    start_time: formatTimeStr(notification?.shift?.starting_time) ?? "",
    end_time: formatTimeStr(notification?.shift?.ending_time) ?? "",
    shift_title: notification?.shift?.title ?? "",
  };

  let Icon = null;
  switch (notification?.notification_type_id) {
    case 14:
      Icon = <NewShiftAssignIcon width="36" height="36" />;
      break;
    case 18:
      Icon = <ShiftRejectedIcon width="36" height="36" />;
      break;
    case 17:
      Icon = <ShiftUpdatedIcon width="36" height="36" />;
      break;
    case 15:
      Icon = <ShiftApproveIcon width="36" height="36" />;
      break;
    case 7:
      Icon = <EarlyFinishIcon width="36" height="36" />;
      break;
    case 8:
      Icon = <ShiftApproveIcon width="36" height="36" />;
      break;
    case 1:
      Icon = <ShiftStartSoonIcon width="36" height="36" />;
      break;
    case 4:
      Icon = <LateStartIcon width="36" height="36" />;
      break;
    case 5:
      Icon = <EarlyStartIcon width="36" height="36" />;
      break;
    case 16:
      Icon = <ShiftStartSoonIcon width="36" height="36" />;
      break;
  }

  const onClickReadHandler = () => {
    mutateUpdateNotification({ id: notification?.id, is_read: true, status: 0 });
  };

  return (
    <Stack
      minHeight={notification && [4, 5, 7, 8].includes(notification.notification_type_id) ? 150 : 90}
      width={362}
      minWidth={'100%'}
      alignItems="center"
      gap={1}
      key={i}
      paddingBottom={'15px'}
      paddingTop={'15px'}
      sx={{
        borderBottom: "0.5px solid #E5E5E5",
        paddingLeft: 1,
        cursor: "pointer",
        overflow: "hidden",
        "&:hover": {
          backgroundColor: "#EBF4FD",
          borderRadius: '12px',
          "& > div": {
            borderRadius: '12px',
          },
        },
      }}
      onClick={onClickReadHandler}
    >
      {[4, 5, 7, 8].includes(notification?.notification_type_id) ? (
        <Stack sx={{ position: "relative" }}>
          <DbImage
            src={notification?.employee?.avatar_url}
            name={getFullName(notification?.employee)}
            sx={{ width: 30, height: 30, borderRadius: "50%" }}
            loaderSize={11}
          />
          <Stack
            sx={{
              position: "absolute",
              bottom: -2,
              right: -2,
              backgroundColor: "#FFFFFF",
            }}
          >
            {Icon}
          </Stack>
        </Stack>
      ) : (
        <Box sx={{ width: 30, height: 30 }}>{Icon}</Box>
      )}

      {[4, 5, 7, 8].includes(notification?.notification_type_id) ? (
        <Typography>
          <Stack flex={1} marginLeft={'12px'}>
            <Box
              lineHeight="12px"
              sx={{
                minWidth: '295px',
                backgroundColor: '#F6F6F6',
                padding: '15px',
                paddingTop: '10px',
                paddingBottom: '0px',
                borderRadius: '12px',
              }}
            >
              <Typography component="span" fontSize={15} fontWeight={500} mr={0.5}>
                {notification?.notification_type?.title}
              </Typography>
              <Typography
                variant="body2"
                fontSize={12}
                fontWeight={500}
                style={{ display: 'inline' }}
                marginLeft={'12px'}
                marginTop={'-1px'}
              >
                {dayjs(notification?.created_at).fromNow()}
              </Typography>
              <Typography variant="body2" fontSize={13} color="#797C7A" fontWeight={500}>
                {replaceText(
                  notification?.notification_type?.message_template,
                  availableText
                )}
              </Typography>
              <Box
                lineHeight="12px"
                sx={{
                  width: 'auto',
                  height: '52px',
                  marginBottom: '15px',
                  marginTop: '5px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '5px',
                  borderLeft: '2px solid #84CC16',
                }}
              >
                <Typography component="div">
                  <Typography component="span" fontSize={12} fontWeight={500} mr={0.5} color={'#84CC16'} marginLeft={'5px'}>
                    {availableText.shift_title}
                  </Typography>
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={14}
                  color="#797C7A"
                  fontWeight={400}
                  style={{ display: 'inline-block' }}
                  marginLeft={'5px'}
                >
                  {availableText.start_time} to {availableText.end_time}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={13}
                  color="#949695"
                  fontWeight={400}
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    marginRight: '5px',
                    marginTop: '0.5px',
                  }}
                >
                  {availableText.start_date}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Typography>
      ) : (
        <Stack flex={1} marginLeft={'12px'}>
          <Box lineHeight="12px">
            <Typography component="span" fontSize={15} fontWeight={500} mr={0.5}>
              {notification?.notification_type?.title}
            </Typography>
            <Typography
              variant="body2"
              fontSize={12}
              fontWeight={500}
              style={{ display: 'inline' }}
              marginLeft={'12px'}
              marginBottom={'11px'}
            >
              {dayjs(notification?.created_at).fromNow()}
            </Typography>
            <Typography variant="body2" fontSize={13} color="#797C7A" fontWeight={500}>
              {replaceText(
                notification?.notification_type?.message_template,
                availableText
              )}
            </Typography>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}

export default NotificationCard;