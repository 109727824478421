import { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
import { FormFieldCommonProps } from "types";
import { timeWithDayjs } from "utils/data-helpers";
import { CalenderIcon } from "assets/svg";
import dayjs from "dayjs";
interface PropTypes extends FormFieldCommonProps {
  onlyPickTime?: boolean;
  notRequired?: boolean;
  minDate?: boolean | Date;
  maxDate?: boolean | Date;
  filteredShiftsByTargetDate?: any;
}
const DateTimePicker = ({
  inputSx,
  label,
  labelSx,
  validations,
  onlyPickTime,
  notRequired,
  minDate = new Date(),
  maxDate,
  filteredShiftsByTargetDate,
  ...props
}: PropTypes) => {
  const form = useFormContext();
  const [open, setOpen] = useState<boolean>(false);
  const disableDates = (date: any) => {
    return filteredShiftsByTargetDate?.some((shift: any) =>
      dayjs(shift.target_date).isSame(date, "day")
    );
  };
  return (
    <Controller
      {...props}
      control={form.control}
      rules={{ required: !notRequired, ...validations }}
      render={({
        field: { ref, onBlur, name, ...field },
        fieldState: { error },
      }) => {
        const pickerProps = {
          ...field,
          minDate,
          maxDate,
          open: open,
          inputRef: ref,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
          value: field?.value ? timeWithDayjs(field.value) : null,
          renderInput: (inputProps: TextFieldProps) => (
            <TextField
              {...inputProps}
              name={name}
              sx={inputSx}
              onBlur={onBlur}
              error={!!error}
              onClick={() => setOpen(true)}
            />
          ),
        };
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl
              sx={{
                ".MuiButtonBase-root.MuiIconButton-root": { fontSize: "20px" },
              }}
            >
              <FormLabel sx={labelSx} required={!notRequired}>
                {label}
              </FormLabel>
              {onlyPickTime ? (
                <TimePicker
                  {...pickerProps}
                  components={{ OpenPickerIcon: FaAngleDown }}
                />
              ) : (
                <DesktopDatePicker
                  {...pickerProps}
                  inputFormat="DD/MM/YYYY"
                  shouldDisableDate={disableDates}
                  components={{ OpenPickerIcon: CalenderIcon }}
                />
              )}
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          </LocalizationProvider>
        );
      }}
    />
  );
};
export default DateTimePicker;
