import { Box, Stack, Typography, Paper, MenuItem } from "@mui/material";
import { Shift } from "types";
import { formatTimeStr } from "utils/data-helpers";
import {
  AmountIcon,
  TimeIcon1,
} from "assets/svg";
import { memo } from "react";
import { PopoverMenu, StatusBtn } from "components";
import { HiDotsVertical, HiOutlineEye } from "react-icons/hi";
import { TbArrowBadgeUp, TbArrowsExchange } from "react-icons/tb";
import { AiOutlineStop } from "react-icons/ai";
export interface Props {
  s: Shift;
  i: number;
  weekdate: string;
  weekName: string;
  shiftStatus: any;
  shiftLoading?: boolean;
}
const ScheduleShift = memo((props: Props) => {
  const { s, i, weekdate, weekName, shiftStatus, shiftLoading } = props;
  return (
    <Box
      component={Paper}
      elevation={0}
      key={i}
      minWidth={"413px"}
      sx={{
        borderRadius: 2,
        border: "1px solid #F5F5F5",
      }}
    >
      <Box mx={1} my={2.25} px={1} py={3}>
        <Stack alignItems="center" justifyContent={"space-between"} gap={0.625}>
          {/* <Typography
            fontSize={21}
            fontWeight={600}
            sx={{
              textTransform: "capitalize",
            }}
          >
            {s?.title}
          </Typography> */}
          <Typography fontSize={17} fontWeight={600}>
            {weekdate}
          </Typography>
          <StatusBtn
            status={shiftStatus}
            sx={{ width: "auto", fontSize: 16 }}
          />
          {/* <DoubleDotsIcon /> */}
        </Stack>
        <Box sx={{ py: 1.5 }}>
          <Typography fontSize={24} fontWeight={600} paddingBottom={0.2}>
            {s?.title.length > 26
              ? s?.title.substring(0, 26) + "..."
              : s?.title
              ? s?.title
              : "-"}
          </Typography>
          <Typography fontSize={17} fontWeight={300}>
            {s?.location.length > 40
              ? s?.location.substring(0, 40) + "..."
              : s?.location
              ? s?.location
              : "-"}
          </Typography>
        </Box>
        <Box sx={{ borderTop: "1px solid #E5E7EB", pt: 1 }}>
          <Stack alignItems="center" gap={0.625} py={1}>
            <Stack>
              <TimeIcon1 style={{ width: 23, height: "auto" }} />
            </Stack>
            <Typography fontSize={20} fontWeight={400}>
              {formatTimeStr(s?.starting_time)}
              {"-"}
              {formatTimeStr(s?.ending_time)}
            </Typography>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent={"space-between"}
            gap={0.625}
          >
            <Box>
              <Stack alignItems="center" justifyContent={"center"} gap={0.625}>
                <AmountIcon style={{ width: 23, height: "auto" }} />
                <Typography fontSize={20} fontWeight={400}>
                  ${s?.hourly_charge}/hour
                </Typography>
              </Stack>
            </Box>
            <PopoverMenu
              btnChild={<HiDotsVertical color="#000000" size={20} />}
              menuId={`start-menu`}
              btnId={`start-button`}
              btnSx={{ fontSize: 20, borderRadius: "50%", p: 0.5 }}
              menuChild={() => (
                <Box>
                  <MenuItem>
                    <HiOutlineEye />
                    View Shift Details
                  </MenuItem>
                  <MenuItem>
                    <TbArrowsExchange />
                    Swap Shift
                  </MenuItem>
                  <MenuItem>
                    <TbArrowBadgeUp />
                    Offer Shift
                  </MenuItem>
                  <MenuItem>
                    <AiOutlineStop />
                    Can’t make it
                  </MenuItem>
                </Box>
              )}
            />
          </Stack>
        </Box>
        {/* <Stack alignItems="center" gap={0.625} mb={2.5}>
          <Stack>
            <Location1Icon style={{ width: 10, height: "auto" }} />
          </Stack>
          <Typography fontSize={12} fontWeight={200} height={15}>
            {s?.location.length > 35 ? s?.location.substring(0, 35) + "..." : s?.location}
          </Typography>
        </Stack> */}
        {/* <StatusBtn status={shiftStatus} sx={{ width: "100%" }} /> */}
      </Box>
    </Box>
  );
});
export default ScheduleShift;
