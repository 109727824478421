import { CircularProgress, Stack } from "@mui/material";

//

type LoaderProps = { boxHeight?: number | string; verticalPadding?: string | number; iconSize?: number };

const Loader = (props: LoaderProps) => (
	<Stack height={props.boxHeight || "auto"} alignItems="center" justifyContent="center" py={props.verticalPadding || "20vh"}>
		<CircularProgress size={props.iconSize} />
	</Stack>
);

export default Loader;
