import { Dispatch, Fragment, SetStateAction, memo } from "react";
import { Button, TableCell, Box } from "@mui/material";
import { UseFieldArrayReturn } from "react-hook-form";
import { HiPlus } from "react-icons/hi";
import dayjs, { Dayjs } from "dayjs";
import { Shift, CompanySchedulerFormData, ContextMenuInfoType } from "types";
import { HorizontalScheduleCard } from "components";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekday from "dayjs/plugin/weekday";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);
//
type PropTypes = {
  email: string;
  employeeId: string;
  weekRange: Dayjs[] | undefined;
  forceReRender: Dispatch<SetStateAction<any>>;
  setSelectedEmployee: Dispatch<SetStateAction<any>>;
  setContextMenuInfo: Dispatch<SetStateAction<ContextMenuInfoType>>;
  setShiftModal: Dispatch<SetStateAction<"create" | Shift | null>>;
  setDetailsModal: any;
  shiftArray: UseFieldArrayReturn<CompanySchedulerFormData, "shifts", "id">;
  leaveArray: UseFieldArrayReturn<CompanySchedulerFormData, "leaves", "id">;
  availabilityArray: UseFieldArrayReturn<CompanySchedulerFormData, "availability", "id">;
};
const SchedulerRow = (props: PropTypes) => {
  // console.log("props", props.leaveArray);
  // const form = useFormContext();
  // console.log("availabilityArray", props.availabilityArray);
  const getCurrentWeekDay = (dayName: string) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let desiredDayIndex = days.indexOf(dayName);
    let currentDayIndex = dayjs().day();

    // Calculate the difference in days
    let dayDifference = desiredDayIndex - currentDayIndex;

    // Get the date of the desired day in the current week
    return dayjs().add(dayDifference, "day").format("YYYY-MM-DD");
  };
  const rowShifts = props.shiftArray?.fields?.filter(
    (s) => s?.employee?.email === props.email
  );
  const rowLeaves = props.leaveArray?.fields?.filter(
    (r) => r?.employee?.email === props.email
  );
  const rowAvailability = props.availabilityArray?.fields?.filter(
    (a) => a?.employee?.email === props.email
  );
  const tableCells = new Array(7).fill(0).map((_, dayIndex: number) => {
    const cellShifts = rowShifts?.filter((s) =>
      props.weekRange?.[dayIndex]?.isSame(dayjs(s?.target_date), "day")
    );
    // const cellLeaves = rowLeaves?.filter((r) =>
    //   props.weekRange?.[dayIndex]?.isSame(dayjs(r?.leaving_from_date), "day")
    // );
    const cellLeaves = rowLeaves?.filter((r) => {
      const leaveFromDate = dayjs(r?.leaving_from_date);
      const resumeDate = dayjs(r?.resume_date);
      const targetDate = props.weekRange?.[dayIndex];
      return (
        targetDate?.isSameOrAfter(leaveFromDate, "day") &&
        targetDate?.isSameOrBefore(resumeDate, "day")
      );
    });
    const cellAvailability = rowAvailability?.filter((r) => {
      const Adate = getCurrentWeekDay(r?.day_of_week);
      return props.weekRange?.[dayIndex]?.format("YYYY-MM-DD") === Adate;
    });
    // console.log("cellAvailability", cellAvailability);

    const dropHandler = (email: any, ev: any) => {
      ev.preventDefault();
      const eventId = ev.dataTransfer.getData("schedule-event-id");
      const cellDate = props.weekRange?.[dayIndex]?.format("YYYY-MM-DD");
      const shiftOnDragIndex = props.shiftArray?.fields?.findIndex(
        (s) => s?.id === eventId
      );
      const leaveOnDragIndex = props.leaveArray?.fields?.findIndex(
        (s) => s?.id === eventId
      );
      if (cellDate) {
        if (shiftOnDragIndex >= 0) {
          // updating event position
          props.shiftArray.fields[shiftOnDragIndex].target_date = cellDate;
          props.forceReRender({});
        } else if (leaveOnDragIndex >= 0) {
          const leaveOnDrag = props.leaveArray.fields[leaveOnDragIndex];
          const leaveDayCount = dayjs(leaveOnDrag?.resume_date)?.diff(
            leaveOnDrag?.leaving_from_date,
            "d"
          );
          const newResumeDate = dayjs(cellDate)
            ?.add(leaveDayCount, "day")
            ?.format("YYYY-MM-DD");
          // updating event position
          leaveOnDrag.leaving_from_date = cellDate;
          leaveOnDrag.resume_date = newResumeDate;
          props.forceReRender({});
        }
      }
    };
    // console.log("cellShifts", cellShifts);
    // console.log("cellLeaves", cellLeaves);
    return (
      <TableCell
        key={dayIndex}
        onDragOver={(ev: any) => ev.preventDefault()}
        onDrop={(ev) => dropHandler(props.email, ev)}
        sx={{
          position: "relative",
          // borderRight: "1px solid #ECEAF3",
          borderBottom: "1px solid #FFFFFF",
          p: 1,
        }}
      >
        {cellShifts?.length === 0 && cellLeaves?.length === 0 && cellAvailability?.length === 0 ? (
          <Button
            onClick={() => {
              props.setSelectedEmployee(props.employeeId);
              props.setShiftModal("create");
            }}
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              top: 8,
              left: 8,
              borderRadius: "5px",
              color: "#d3d3d3",
              bgcolor: "#f9f9f9",
              border: "2px dashed #d3d3d3",
              // width: "100%",
            }}
          >
            <HiPlus />
          </Button>
        ) : (
          <>
            {cellShifts?.map((shift, i) => (
              <Box key={i} onClick={() => props.setDetailsModal(shift)} sx={{}}>
                <HorizontalScheduleCard
                  key={i}
                  shift={shift}
                  wrapperProps={{
                    onContextMenu: (ev: MouseEvent) => {
                      ev.preventDefault();
                      props.setContextMenuInfo({
                        x: ev.pageX,
                        y: ev.pageY,
                        onShift: true,
                        fieldId: shift?.id,
                      });
                    },
                  }}
                />
              </Box>
            ))}
            {cellLeaves?.map((leave, i) => (
              <HorizontalScheduleCard
                key={i}
                leave={leave}
                wrapperProps={{
                  onContextMenu: (ev: MouseEvent) => {
                    ev.preventDefault();
                    props.setContextMenuInfo({
                      x: ev.pageX,
                      y: ev.pageY,
                      onShift: false,
                      fieldId: leave?.id,
                    });
                  },
                }}
              />
            ))}
            {cellAvailability?.map((availability, i) => (
              <HorizontalScheduleCard
                key={i}
                availability={availability}
                wrapperProps={{
                  onContextMenu: (ev: MouseEvent) => {
                    ev.preventDefault();
                    props.setContextMenuInfo({
                      x: ev.pageX,
                      y: ev.pageY,
                      onShift: false,
                      fieldId: availability?.id,
                    });
                  },
                }}
              />
            ))}
          </>
        )}
      </TableCell>
    );
  });
  return <>{tableCells}</>; // must wrap in fragment..
};
export default memo(SchedulerRow);
