import { Box, Grid, Stack } from "@mui/material";
import { PremiumPlanType } from "types";

//

type PropTypes = { plan: PremiumPlanType; planPrice: number; memberAmount: number };

const PaymentDetailStep = ({ plan, planPrice, memberAmount }: PropTypes) => (
	<Box mb={2.5}>
		<Grid
			container
			columns={4}
			sx={{ color: "#00000099", fontSize: 16, borderTop: "2px solid #818393", borderBottom: "2px solid #818393", lineHeight: "23px", pt: 1.5, pb: 2 }}
		>
			<Grid item xs={2}>
				<Stack direction="column" gap={1.5}>
					<p>Item</p>
					<p>
						${planPrice} {plan} plan
					</p>
					<p>Service Fee</p>
				</Stack>
			</Grid>

			<Grid item xs={1}>
				<Stack direction="column" gap={1.5}>
					<p>Amount</p>
					<p>{memberAmount}</p>
					<p>N/A</p>
				</Stack>
			</Grid>

			<Grid item xs={1}>
				<Stack direction="column" gap={1.5} textAlign="right">
					<p>Sub Total</p>
					<p>$ {planPrice * memberAmount}</p>
					<p>$ {25.5}</p>
				</Stack>
			</Grid>
		</Grid>

		<Stack justifyContent="space-between" fontSize={16} fontWeight={800} lineHeight="23px" mt={1.5}>
			<p>Total Due Today</p>
			<p>${planPrice * memberAmount + 25.5}</p>
		</Stack>
	</Box>
);

export default PaymentDetailStep;
