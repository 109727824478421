import React from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { ShiftDefaults } from "constants/FormDefaultValues";
import { Shift } from "types";
import {
  SickLeaveIcon,
  FamilyEmergencyIcon,
  BereavementIcon,
} from "assets/svg"; // Importing icons from the other page

interface SimpleDialogProps {
  open: boolean;
  startShiftLateHandler: Function;
  onClose: (value: string) => void;
}

const DialogShiftLateStart = (props: SimpleDialogProps) => {
  const { onClose, open } = props;
  const form = useForm<Shift>({ defaultValues: ShiftDefaults });

  const handleClose = () => {
    onClose("false");
  };

  const submitHandler = () => {
    const payload = {
      start_reason: form.watch("start_reason"),
    };
    props.startShiftLateHandler(payload);
    onClose("false");
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" sx={{ margin: "auto", borderRadius: "16px" }}>
      <Stack sx={{ position: "absolute", top: 0, right: 0, padding: 2, marginTop: "35px" }}>
        <Button onClick={handleClose} style={{ color: "#949695", backgroundColor: "transparent" }}>
          X
        </Button>
      </Stack>

      <DialogTitle>
        <Typography
          variant="h5"
          color="#202422"
          fontWeight={600}
          fontSize={24}
          marginLeft="10px"
          paddingLeft="9px"
          borderLeft="5px solid #F97316"
          height="32px"
          borderRadius="5px"
          marginTop="40px"
        >
          Starting Late
        </Typography>

        <Typography
          variant="body2"
          marginLeft="25px"
          color="#6C6E6D"
          fontSize={16}
          fontWeight={400}
        >
          You started your shift late. Would you like to provide a reason below?
        </Typography>
      </DialogTitle>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)}>
          <DialogContent>
            <RadioGroup
              aria-label="start_reason"
              name="start_reason"
              value={form.watch("start_reason") || ""}
              onChange={(e) => form.setValue("start_reason", e.target.value)}
            >
              <Stack direction="row">
                {["traffic_transport", "family_emergency", "bereavement"].map((startReason, index) => (
                  <Card
                    key={index}
                    sx={{
                      minWidth: 222,
                      minHeight: 218,
                      textAlign: "left",
                      margin: "0 10px",
                      position: "relative",
                      boxShadow: "none",
                      borderRadius: "8px",
                      border: "1px solid #EAEAEA",
                      background: "var(--White, #FFF)",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        borderBottom: "1px solid #ccc",
                        padding: "8px",
                        color: "#535655",
                        fontWeight: "600",
                        fontSize: "16px",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      {startReason === "traffic_transport"
                        ? "Traffic / Transport"
                        : startReason === "family_emergency"
                        ? "Family Emergency"
                        : startReason === "bereavement"
                        ? "Bereavement"
                        : "Other"}
                    </Typography>
                    <FormControlLabel
                      value={startReason}
                      control={
                        <Radio
                          style={{
                            color: form.watch("start_reason") === startReason ? "#1976D2" : "#E2E3E2",
                          }}
                        />
                      }
                      label=""
                      sx={{
                        position: "absolute",
                        top: 3,
                        right: -8,
                      }}
                    />
                    {startReason === "traffic_transport" && (
                      <SickLeaveIcon
                        height={"100px"}
                        width={"100px"}
                        opacity={"60%"}
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    )}
                    {startReason === "family_emergency" && (
                      <FamilyEmergencyIcon
                        height={"100px"}
                        width={"100px"}
                        opacity={"60%"}
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    )}
                    {startReason === "bereavement" && (
                      <BereavementIcon
                        height={"100px"}
                        width={"100px"}
                        opacity={"60%"}
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    )}
                  </Card>
                ))}
              </Stack>

              {/* Other card outside of the array */}
              <Card
                sx={{
                  width: "705px",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  margin: "0 10px",
                  marginTop: "25px",
                  height: "60px",
                  boxShadow: "none",
                  borderRadius: "8px",
                  border: "1px solid #EAEAEA",
                  background: "var(--White, #FFF)",
                  borderTopLeftRadius: form.watch("start_reason") === "other" ? '8px' : '8px',
                  borderBottomLeftRadius: form.watch("start_reason") === "other" ? '0px' : '8px',
                  borderTopRightRadius: form.watch("start_reason") === "other" ? '8px' : '8px',
                  borderBottomRightRadius: form.watch("start_reason") === "other" ? '0px' : '8px',
                  borderBottom: form.watch("start_reason") === "other" ? 'none' : '1px solid #EAEAEA',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    padding: "8px",
                    marginLeft: "20px",
                    color: "#535655",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Other
                </Typography>
                <FormControlLabel
                  value="other"
                  control={
                    <Radio
                      style={{
                        color: form.watch("start_reason") === "other" ? "#1976D2" : "#E2E3E2",
                      }}
                    />
                  }
                  label=""
                  sx={{
                    position: "absolute",
                    bottom: "8px",
                    right: "-8px",
                  }}
                />
              </Card>

              <div style={{ width: '705px', height: '226px', marginLeft: '10px', position: 'relative' }}>
                {form.watch("start_reason") === "other" && (
                  <input
                    name="custom_reason"
                    type="text"
                    style={{
                      width: '100%',
                      height: '100%',
                      border: "1px solid #EAEAEA",
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '8px',
                      boxSizing: 'border-box',
                      paddingTop: '0',
                      paddingBottom: '0',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                    }}
                  />
                )}
              </div>

            </RadioGroup>

            <Stack justifyContent="center" marginY={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  marginTop: "30px",
                  marginBottom: "40px",
                  height: "52px",
                  width: "215px",
                  fontSize: "16px",
                }}
              >
                Confirm
              </Button>
            </Stack>
          </DialogContent>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default DialogShiftLateStart;
