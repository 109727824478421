import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { DbImage } from "components";
import { useChatRoomID } from "hooks";
import { fetchLatestRoomMessage, fetchRoomNewMessageCount } from "api/messanger";

type PropTypes = {
  name: string;
  profileID: string;
  onClick: VoidFunction;
  avatarUrl: string | null;
  isActive: boolean;
};

const ChatListItem = (props: PropTypes) => {
  const [roomIdToUse, isRoomIdLoading, uid] = useChatRoomID(props.profileID);

  const { data: latestNewMsg, isInitialLoading: isNewMsgLoading } = useQuery({
    queryKey: [`room-${roomIdToUse}-latest-message`, roomIdToUse, uid],
    queryFn: () => fetchLatestRoomMessage(roomIdToUse),
    enabled: !!roomIdToUse,
  });

  const { data: newMsgIdList, isInitialLoading: isNewMsgIdListLoading } = useQuery({
    queryKey: [`room-${roomIdToUse}-new-messages-count`, roomIdToUse, uid],
    queryFn: () => fetchRoomNewMessageCount(roomIdToUse, uid),
    enabled: !!roomIdToUse,
  });

  const isItemLoading = isRoomIdLoading || isNewMsgLoading || isNewMsgIdListLoading;
  const hasNewMsg = newMsgIdList && newMsgIdList?.length > 0;

  const newMsgPreview = latestNewMsg?.[0]?.images_list && latestNewMsg[0].images_list?.length > 0 ? "received a photo" : latestNewMsg?.[0]?.content || "";

  const handleItemClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onClick();
  };

  return isItemLoading ? (

	<Stack gap={0} sx={{
        height: '58px',
        minWidth: '100%',
        borderRadius: '8px',
        padding: '10px',
        ":hover": { bgcolor: "#F6F6F6" },
        transition: "background-color 200ms",
        cursor: "pointer",
        bgcolor: props.isActive ? "#EBF4FD" : "transparent",
        marginBottom: "-8px",
      }}>
      <Skeleton variant="circular" width={34} height={34} />
	  <Skeleton variant="text" sx={{ flex: 1, marginLeft: '10px', height: '32px', width: '100%' }} />  
	    </Stack>
 
 ) : (
    <Stack
      gap={0}
      alignItems="center"
      title={newMsgPreview}
      onClick={handleItemClick}
      sx={{
        height: '58px',
        minWidth: '100%',
        borderRadius: '8px',
        padding: '10px',
        ":hover": { bgcolor: "#F6F6F6" },
        transition: "background-color 200ms",
        width: '100%',
        cursor: "pointer",
        bgcolor: props.isActive ? "#EBF4FD" : "transparent",
        marginBottom: "-8px",
      }}
    >
      <Stack width={hasNewMsg ? "85%" : "100%"} alignItems="center" gap={{ xs: 2, sm: 1.75 }}>
        <DbImage src={props.avatarUrl} name={props.name} />

        <Box flex={1}>
          <Typography fontSize={{ xs: 17.7, sm: 14 }} fontWeight={500} lineHeight={{ xs: "24px", sm: "20px" }} color={'#202422'}>
            {props.name}
          </Typography>

          <Typography
            maxWidth="80%"
            fontWeight={400}
            color="#616462"
            fontSize={{ xs: "10px", sm: "10px" }}
            sx={{ lineHeight: { xs: "21px", sm: "17px" }, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "clip" }}
          >
            {newMsgPreview}
          </Typography>
        </Box>
      </Stack>

      {hasNewMsg && (
        <Stack
          minWidth={{ xs: 30, sm: 24.4 }}
          height={{ xs: 30, sm: 24.4 }}
          width={{ xs: 30, sm: 24.4 }}
          justifyContent="center"
          alignItems="center"
          sx={{ fontSize: { xs: 15.5, sm: 12.65 }, bgcolor: "primary.main", borderRadius: "50%", userSelect: "none", color: "white" }}
        >
          {newMsgIdList.length}
        </Stack>
      )}
    </Stack>
  );
};

export default ChatListItem;
