import { Box, Stack, Typography } from "@mui/material";
import { Shift } from "types";
import { formatTimeStr } from "utils/data-helpers";
import dayjs from "dayjs";
import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { AmountIcon, TimeIcon1 } from "assets/svg";
import { getShiftStatus } from "utils/records-helpers";
import StatusBtn from "components/StatusBtn";
export interface Props {
  item: Shift;
  shiftLoading?: boolean;
}
const TodayScheduleShift = memo((props: Props) => {
  const { item, shiftLoading } = props;
  // console.log("item", item);
  const displayTimeOrSkeleton = item ? (
    <>
      {formatTimeStr(item?.starting_time)}
      {" - "}
      {formatTimeStr(item?.ending_time)}
    </>
  ) : (
    <Skeleton width={150} height={20} />
  );
  const shiftStatus = getShiftStatus(item);
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box>
        <Typography
          fontSize={17}
          fontWeight={700}
          display={"flex"}
          alignItems={"center"}
        >
          {(item?.target_date &&
            !shiftLoading &&
            dayjs(item?.target_date).format("DD MMM, YYYY")) || (
            <Skeleton width={100} height={20} />
          )}
          <StatusBtn
            status={shiftStatus}
            sx={{ width: "auto", fontSize: 16, ml: 1 }}
          />
        </Typography>
        <Typography
          variant="h2"
          fontSize={24}
          fontWeight={700}
          sx={{ pb: 1, pt: 2 }}
        >
          {item?.title && item?.title.length > 30
            ? item?.title.substring(0, 30) + "..."
            : item?.title || <Skeleton width={200} height={40} />}
        </Typography>
        <Stack alignItems="center" sx={{ rowGap: 1.5, columnGap: 1 }}>
          <Typography fontSize={20} fontWeight={200}>
            {item?.location && item?.location.length > 30
              ? item?.location.substring(0, 30) + "..."
              : item?.location || <Skeleton width={200} height={30} />}
          </Typography>
        </Stack>
      </Box>
      <Stack>
        <Box>
          <Typography
            fontSize={19}
            fontWeight={400}
            display={"flex"}
            alignItems={"center"}
          >
            <TimeIcon1 color="#9BA1CC" />
            <Typography variant="h6" ml={0.5}>
              Shift Time
            </Typography>
          </Typography>
          <Stack gap={0.625} pt={2}>
            <Typography fontSize={19} fontWeight={400} display={"flex"}>
              {displayTimeOrSkeleton}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ ml: 3 }}>
          <Typography
            fontSize={19}
            fontWeight={400}
            display={"flex"}
            alignItems={"center"}
          >
            <AmountIcon color="#9BA1CC" />
            <Typography variant="h6" ml={0.5}>
              Hourly Rate
            </Typography>
          </Typography>
          <Stack alignItems="center" gap={0.625} pt={2}>
            <Typography
              variant="h6"
              fontSize={18}
              fontWeight={400}
              display={"flex"}
            >
              <Typography variant="h6">
                {item?.hourly_charge ? (
                  `$${item?.hourly_charge}/hour`
                ) : (
                  <Skeleton width={100} height={30} />
                )}
              </Typography>
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
});
export default TodayScheduleShift;
