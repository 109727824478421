import { useState, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  TabProps,
  TextField,
  tabsClasses,
} from "@mui/material";
import {
  useForm,
  FormProvider,
  UseFormReturn,
  SubmitHandler,
} from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Dayjs } from "dayjs";
import { LeaveRequestDefaults } from "constants/FormDefaultValues";
import { UserContext } from "context/UserContext";
import { postLeaveRequest } from "api/leave-request";

import { LeaveRequest } from "types";
import { DateTimePicker, Loader, SelectField } from "components";
import { getFullName, datesToString } from "utils/data-helpers";
import { getCompanyManagers } from "api/companies";
import styled from "@emotion/styled";
import { WhiteRightIcon } from "assets/svg";

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  () => ({
    fontSize: 14,
    paddingLeft: 30,
    paddingRight: 30,
    marginRight: 16,
    fontWeight: 600,
    borderRadius: "50px",
    textTransform: "capitalize",
    backgroundColor: "#F6F6F6",
    transition: "all 200ms ease-in-out",
    "&:hover": { color: "#FFFFFF", backgroundColor: "#202422" },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#202422",
    },
  })
);

type PropTypes = { form: UseFormReturn<LeaveRequest> };
type FormTypes = "Sick Leave" | "Annual Leave" | "Personal Leave";
const RequestLeave = ({ form }: PropTypes) => {
  const { id: uid, company_id } = useContext(UserContext) || {};
  const [initialUserLoading] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<FormTypes>("Sick Leave");
  const formMethods = useForm<LeaveRequest>({
    defaultValues: LeaveRequestDefaults,
  });

  const [comment, setComment] = useState("");
  const managersQuery = useQuery(["company-mangers", company_id], () =>
    getCompanyManagers(company_id)
  );

  const managerList = managersQuery.data?.map(({ profiles }) => ({
    value: profiles?.id,
    label: getFullName(profiles),
  }));

  const postMutation = useMutation(["post-request"], postLeaveRequest, {
    onSuccess: () => {
      formMethods.reset();
      toast.success("Your request was submitted..");
    },
  });

  const onSubmit: SubmitHandler<LeaveRequest> = (data) => {
    postMutation.mutate({
      ...data,
      comment: comment,
      employee_id: uid,
      company_id: company_id,
      ...datesToString(data, ["leaving_from_date", "resume_date"]),
      type_of_leave: leaveType,
    });
  };

  // jsx..

  return initialUserLoading ? (
    <Loader />
  ) : (
    <FormProvider {...form}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)} pt={2.5}>
        <section>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h1" fontSize={20} fontWeight={600}>
              Request Your Leave{" "}
            </Typography>

            <Button
              variant="text"
              onClick={() => form.reset()}
              sx={{
                py: 0.25,
                mb: 1.25,
                ml: "auto",
                fontWeight: 600,
                display: "block",
                textTransform: "capitalize",
              }}
            >
              Clear
            </Button>
          </Box>
          <Box component="section">
            <Paper
              elevation={0}
              sx={{
                borderRadius: "20px",
                px: { xs: 3, sm: 6.25 },
                pt: { xs: 4, sm: 5.625 },
                pb: { xs: 5, sm: 7 },
              }}
            >
              {/* <FormProvider {...formMethods}> */}
              {/* <form onSubmit={form.handleSubmit(onSubmit)}> */}
              <Grid
                container
                columns={4}
                spacing={{ xs: 3.75, sm: 2.5 }}
                sx={{
                  ".MuiFormLabel-root": {
                    color: "#202422",
                    fontSize: "18px",
                    fontWeight: 600,
                    mb: 0.6,
                  },
                  ".MuiInputBase-root": {
                    pr: 3,
                    ".MuiInputBase-input": { pl: 2.5 },
                    fieldset: { borderRadius: "10px" },
                    "svg.MuiSelect-icon": { right: 17, fontSize: "22px" },
                  },
                }}
              >
                <Grid item xs={2} sm={1}>
                  <SelectField
                    label="Notify Manager"
                    labelSx={{
                      textTransform: "capitalize",
                      fontSize: "16px !important",
                      fontWeight: "500 !important",
                      marginBottom: "4px",
                    }}
                    name="notify_manager_id"
                    options={managerList || []}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "16px !important",
                      fontWeight: "500 !important",
                      marginBottom: "4px",
                      "&:focus": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    Request Note{" "}
                  </Typography>
                  <TextField
                    type="text"
                    label=""
                    required
                    name="comment"
                    autoComplete="off"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <DateTimePicker
                    label="From"
                    name="leaving_from_date"
                    labelSx={{
                      textTransform: "capitalize",
                      fontSize: "16px !important",
                      fontWeight: "500 !important",
                      marginBottom: "2px",
                    }}
                  />
                </Grid>

                <Grid item xs={2} sm={1}>
                  <DateTimePicker
                    label="To"
                    labelSx={{
                      textTransform: "capitalize",
                      fontSize: "16px !important",
                      fontWeight: "500 !important",
                      marginBottom: "2px",
                    }}
                    name="resume_date"
                    validations={{
                      validate: (val: Dayjs) => {
                        const fromDate =
                          formMethods.getValues("leaving_from_date");
                        return fromDate
                          ? Number(val) > Number(fromDate)
                            ? true
                            : "Must be after From date"
                          : true;
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 500,
                  marginBottom: "8px",
                  marginTop: "24px",
                }}
              >
                Leave Type
              </Typography>
              <Box
                alignItems={"center"}
                display={"flex"}
                sx={{ flexGrow: 1, marginTop: "8px" }}
              >
                <Tabs
                  value={leaveType}
                  onChange={(_ev, newVal) => setLeaveType(newVal)}
                  centered
                  sx={{
                    [`.${tabsClasses.indicator}`]: { display: "none" },
                  }}
                >
                  <StyledTab value="Sick Leave" label="Sick Leave" />
                  <StyledTab value="Annual Leave" label="Annual Leave" />
                  <StyledTab value="Personal Leave" label="Personal Leave" />
                </Tabs>
              </Box>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  width: "fit-content",
                  padding: "14px 28px",
                  display: "flex",
                  fontSize: 14,
                  minHeight: 48,
                  borderRadius: "4px",
                  mt: "50px",
                }}
              >
                {postMutation.isLoading ? (
                  <CircularProgress size={30} sx={{ color: "white" }} />
                ) : (
                  "Send Request"
                )}
                <WhiteRightIcon style={{ marginLeft: "10px" }} />
              </Button>
              {/* </form> */}
              {/* </FormProvider> */}
            </Paper>
          </Box>
        </section>
      </Box>
    </FormProvider>
  );
};

export default RequestLeave;
