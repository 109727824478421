import { Link } from "react-router-dom";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { RiFacebookFill, RiLinkedinFill, RiTwitterFill } from "react-icons/ri";

import { SessionUser } from "types";
import { DbImage } from "components";
import { getFullName } from "utils/data-helpers";

//

const ProfileSummary = ({ user }: { user: SessionUser }) => (
	<Stack
		flex={1}
		minWidth={{ sm: 220 }}
		maxWidth={{ sm: 275 }}
		width={{ xs: "100%", sm: "auto" }}
		direction="column"
		alignItems="center"
		borderRadius="10px"
		textAlign="center"
		bgcolor="white"
		py={{ xs: 6, sm: 4.25 }}
		px={2}
	>
		<DbImage
			loaderSize={30}
			src={user?.avatar_url}
			name={getFullName(user)}
			sx={{ width: { xs: 144, sm: 100 }, height: { xs: 144, sm: 100 }, borderRadius: { xs: "28px", sm: "20px" } }}
		/>

		<Typography variant="h3" fontSize={16} fontWeight={700} lineHeight="22px" mt={2}>
			{getFullName(user)}
		</Typography>

		{!user?.profession && (
			<Typography variant="caption" color="app.greyblue" fontSize={12} lineHeight="14px">
				{user?.profession || "UX UI Designer"}
			</Typography>
		)}

		<Typography variant="caption" color="#1E3A56" fontSize={11} fontWeight={700} lineHeight="16px" mt={0.5}>
			Membership in 3 Years
		</Typography>

		{user?.caption && (
			<Typography variant="caption" color="#B0CAD1" fontSize={12} lineHeight="16px" mt={0.4}>
				{user.caption}
			</Typography>
		)}

		<Stack alignItems="center" gap={0.4} mt={1}>
			<IconButton size="small" component="a" target="_blank" rel="noreferrer" href="https://www.facebook.com/" sx={{ color: "#4267B2" }}>
				<RiFacebookFill />
			</IconButton>

			<IconButton size="small" component="a" target="_blank" rel="noreferrer" href="https://www.twitter.com/" sx={{ color: "#1DA1F2 " }}>
				<RiTwitterFill />
			</IconButton>

			<IconButton size="small" component="a" target="_blank" rel="noreferrer" href="https://www.linkedin.com/" sx={{ color: "#0072b1" }}>
				<RiLinkedinFill />
			</IconButton>
		</Stack>

		<Button
			variant="contained"
			component={Link}
			to="/settings"
			sx={{
				width: { xs: 172, sm: 120 },
				height: { xs: 44, sm: 30 },
				fontSize: { sm: 12 },
				fontWeight: 600,
				borderRadius: "5px",
				mt: 2,
			}}
		>
			Edit Profile
		</Button>
	</Stack>
);

export default ProfileSummary;
