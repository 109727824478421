import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiTwotoneSave } from "react-icons/ai";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  buttonClasses,
  inputBaseClasses,
  Box,
  Grid,
} from "@mui/material";
import { Company } from "types";
import { useDbImageHandler } from "hooks";
import {
  getCompany,
  getCompanyByInvite,
  getNewCompanyInviteCode,
  updateCompany,
} from "api/companies";
import {
  AvatarBlock,
  CopyTextIcon,
  Loader,
  SettingInput,
  LoadingOverlay,
  TimezonesInput,
} from "components";
import { EditIcon, ResetClockIcon, TimezoneIcon } from "assets/svg";
import { getUpdateValues } from "utils/api-helpers";
//
type PropTypes = {
  form: UseFormReturn<Company>;
  companyID: string;
  isAdmin: boolean;
};
const PaymentRatesEmployee = ({ form, companyID, isAdmin }: PropTypes) => {
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [deleteAvatar, setDeleteAvatar] = useState<boolean>(false);
  const [canEditInviteCode, setEditInviteCode] = useState<boolean>(false);
  const { dbImgHandler, deleteDbImg, areImageOperationsLoading } =
    useDbImageHandler("avatars", `${form.getValues("name")} logo`);
  /**  api calls.. **/
  const {
    refetch: refetchCompany,
    data: initialCompanyDetails,
    isInitialLoading: isCompanyLoading,
  } = useQuery(
    ["company-setting-details", companyID],
    () => getCompany({ id: companyID }),
    {
      enabled: Boolean(isAdmin && companyID),
    }
  );
  // form values sync with DB company record..
  useEffect(() => {
    if (initialCompanyDetails?.name) form.reset(initialCompanyDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCompanyDetails]);
  const { mutate: saveSettings, isLoading: isSavingSettings } = useMutation({
    mutationKey: ["company-settings-update"],
    mutationFn: (vals: Company) =>
      updateCompany({
        id: companyID,
        props: getUpdateValues(initialCompanyDetails, vals),
      }),
    onSuccess: () => refetchCompany(),
  });
  const { mutate: mutateValidateInvite, isLoading: isValidatingInvite } =
    useMutation(() => getCompanyByInvite(form.getValues("invite_code")), {
      onError: () => toast.success("You can save this code."), // success on 0 company returned..
      onSuccess: () => {
        toast.error("This code is currently in use.");
        form.setValue("invite_code", initialCompanyDetails?.invite_code);
      },
    });
  const { mutate: mutateNewInviteCode, isLoading: isGeneratingInviteCode } =
    useMutation(getNewCompanyInviteCode, {
      onSuccess: (code) => {
        form.setValue("invite_code", code);
        toast.success("New employee invite code generated.");
      },
    });
  // utilities..
  const onLogoRemoveClick = () => {
    if (deleteAvatar) setDeleteAvatar(true);
    if (logoFile) setLogoFile(null);
  };
  const submitHandler = (vals: Company) => {
    const logoPath = vals?.logo_url; // existing logo..
    if (logoFile) {
      dbImgHandler(logoFile, logoPath, (res: any) =>
        saveSettings({ ...vals, ...(!logoPath && { logo_url: res }) })
      );
    } else if (deleteAvatar && logoPath) {
      deleteDbImg([logoPath], {
        onSuccess: () => {
          saveSettings({ ...vals, logo_url: null });
          setDeleteAvatar(false);
        },
      });
    } else saveSettings(vals);
  };
  // jsx..
  return (
    <FormProvider {...form}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Payment Rates for Employees
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            Manage payment rates for your employees
          </Typography>
        </Box>
        <Stack
          component="form"
          onSubmit={form.handleSubmit(submitHandler)}
          gap={2}
        >
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <SettingInput
                name="user"
                label="User"
                required="User Required"
                typeCompany
              />
            </Grid>
            <Grid item xs={4}>
              <SettingInput
                name="payment_rate"
                label="Payment Rate"
                required="Payment Rate Required"
                typeCompany
              />
            </Grid>
            <Grid item xs={4}>
              <SettingInput
                name="accruel_rates"
                label="Accruel Rates"
                required="Accruel Rates Required"
                typeCompany
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <LoadingOverlay open={isSavingSettings || areImageOperationsLoading} />
    </FormProvider>
  );
};
export default PaymentRatesEmployee;
