import { FormControl, FormLabel, TextField, TextFieldProps } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldCommonProps } from "types";

//

type FormInputProps = FormFieldCommonProps & {
	rules?: any;
	inLabel?: string;
	isLabelLg?: boolean;
	placeholder?: string;
	noLabelStar?: boolean;
	required?: string | boolean;
	textFieldProps?: TextFieldProps;
};

const FormInput = (props: FormInputProps) => {
	const form = useFormContext();

	return (
		<Controller
			name={props.name}
			control={form.control}
			rules={{ required: props.required, ...props.validations }}
			render={({ field, fieldState: { error } }) => {
				if ([undefined, null].includes(field?.value)) field = { ...field, value: "" };

				const inputField = <TextField {...field} helperText={error?.message} {...props.textFieldProps} label={props.inLabel} error={!!error} />;
				//
				return props.label ? (
					<FormControl>
						<FormLabel
							error={!!error}
							required={Boolean(props.required) && !props.noLabelStar}
							sx={{
								fontSize: 18,
								fontWeight: 600,
								color: props.textFieldProps?.disabled ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0.6)",
								...props.labelSx,
							}}
						>
							{props.label}
						</FormLabel>

						{inputField}
					</FormControl>
				) : (
					inputField
				);
			}}
		/>
	);
};

export default FormInput;
