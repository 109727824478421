import { Fragment, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getCompany,
} from "api/companies";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  paperClasses,
  buttonClasses,
  menuItemClasses,
  typographyClasses,
} from "@mui/material";
import { HiChevronDown } from "react-icons/hi";
import { usePrevAccounts } from "hooks";
import { UserContext } from "context/UserContext";
import { PopoverMenu, DbImage, LoadingOverlay, Loader } from "components";
import { getFullName } from "utils/data-helpers";
import { signOutHandler } from "api/auth";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  ProfileIcon,
  SettingsIcon,
  LogoutIcon
} from "assets/svg";

const HeaderRightMenu = () => {

  const [companyName, setCompanyName] = useState(""); // State to store the company name

  useEffect(() => {
    // Fetch the company details when the component mounts
    const fetchCompanyName = async () => {
      try {
        // Replace "yourActualCompanyId" with the actual UUID representing the company ID
        const companyData = await getCompany({ id: (user.company_id), select: "name" });
        setCompanyName(companyData?.name || "Default Company Name");
      } catch (error) {
        console.error("Error fetching company name:", error);
        setCompanyName("Default Company Name");
      }
    };
  
    fetchCompanyName();
  }, []);
  
  const location = useLocation();
  const user = useContext(UserContext);
  const { prevProfilesLoading } = usePrevAccounts(user?.id);
  const { mutate: mutateSignOut, isLoading: isSignOutLoading } =
    useMutation(signOutHandler);
  return (
    <>
      <PopoverMenu
        menuId="header-right-menu"
        btnId="header-right-menu-button"
        btnProps={{ variant: "text", endIcon: <HiChevronDown color="#6B7280" /> }}
        menuSx={{
          [`.${paperClasses.root}`]: {
            minWidth: 150,
            marginTop: -0.6,
            borderRadius: "10px",
            boxShadow: "0 4px 4px #00000020",
          },
        }}
        btnSx={{
          gap: "10px",
          minWidth: "auto",
          textAlign: "left",
          borderRadius: "10px",
          paddingTop:'0xp',
          [`.${buttonClasses.endIcon}`]: {
            height: 20,
            display: user?.first_name ? { xs: "none", md: "block" } : "none",
          },
        }}
        btnChild={
          <>
            <Box
              sx={{
                borderRadius: "50%",
                margin: "8px",
              }}
            >

                <DbImage
                  src={user?.avatar_url}
                  name={getFullName(user)}
                  loaderSize={18}
                  keepDefaultSx
                  sx={{
                    width: 32,
                    height: 32,
               
                  }}
                  size={32}
                />
      
            </Box>
            {user?.first_name && (
              <Box
                maxWidth={200}
                display={{ xs: "none", md: "block" }}
                sx={{
                  [`.${typographyClasses.root}`]: {
                    overflow: "clip",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                <Typography fontWeight={600} color="#253334" fontSize={13}>
                  {`${user?.first_name} ${user?.last_name}`}
                </Typography>
                {user?.role ? (


    <Typography
      style={{
        fontWeight: 600,
        backgroundColor:
          user?.role.toLowerCase() === 'admin'
            ? '#E0F2FE'
            : user?.role.toLowerCase() === 'employee'
            ? '#E9E9E9'
            : user?.role.toLowerCase() === 'manager'
            ? '#FFF7ED'
            : undefined,
        color:
          user?.role.toLowerCase() === 'admin'
            ? '#0284C7'
            : user?.role.toLowerCase() === 'employee'
            ? '#797C7A'
            : user?.role.toLowerCase() === 'manager'
            ? '#EA580C'
            : undefined,
        fontSize: 11,
        textAlign:"center",
        borderRadius:'30px'
      }}
    >
      {user?.role}
    </Typography>




   
                ) : null}
              </Box>
            )}
          </>
        }
        menuChild={(closePopover) => (
          <div>
            {prevProfilesLoading ? (
              <Loader verticalPadding={2} iconSize={22} />
            ) : (
              // prevLoggedProfiles?.map((account: PrevAccount, i: number) => {
              //   const accountHolderName = getFullName(account);
              //   return (
              //     <MenuItem
              //       key={i}
              //       onClick={() => {
              //         switchAccountInOneClick(account?.id, () =>
              //           navigate("/auth/sign-in")
              //         );
              //         closePopover();
              //       }}
              //       sx={{
              //         gap: 1,
              //         py: 1.25,
              //         [`&.${menuItemClasses.root}`]: { pl: 2, pr: 1 },
              //         borderBottom: "1px solid #E5E5E5",
              //       }}
              //     >
              //       <DbImage
              //         src={account?.avatar_url}
              //         name={accountHolderName}
              //         sx={{ width: 26, height: 26 }}
              //         loaderSize={11}
              //       />
              //       <Box flex={1}>
              //         <Typography
              //           color="#253334"
              //           fontSize={10}
              //           fontWeight={500}
              //           lineHeight="13.65px"
              //         >
              //           {accountHolderName || account?.email}
              //         </Typography>
              //         {user?.role && (
              //           <Typography
              //             color="app.greyblue"
              //             fontSize={8}
              //             lineHeight="11px"
              //             textTransform="capitalize"
              //           >
              //             {user?.role}, Shifti Technologies
              //           </Typography>
              //         )}
              //       </Box>
              //       <IconButton
              //         sx={{ width: 20, height: 20, opacity: 0.6, p: 0.5 }}
              //         onClick={(ev) => {
              //           ev.stopPropagation();
              //           removeAccountFromList(account?.id);
              //           refetchPrevProfiles();
              //         }}
              //       >
              //         <MdDelete />
              //       </IconButton>
              //     </MenuItem>
              //   );
              // })
              <></>
            )}
            <Box p="0 20px 20px">
              <Typography
                sx={{
                  display: "inline-block",
                  fontSize: 10,
                  fontWeight: 500,
                  color: "#818393",
                  // mt: 1.8,
                  mb: 1.5,
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   mutateSignOut();
                //   closePopover();
                // }}
              >
                {/* + Add Another Account */}
              </Typography>
              <MenuItem
                style={{
                  padding: 0,
                  borderBottom: "1px solid #E5E5E5",
                  backgroundColor: "transparent", // Set the background color to transparent or your desired color
                }}
                component={Link}
                to="/profile"
                onClick={() => {
                  // Handle Profile click
                  closePopover();
                }}
                selected={location.pathname === '/profile'}
                sx={{
                  '&:hover': {
                    color: 'blue', // Change color on hover to blue
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0px', marginBottom: '10px' }}>
                  <ProfileIcon style={{ marginRight: 10, marginLeft: '5px', width: 16, height: 16 }} /> {/* Replace with your ProfileIcon */}
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 500, color: "#6B7280" }}
                  >
                    Profile
                  </Typography>
                </Box>
              </MenuItem>

              <MenuItem
                style={{
                  padding: 0,
                  borderBottom: "1px solid #E5E5E5",
                  backgroundColor: "transparent", // Set the background color to transparent or your desired color
                }}
                component={Link}
                to="/settings"
                onClick={() => {
                  // Handle Settings click
                  closePopover();
                }}
                selected={location.pathname === '/settings'}
                sx={{
                  '&:hover': {
                    color: 'blue', // Change color on hover to blue
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                  <SettingsIcon style={{ marginRight: 10, marginLeft: '3px', width: 16, height: 16 }} /> {/* Replace with your SettingsIcon */}
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 500, color: "#6B7280" }}
                  >
                    Settings
                  </Typography>
                </Box>
              </MenuItem>

              <MenuItem
                style={{
                  padding: 0,
                  backgroundColor: "transparent", // Set the background color to transparent or your desired color
                }}
                onClick={() => {
                  mutateSignOut();
                  closePopover();
                }}
                sx={{
                  '&:hover': {
                    color: 'blue', // Change color on hover to blue
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '0px' }}>
                  <LogoutIcon style={{ marginRight: 12, marginLeft: '3px', width: 16, height: 16 }} /> {/* Replace with your LogoutIcon */}
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 500, color: "#6B7280" }}
                  >
                    Logout
                  </Typography>
                </Box>
              </MenuItem>
            </Box>
          </div>
        )}
      />
      {/* <LoadingOverlay open={isSignOutLoading} /> */}
    </>
  );
};
export default HeaderRightMenu;
