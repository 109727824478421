import { useContext } from "react";
import { Box, Paper, Stack, Grid } from "@mui/material";
import dayjs from "dayjs";
import { Break, Shift } from "types";
import {
  twoDatePercentage,
  // checkBreakTimeConsume,
} from "utils/data-helpers";
import { useState, useEffect, memo } from "react";
import {
  DialogShiftEarlyClose,
  DialogShiftEarlyStart,
  DialogShiftLateStart,
  DialogShiftLateClose,
  DialogBreakClose,
  ButtonDisplayLogic,
  TodayScheduleShift,
  NoShiftToday,
} from "components/shift";
import duration from "dayjs/plugin/duration";
import { UserContext } from "context/UserContext";
dayjs.extend(duration);
type PropsTypes = {
  mutateUpdate: Function;
  mutateEndShift: Function;
  mutateEarlyEndShift: Function;
  mutateEarlyStartShift: Function;
  mutateLateStartShift: Function;
  mutateStartShiftBreak: Function;
  mutateEndShiftBreak: Function;
  item?: Shift;
  shiftBreaks?: Break[];
  notEndedBreak?: Break;
  mutateUnScheduleShift: Function;
  shiftLoading?: boolean;
};
const TodayShiftCard = memo((props: PropsTypes) => {
  const user = useContext(UserContext);
  const { item, shiftBreaks, notEndedBreak, shiftLoading } = props;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    shiftStartText: "",
    shiftFlag: "0",
  });
  // let breakStatus = checkBreakTimeConsume(shiftBreaks, item?.break_allowance);
  let currentTime = dayjs().format("HH:mm:ss");
  let currentDateTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
  let currentDate = dayjs().format("YYYY-MM-DD");
  const shiftDatetime = item?.target_date + " " + item?.starting_time;
  const startDatetime = item?.target_date + " " + item?.starting_time;
  const endDatetime = item?.target_date + " " + item?.ending_time;
  const actualStartTime =
    item?.actual_start_time && item?.actual_start_time.replace("T", " ");
  const actualEndTime =
    item?.actual_end_time && item?.actual_end_time.replace("T", " ");
  const actualStartTimeFormat = dayjs(actualStartTime).format(
    "MMM DD, YYYY HH:mm:ss"
  );
  const actualEndTimeFormat = dayjs(actualEndTime).format(
    "MMM DD, YYYY HH:mm:ss"
  );
  const startDatetimeFormat = item?.actual_start_time
    ? actualStartTimeFormat
    : dayjs(startDatetime).format("MMM DD, YYYY HH:mm:ss"); // Aug 26, 2023 15:37:25
  const endDatetimeFormat = item?.actual_end_time
    ? actualEndTimeFormat
    : dayjs(endDatetime).format("MMM DD, YYYY HH:mm:ss"); // Aug 26, 2023 15:37:25
  const visibleProgress = ["4", "5", "6", "9", "8"];
  // console.log("item", item);
  useEffect(() => {
    // shift not started yet you can start early
    if (
      dayjs().isBefore(shiftDatetime, "m") &&
      item?.actual_start_time === null
    ) {
      setState((s) => ({
        ...s,
        shiftStartText: "Shift Starts",
        shiftFlag: "1",
      }));
    }
    // shift not started yet you can start late

    if (
      dayjs().isAfter(shiftDatetime, "m") &&
      item?.actual_start_time === null
    ) {
      setState((s) => ({
        ...s,
        shiftStartText: "Shift Started",
        shiftFlag: "3",
      }));
    }
    // shift already start and running. End Shift and Start Break button visible
    if (item?.shift_status === "4") {
      setState((s) => ({
        ...s,
        shiftStartText: "On Shift for",
        shiftFlag: "4",
      }));
    }
    // shift already start and running. break is also running
    if (item?.shift_status === "5") {
      setState((s) => ({
        ...s,
        shiftStartText: "Left of Break",
        shiftFlag: "5",
      }));
    }
    // shift already start and running but break is also complete
    if (item?.shift_status === "6") {
      setState((s) => ({
        ...s,
        shiftStartText: "On Shift for",
        shiftFlag: "6",
      }));
    }
    // shift already start and running but overtime is also happening
    if (
      item?.ending_time! < currentTime &&
      notEndedBreak?.start_date_time &&
      notEndedBreak?.end_date_time &&
      item?.actual_start_time !== null
    ) {
      setState((s) => ({
        ...s,
        shiftStartText: "On Shift for",
        shiftFlag: "8",
      }));
    }
    // shift already start and running but overtime is not happening
    if (item?.shift_status === "9") {
      setState((s) => ({
        ...s,
        shiftStartText: "On Shift for",
        shiftFlag: "9",
      }));
    }
    // if (
    //   item?.ending_time! > currentTime &&
    //   notEndedBreak?.start_date_time &&
    //   notEndedBreak?.end_date_time &&
    //   item?.actual_start_time !== null
    // ) {
    //   setState((s) => ({
    //     ...s,
    //     shiftStartText: "On Shift for",
    //     shiftFlag: "9",
    //   }));
    // }
    // Break already start and running end break also visible( break time passed but not closed yet)
    // if (item?.actual_start_time && item?.actual_end_time) {
    //   setState((s) => ({
    //     ...s,
    //     shiftStartText: "",
    //     shiftFlag: "7",
    //   }));
    // }
    // shift not started yet you can start now
    if (
      dayjs().isSame(shiftDatetime, "m") &&
      item?.actual_start_time === null
    ) {
      setState((s) => ({
        ...s,
        shiftStartText: "Shift Starts",
        shiftFlag: "2",
      }));
    }
  }, [shiftDatetime, item, notEndedBreak, currentTime]);
  const progressvalue = twoDatePercentage(
    startDatetimeFormat,
    endDatetimeFormat
  );
  const submitHandler = () => {
    if (state.shiftFlag === "3" || state.shiftFlag === "1") {
      setOpen(true);
    } else {
      props.mutateUpdate({ id: item?.id });
    }
  };
  const endShiftHandler = () => {
    if (
      state.shiftFlag === "4" ||
      state.shiftFlag === "9" ||
      state.shiftFlag === "8" ||
      state.shiftFlag === "6"
    ) {
      setOpen(true);
    } else {
      // console.log("else end shift");
      // props.mutateEndShift({ id: item?.id });
    }
  };
  const handleClose = (value: string) => {
    setOpen(false);
    // setSelectedValue(value);
  };
  const startShiftBreakHandler = () => {
    props.mutateStartShiftBreak({
      shift_id: item?.id,
      start_date_time: currentTime,
      end_date_time: null,
      shift_status: "5",
    });
  };
  const endShiftBreakHandler = () => {
    if (state.shiftFlag === "5") {
      setOpen(true);
    }
  };
  const endShiftBreakConfirm = () => {
    props.mutateEndShiftBreak({
      id: notEndedBreak?.id,
      // start_date_time: null,
      end_date_time: currentTime,
      shift_status: 6,
      shift_id: item?.id,
    });
  };
  // const endShiftConfirm = () => {
  //   props.mutateEndShift({ id: item?.id });
  // };
  const startShiftEarlyHandler = (payload: Shift) => {
    props.mutateEarlyStartShift({
      id: item?.id,
      actual_start_time: currentDateTime,
      start_reason: payload.start_reason,
      start_status: "early",
      shift_status: "4",
    });
  };
  const startShiftLateHandler = (payload: Shift) => {
    props.mutateLateStartShift({
      id: item?.id,
      actual_start_time: currentDateTime,
      start_reason: payload.start_reason,
      start_status: "late",
      shift_status: "4",
    });
  };
  const endShiftEarlyHandler = (payload: Shift) => {
    props.mutateEarlyEndShift({
      id: item?.id,
      actual_end_time: currentDateTime,
      end_reason: payload.end_reason,
      end_status: "early",
      shift_status: "0",
    });
    setState({ ...state, shiftFlag: "0" });
  };
  const endShiftLateHandler = (payload: Shift) => {
    props.mutateEarlyEndShift({
      id: item?.id,
      actual_end_time: currentDateTime,
      end_reason: payload.end_reason,
      end_status: "late",
      shift_status: "0",
    });
    setState({ ...state, shiftFlag: "0" });
  };
  const unScheduleShiftHandler = () => {
    props.mutateUnScheduleShift({
      title: "Unscheduled Shift",
      target_date: currentDate,
      hourly_charge: 0,
      total_price: 0,
      starting_time: currentTime,
      actual_start_time: currentDateTime,
      ending_time: dayjs().add(dayjs.duration(1, "h")).format("HH:mm:ss"),
      employee_id: user.id,
      break_allowance: 5,
      company_id: user.company_id,
      location: "",
      latitude: 0,
      longitude: 0,
      assign_user_id: user.id,
      shift_status: 4,
    });
  };
  const display_item = item && (
    <>
      {(state.shiftFlag === "4" ||
        state.shiftFlag === "9" ||
        state.shiftFlag === "6") && (
        <DialogShiftEarlyClose
          open={open}
          onClose={handleClose}
          endShiftEarlyHandler={endShiftEarlyHandler}
        />
      )}
      {state.shiftFlag === "1" && (
        <DialogShiftEarlyStart
          open={open}
          onClose={handleClose}
          startShiftEarlyHandler={startShiftEarlyHandler}
        />
      )}
      {state.shiftFlag === "3" && (
        <DialogShiftLateStart
          open={open}
          onClose={handleClose}
          startShiftLateHandler={startShiftLateHandler}
        />
      )}
      {state.shiftFlag === "8" && (
        <DialogShiftLateClose
          open={open}
          onClose={handleClose}
          endShiftLateHandler={endShiftLateHandler}
        />
      )}
      {state.shiftFlag === "5" && (
        <DialogBreakClose
          open={open}
          onClose={handleClose}
          endShiftBreakConfirm={endShiftBreakConfirm}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TodayScheduleShift item={item} shiftLoading={shiftLoading} />
        </Grid>
        <Grid item xs={4}>
          {state.shiftFlag !== "0" && (
            <ButtonDisplayLogic
              state={state}
              progressvalue={progressvalue}
              shiftBreaks={shiftBreaks}
              item={item}
              visibleProgress={visibleProgress}
              shiftDatetime={shiftDatetime}
              {...{
                endShiftBreakHandler,
                startShiftBreakHandler,
                endShiftHandler,
                submitHandler,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
  // console.log("item", item);
  return (
    <Box
      component={Paper}
      elevation={0}
      sx={{
        position: "relative",
        borderRadius: "20px",
        overflow: "hidden",
      }}
    >
      <Box mx={4}>
        <Stack
          flexWrap="wrap"
          className="top-header-block"
          justifyContent={item ? "space-between" : "space-around"}
          alignItems="center"
          sx={{ rowGap: 1.5, columnGap: 1, mb: 10, mt: 5 }}
        >
          {display_item}
          {!item && !shiftLoading && (
            <NoShiftToday unScheduleShiftHandler={unScheduleShiftHandler} />
          )}
        </Stack>
      </Box>
    </Box>
  );
});
export default TodayShiftCard;
