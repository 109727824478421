import { ReactElement, useState } from "react";
import { Box, Button, ButtonProps, CircularProgress, Menu, menuClasses, menuItemClasses, MenuProps, paperClasses, SxProps } from "@mui/material";

//

type PropTypes = {
	btnId: string;
	menuId: string;
	btnSx?: SxProps;
	rootSx?: SxProps;
	menuSx?: SxProps;
	isBtnLoading?: boolean;
	btnProps?: ButtonProps;
	paperMinWidth?: number;
	menuProps?: Partial<MenuProps>;
	btnChild: ReactElement | string;
	menuChild: (onClose: Function) => ReactElement;
};

const PopoverMenu = (props: PropTypes) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	//
	return (
		<Box className="popover-menu-wrapper">
<Button
  {...props.btnProps}
  id={props.btnId}
  aria-haspopup="true"
  sx={{
    minWidth: 0,
    ...props.btnSx,
    "&:hover": {
      backgroundColor: "transparent", // Set hover background to transparent
    },
    "&:active": {
      backgroundColor: "transparent !important", // Set active background to transparent
      boxShadow: "none !important", // Remove box shadow on active
      margin: 0, // Set margin to zero on active
    },
  }}
  aria-expanded={anchorEl ? "true" : undefined}
  aria-controls={anchorEl ? props.menuId : undefined}
  onClick={(e) => {
    e.stopPropagation();
    if (!props.isBtnLoading) setAnchorEl(e.currentTarget);
  }}
>
  {props.isBtnLoading ? <CircularProgress size={15} /> : props.btnChild}
</Button>



			<Menu
				elevation={0}
				open={!!anchorEl}
				id={props.menuId}
				anchorEl={anchorEl}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				MenuListProps={{ "aria-labelledby": props.btnId }}
				onClose={() => setAnchorEl(null)}
				{...props.menuProps}
				sx={{
					zIndex: 1000,

					[`.${paperClasses.root}`]: {
						p: 0,
						mt: 0.5,
						borderRadius: 2,
						minWidth: props.paperMinWidth,
						boxShadow: "0 5px 12px rgb(0 0 0 / 0.2)",
					},

					[`.${menuClasses.list}`]: { p: 0 },

					[`.${menuItemClasses.root}`]: {
						px: 1.5,
						minHeight: 0,
						fontSize: 12,
						transition: "background-color 0.15s",
						"& > svg": { fontSize: 14, mr: 0.65 },
					},

					...props.menuSx,
				}}
			>
				{props.menuChild(() => setAnchorEl(null))}
			</Menu>
		</Box>
	);
};

export default PopoverMenu;
