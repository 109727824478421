import { Button, SxProps, Typography, Box } from "@mui/material";
import { Dispatch, SetStateAction, useMemo } from "react";
import { DbImage } from "components";
//
type AvatarBlockProps = {
  setFile: Dispatch<SetStateAction<File | null>>;
  removeHandler: VoidFunction;
  file: string | File | null;
  isLoading?: boolean;
  avatarSx?: SxProps;
  title?: string;
  sx?: SxProps;
  avatarProps: {
    name: string | null;
    loaderSize?: number;
    hasLocalFile?: boolean;
  };
};
const btnStyles = {
  width: "100%",
  height: 50,
  fontSize: 17,
  fontWeight: 700,
  borderRadius: "5px",
  marginY: 1,
};
const AvatarBlock = ({
  file,
  setFile,
  title,
  avatarProps,
  removeHandler,
  isLoading,
  sx,
  avatarSx,
}: AvatarBlockProps) => {
  const imageProps = useMemo(() => {
    return {
      ...avatarProps,
      src: file
        ? typeof file === "string"
          ? file
          : URL.createObjectURL(file)
        : "",
      sx: {
        height: 100,
        borderRadius: { xs: "28px", sm: "20px" },
        margin: "auto",
        width: "100%",
        ...avatarSx,
      },
    };
  }, [file, avatarProps, avatarSx]);
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        columnGap: { xs: 2.5, sm: 4.5 },
        ...sx,
      }}
    >
      {title && (
        <Typography
          variant="h3"
          fontSize={18}
          fontWeight={600}
          lineHeight="24.5px"
          mb={0.625}
        >
          {title}
        </Typography>
      )}
      <DbImage {...imageProps} size={75} />
      <Typography sx={{ py: 1, fontSize: 20, fontWeight: 700 }}>
        {avatarProps.name}
      </Typography>
      <Button variant="contained" component="label" sx={btnStyles}>
        <input
          hidden
          type="file"
          accept="image/*"
          disabled={isLoading}
          onChange={(e) => {
            if (e.target?.files?.length) setFile(e.target.files[0]);
          }}
        />
        <span>Upload</span>
      </Button>
      <Button
        variant="outlined"
        sx={{ ...btnStyles, borderWidth: "2px" }}
        onClick={removeHandler}
      >
        Remove
      </Button>
    </Box>
  );
};
export default AvatarBlock;
