import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { SessionUser, Company } from "types";
import { inValidateOtherSessions, changePassword } from "api/auth";
import { Loader, SettingInput } from "components";
import { EmailMixedColorIcon } from "assets/svg";
import Password from "./security/Password";
import Timezone from "./company/Timezone";
import YourDevices from "./security/YourDevices";
import RecoveryMethod from "./security/RecoveryMethod";
import ThirdPartyIntegration from "./security/ThirdPartyIntegration";
//
type PropTypes = {
  form: UseFormReturn<SessionUser>;
  user: SessionUser;
  form1: UseFormReturn<Company>;
};
const SecuritySettings = ({ form, user, form1 }: PropTypes) => {
  const [initialUserLoading, setInitialUserLoading] = useState<boolean>(true);
  const { mutate: mutateInValidateOtherUser } = useMutation(
    inValidateOtherSessions,
    {
      onSuccess: () => {
        toast.success("Session invalidated successfully");
      },
    }
  );
  const { mutate: mutateChangePassword } = useMutation(changePassword, {
    onSuccess: (res) => {
      if (res) {
        toast.success("Password updated successfully");
      }
    },
  });
  useEffect(() => {
    if (user) {
      form.reset(user);
      if (initialUserLoading) setInitialUserLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const deviceLogout = async () => {
    let randomID = localStorage.getItem("randomID");
    mutateInValidateOtherUser({ uid: user.id, random_id: randomID });
  };
  return initialUserLoading ? (
    <Loader />
  ) : (
    <FormProvider {...form} {...form1}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Security
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            Manage your security setting
          </Typography>
        </Box>
        <Box component="form" pt={2.5}>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(2, minmax(0, 1fr))",
            }}
            columnGap={2.65}
            rowGap={4}
          >
            <SettingInput
              textFieldProps={{ type: "email" }}
              name="email"
              label="Email address"
              icon={<EmailMixedColorIcon />}
              required="Email Required"
            />
            <SettingInput
              textFieldProps={{ type: "password" }}
              name="password"
              label="Password"
              required="Password"
            />
          </Box>
        </Box>
      </Box>
      <Password
        form={form}
        companyID=""
        isAdmin
        mutateChangePassword={mutateChangePassword}
      />
      <Timezone form={form1} companyID="" isAdmin />
      <YourDevices
        form={form1}
        user={user}
        isAdmin
        deviceLogout={deviceLogout}
      />
      <RecoveryMethod form={form1} companyID="" isAdmin />
      <ThirdPartyIntegration form={form1} companyID="" isAdmin />
    </FormProvider>
  );
};
export default SecuritySettings;
