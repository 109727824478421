import { Suspense, useContext, useEffect } from "react";
import { Route, useLocation, Switch } from "react-router-dom";
import supabase from "supabase/supabaseClient";
import RoutesConf, { Page404, Profile, Settings } from "./routes-conf";
import MainLayout from "layouts/MainLayout";
import { UserContext } from "context/UserContext";
import { Loader } from "components";
import MultiAuthentication from "pages/auth/MultiAuthentication";

//

const AppRoutes = () => {
	const user = useContext(UserContext);
	const { pathname } = useLocation();

	const atAuthPages = pathname.startsWith("/auth/");
	const userType = user?.role || "employee"; // we want to show employee routes for unnasigned users also..
	// routes..

	const allRoutes = (
		<Suspense fallback={<Loader iconSize={65} verticalPadding="40vh" />}>
			<Switch>
				{RoutesConf.auth?.map(({ Component, ...props }, i) => (
					<Route key={i} {...props} exact>
						<Component />
					</Route>
				))}

				{RoutesConf[userType]?.map(({ Component, ...props }, i) => (
					<Route key={i} {...props} exact>
						<Component />
					</Route>
				))}

				<Route path="/profile" exact>
					<Profile />
				</Route>
				<Route path="/settings" exact>
					<Settings />
				</Route>
				<Route path="*">
				<Route path="/multi-authentication">
					<MultiAuthentication />
				</Route>
					<Page404 />
				</Route>
			</Switch>
		</Suspense>
	);

	//

	return atAuthPages ? allRoutes : <MainLayout>{allRoutes}</MainLayout>;
};

export default AppRoutes;
