import { ChangeEvent } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

import { PasswordInput } from "components";

//

type PropTypes = { termChecked: boolean; setTermCheck: (val: ChangeEvent<HTMLInputElement>) => void; termError: string };

const PasswordStep = ({ termChecked, setTermCheck, termError }: PropTypes) => (
	<div>
		<PasswordInput />

		<PasswordInput
			isConfirm
			topSpacing={0.75}
			validations={{
				validate: (val: string, vals: { password: string }) => {
					return vals?.password !== val ? "passwords do not match" : null;
				},
			}}
		/>

		<FormControlLabel
			sx={{ color: termError ? "red" : "#00000099", mt: 3 }}
			control={<Checkbox size="small" checked={termChecked} onChange={setTermCheck} />}
			label={
				<>
					By Ticking This Box, You Agree To Our{" "}
					<Box component="span" color="primary.main" fontWeight={600}>
						Terms Of Service.
					</Box>
				</>
			}
		/>
	</div>
);

export default PasswordStep;
