import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography, Skeleton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { UserContext } from "context/UserContext";
import { getCompany } from "api/companies";
import { HomeIcon, ActiveHomeIcon, ReportIcon, MeIcon, UserIcon, ScheduleMenuIcon } from "assets/svg";

interface SideNavbarProps { 
  isSidebarMinimized: boolean; 
  setSidebarMinimized: React.Dispatch<React.SetStateAction<boolean>>; 
}
interface NavItemProps { 
  url: string; 
  text: string; 
  icon: React.ReactElement | null; 
  activeIcon: React.ReactElement | null; 
}

const SideNavbar: React.FC<SideNavbarProps> = ({ isSidebarMinimized, setSidebarMinimized }) => {
  const isAdmin = useContext(UserContext)?.role === "admin";
  const isManager = useContext(UserContext)?.role === "manager";
  const user = useContext(UserContext);
  const [crewMenuOpen, setCrewMenuOpen] = useState(false);
  const [meMenuOpen, setMeMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const [companyName, setCompanyName] = useState<string | null>(null);

  useEffect(() => {
    const fetchCompanyName = async () => {
      try {
        const companyData = await getCompany({ id: user?.company_id, select: "name" });
        setCompanyName(companyData?.name || null);
      } catch (error) {
        console.error("Error fetching company name:", error);
        setCompanyName(null);
      }
    };

    fetchCompanyName();
  }, [user]);

  const toggleCrewMenu = () => setCrewMenuOpen(!crewMenuOpen);
  const toggleMeMenu = () => setMeMenuOpen(!meMenuOpen);

  const listItemButtonStyles = { "&:hover": { bgcolor: "#e4e8ee" }, borderRadius: "8px", height: "35px" };

  const NavItem: React.FC<NavItemProps> = ({ url, text, icon, activeIcon }) => {
    const isActive = url === "/" ? pathname === url : pathname.startsWith(url.split("/").slice(0, 2).join("/"));

    return (
      <Tooltip title={isSidebarMinimized ? text : ""} placement="right" arrow>
        <ListItem component={Link} to={url || ""} disablePadding>
          <ListItemButton sx={{ ...listItemButtonStyles, bgcolor: isActive ? "#e4f3ff" : "transparent", fontSize: 14 }}>
            <ListItemIcon sx={{ height: "16px", width: "16px", alignItems: "left" }}>{isActive ? activeIcon : icon}</ListItemIcon>
            <ListItemText sx={{ span: { fontSize: url === "/dashboard" ? 16 : 14, fontWeight: isActive ? 500 : 400, color: isActive ? "#2a8fe9" : "#6B7280", marginLeft: "-29px" } }}>{text}</ListItemText>
          </ListItemButton>
        </ListItem>
      </Tooltip>
    );
  };

  return (
    <Stack height="100%" width="100%" direction="column">
      <Box mt={isSidebarMinimized ? "0px" : "0px"} paddingX="28px" borderBottom="1px solid #E5E7EB" height="50px" display="flex" alignItems="flex-end">
        <Box sx={{ width: isSidebarMinimized ? "83px" : "300px", textAlign: isSidebarMinimized ? "center" : "left", marginBottom: isSidebarMinimized ? '0px' : '10px' }}>
          {companyName !== null ? (
            <Typography variant="h6" style={{ fontWeight: "bold", color: "#6B7280", fontSize: "16px", display: "flex", alignItems: "center" }}>
              <Skeleton variant="rectangular" sx={{ width: '24px', height: '24px', borderRadius: '4px', marginRight: '10px' }} />
              {companyName}
            </Typography>
          ) : (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton variant="rectangular" sx={{ width: '24px', height: '24px', borderRadius: '4px', marginRight: '10px' }} />
              <Skeleton variant="text" sx={{ flex: 1, width: '100px', height: '32px' }} />
            </Box>
          )}
        </Box>
      </Box>

      <List component="nav" sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Stack direction="column" gap={0.5} sx={{ px: "15px", pt: "5px", pb: "5px" }}>
          <NavItem url="/dashboard" text="Dashboard" icon={<HomeIcon width={16} height={16} />} activeIcon={<ActiveHomeIcon width={16} height={16} />} />
        </Stack>

        {/* Existing "Me" dropdown */}
        <ListItem disablePadding>
          <ListItemButton onClick={toggleMeMenu} sx={{ ...listItemButtonStyles, fontSize: 14, justifyContent: "flex-start", paddingLeft: "15px", paddingRight: "15px", marginLeft: "15px", marginRight: "15px" }}>
            <ListItemIcon sx={{ minWidth: "0px" }}><MeIcon height={16} /></ListItemIcon>
            <ListItemText primary="Me" sx={{ fontSize: 14, marginLeft: "14px", color: "#6B7280" }} />
            {meMenuOpen ? <ExpandLess sx={{ color: "#6B7280", fontSize: "20px" }} /> : <ExpandMore sx={{ color: "#6B7280", fontSize: "20px" }} />}
          </ListItemButton>
        </ListItem>

        {/* Content of the "Me" dropdown menu */}
        {meMenuOpen && (
          <List component="nav" sx={{ paddingLeft: "30px", paddingRight: '27px' }}>
            <NavItem url="/my-schedule" text="My Schedule" icon={null} activeIcon={null} />
            <NavItem url="/my-shifts" text="My Shifts" icon={null} activeIcon={null} />
            <NavItem url="/my-leave" text="My Leave" icon={null} activeIcon={null} />
            {/* Add more items as needed */}
          </List>
        )}

        {/* New singular button for "Schedule" */}
        {(isAdmin || isManager) && (
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/schedule" sx={{ ...listItemButtonStyles, fontSize: 14, justifyContent: "flex-start", paddingLeft: "15px", paddingRight: "15px", marginLeft: "15px", marginRight: "15px" }}>
              <ListItemIcon sx={{ minWidth: "0px" }}><ScheduleMenuIcon width={16} /></ListItemIcon>
              <ListItemText primary="Schedule" sx={{ fontSize: 14, marginLeft: "14px", color: "#6B7280" }} />
            </ListItemButton>
          </ListItem>
        )}

        {/* Existing "Crew" dropdown */}
        {(isAdmin || isManager) && (
          <ListItem disablePadding>
            <ListItemButton onClick={toggleCrewMenu} sx={{ ...listItemButtonStyles, fontSize: 14, justifyContent: "flex-start", paddingLeft: "15px", paddingRight: "15px", marginLeft: "15px", marginRight: "15px" }}>
              <ListItemIcon sx={{ minWidth: "0px" }}><UserIcon width={16} /></ListItemIcon>
              <ListItemText primary="Crew" sx={{ fontSize: 14, marginLeft: "14px", color: "#6B7280" }} />
              {crewMenuOpen ? <ExpandLess sx={{ color: "#6B7280", fontSize: "20px" }} /> : <ExpandMore sx={{ color: "#6B7280", fontSize: "20px" }} />}
            </ListItemButton>
          </ListItem>
        )}

        {/* Content of the "Crew" dropdown menu */}
        {crewMenuOpen && (
          <List component="nav" sx={{ paddingLeft: "30px", paddingRight: '27px' }}>
            <NavItem url="/shifts" text="Shifts" icon={null} activeIcon={null} />
            <NavItem url="/leave-request" text="Leave Request" icon={null} activeIcon={null} />
            <NavItem url="/users" text="Users" icon={null} activeIcon={null} />
          </List>
        )}

        {/* New menu item for "Reporting" */}
        {(isAdmin || isManager) && (
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/reporting" sx={{ ...listItemButtonStyles, fontSize: 14, justifyContent: "flex-start", paddingLeft: "15px", paddingRight: "15px", marginLeft: "15px", marginRight: "15px" }}>
              <ListItemIcon sx={{ minWidth: "0px" }}><ReportIcon width={16} /></ListItemIcon>
              <ListItemText primary="Reporting" sx={{ fontSize: 14, marginLeft: "14px", color: "#6B7280" }} />
            </ListItemButton>
          </ListItem>
        )}

        <Stack direction="column" gap={0.5} sx={{ px: "28px", pt: "5px", pb: "5px", height: "100%", flexDirection: "column", justifyContent: "flex-end" }}></Stack>
      </List>

      <Typography variant="caption" sx={{ mt: 'auto', mx: 2, color: '#6B7280', textAlign: 'center' }}>
        Powered by Shfiti
      </Typography>
    </Stack>
  );
};

export default SideNavbar;