import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Box, IconButton, InputAdornment, OutlinedInput, Stack, Typography, inputBaseClasses, outlinedInputClasses } from "@mui/material";

import { RounedArrowUpIcon, ThreeUserIcon } from "assets/svg";

//

const inputStyles = {
	[`&, .${outlinedInputClasses.notchedOutline}`]: { borderRadius: "20px" },
	[`.${inputBaseClasses.input}`]: { height: 100, fontSize: 40, fontWeight: 700, color: "#00000099", textAlign: "center", boxSizing: "border-box" },
};

//

const MemberCountStep = ({ count, setCount, planPrice }: { count: number;planPrice:number; setCount: Dispatch<SetStateAction<number>> }) => {
	const setCountOnType = (e: ChangeEvent<HTMLInputElement>) => {
		let result = Number(e.currentTarget.value);
		if (result > 100) result = 100;
		else if (result < 1) result = 1;
		setCount(result);
	};

	const incrementCount = () => (count <= 100 ? setCount((prev) => prev + 1) : null);
	const decrementCount = () => (count > 1 ? setCount((prev) => prev - 1) : null);
	//
	return (
		<Box color="#00000099">
			<Typography color="inherit">Amount of Users</Typography>

			<Stack alignItems="center" gap={2.5} mt={2.75} sx={{ "& > svg": { minWidth: { xs: 85, md: 100 } } }}>
				<ThreeUserIcon width={100} height="auto" />

				<OutlinedInput
					value={count}
					onChange={setCountOnType}
					inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
					sx={{ ...inputStyles, input: { pl: { md: 6 } } }}
					endAdornment={
						<InputAdornment position="end">
							<Stack direction="column" gap={1.25}>
								<IconButton aria-label="increment user count" onClick={incrementCount} disableRipple sx={{ p: 0 }}>
									<RounedArrowUpIcon />
								</IconButton>

								<IconButton aria-label="decrement user count" onClick={decrementCount} disableRipple sx={{ transform: "rotate(180deg)", p: 0 }}>
									<RounedArrowUpIcon />
								</IconButton>
							</Stack>
						</InputAdornment>
					}
				/>
			</Stack>

			<Typography color="inherit" mt={3.5}>
				Total User Cost
			</Typography>

			<Stack
				alignItems="center"
				justifyContent="center"
				sx={{ height: 100, fontSize: 40, fontWeight: 700, color: "#00000099", border: "1px solid #0000003b", borderRadius: "20px", mt: 1.5 }}
			>
				${count * planPrice}
			</Stack>
		</Box>
	);
};

export default MemberCountStep;
