import { useLocation } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import { Box, Drawer,  Stack } from "@mui/material";

import Header from "./Header";
import SideNavbar from "./SideNavbar";

// =======================================================>>

const MainLayout = (props: { children: ReactElement }) => {
  const { pathname } = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSidebarMinimized, setSidebarMinimized] = useState(false);

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);
  if (pathname === "/multi-authentication") {
    return <div>{props.children}</div>;
  }
  //

  return (
    <Stack direction={{ xs: "row" }} bgcolor="#F9F9F9">
      <Box
        height="100vh"
        display={{ xs: "none", md: "block" }}
        width={isSidebarMinimized ? 250 : { xs: 250, xl: 255 }}
        sx={{
          pb: 3,
          mb: '0px', // Add margin-bottom property
          mt: '0px', // Add margin-top property
          overflowY: "auto",
          transition: "width 140ms",
          boxShadow: "5px 5px 5px 5px #f5f5f5",
          "::-webkit-scrollbar": { display: "none" },
          borderRadius: "0px",
          backgroundColor: "#FFFFFF",
          marginLeft: '0px',
          borderRight:"1px solid #E5E7EB"
        }}
      >
        <SideNavbar {...{ isSidebarMinimized, setSidebarMinimized }} />
      </Box>

      <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        sx={{
          display: { xs: "block", md: "none" },

          ".MuiPaper-root": {
            bgcolor: "#EBEEF4",
            paddingBottom: "30px",
            "::-webkit-scrollbar": { display: "none" },
          },
        }}
      >
<SideNavbar isSidebarMinimized={isSidebarMinimized} setSidebarMinimized={setSidebarMinimized}/>       </Drawer>

      <Box
        id="app-scroll-wrapper"
        sx={{
          flex: 1,
          height: "100vh",
          bgcolor: "#FAFAFA",
          overflowY: "auto",
          overflowX: "hidden",
          scrollBehavior: "smooth",
        }}
        // boxShadow={{ md: "0 0 50px #3F3F4421" }}
        // borderRadius={{ md: "20px 0 0 20px" }}
      >
        {pathname !== "/news-feed" ? (
          <Header toggleSideNavbar={() => setSidebarOpen((prev) => !prev)} />
        ) : null}

        <Box component="main" px={{ xs: 2, md: "25px" }} pb={"25px"} pt={"25px"}>
          {props.children}
        </Box>
      </Box>
    </Stack>
  );
};

export default MainLayout;
