import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, Box, TextField, inputBaseClasses } from "@mui/material";
import { HiChevronDown } from "react-icons/hi";
import { red } from "@mui/material/colors";

import FieldControl from "./FieldControl";
import { TimezonesList } from "constants/timezones-list";

//

export default function TimezonesInput({ label, size }: { label: string, size?: string }) {
	const form = useFormContext();
	//
	return (
		<Controller
			name="time_zone"
			control={form.control}
			rules={{ required: "Timezone required" }}
			render={({ field, fieldState: { error } }) => (
				<FieldControl {...{ label, error }}>
					<Autocomplete
						{...field}
						id="timezone-selection"
						options={TimezonesList}
						value={field.value || null}
						getOptionLabel={(opt) => opt.label}
						onChange={(_ev: any, newVal: any) => field.onChange(newVal)}
						isOptionEqualToValue={(opt, val) => opt?.label === val?.label}
						popupIcon={<HiChevronDown fontSize={25} color="#9BA1CC" />}
						sx={{
							[`.${inputBaseClasses.root}`]: size==='large' ? { height: 60, py: 0, px: 2.5 } : { height: 50, py: 0, px: 2.5, backgroundColor: "#F6F6F6", },
							fieldset: { borderRadius: "10px", borderColor: error ? red[500] : "#EDEDED" },
						}}
						renderOption={(props, opt) => (
							<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0, borderRadius: "3px" } }} {...props}>
								{/* <img
									width="20"
									loading="lazy"
									src={`https://flagcdn.com/w20/${opt.code.toLowerCase()}.png`}
									srcSet={`https://flagcdn.com/w40/${opt.code.toLowerCase()}.png 2x`}
									alt=""
								/> */}
								{opt.label}
							</Box>
						)}
						renderInput={(params) => (
							<TextField {...params} placeholder="(GMT+10:00) Sydney" inputProps={{ ...params.inputProps, autoComplete: "new-password" }} /> // disable autocomplete and autofill
						)}
					/>
				</FieldControl>
			)}
		/>
	);
}
