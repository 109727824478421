import { styled } from "@mui/material/styles";
import { Fragment, useEffect, useMemo, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  gridClasses as gCl,
} from "@mui/x-data-grid";
import Filter from "assets/images/filter.png";
import ActiveFilter from "assets/images/active-filter.png";
import {
  Box,
  Paper,
  Stack,
  Typography,
  OutlinedInput,
  svgIconClasses,
  checkboxClasses,
  inputBaseClasses,
  iconButtonClasses,
  typographyClasses,
  OutlinedInputProps,
  inputAdornmentClasses,
  Button,
} from "@mui/material";
import { SkeletonLoading } from "components";
import { formatStr } from "utils/data-helpers";
import { ClearBlackIcon } from "assets/svg";
//
type FilterChangeParams = { name: string; value: string | number };
type TableFilter = { label?: any; code: string; value?: string | number };
type TablePropTypes = {
  leavetableTitle: string;
  bodyRows: any[];
  isLoading: boolean;
  headers: GridColDef[];
  rootMinWidth?: number;
  filtersList: (TableFilter | string)[];
  rowClickHandler?: GridEventListener<"rowClick">;
  selectionRejectHandler?: (param: number[]) => void;
  selectionApproveHandler?: (param: number[]) => void;
};
//
const StyledDataGrid = styled(DataGrid)(() => ({
  border: "none",
  overflow: "auto",
  [`.${gCl.main}`]: { minWidth: 1000 },
  [`.${gCl.columnHeaders}`]: {
    paddingLeft: 0,
    backgroundColor: "#F6F6F6",
  },
  [`.${gCl["columnSeparator--sideRight"]}`]: { display: "none" },
  [`.${gCl.columnHeader}`]: { [`.MuiCheckbox-root`]: { color: "white" } },
  [`.${gCl.columnHeaderCheckbox} > *`]: { display: "none" },
  [`.${gCl.cell}.email-cell`]: { textDecoration: "underline" },
  [`.${gCl.columnHeaderTitle}`]: { fontSize: 14, fontWeight: 600 },
  [`.${gCl.virtualScrollerRenderZone}, .${gCl.row}`]: { width: "100%" },
  [`.${checkboxClasses.root} .${svgIconClasses.root}`]: {
    width: 17,
    height: 17,
  },
  [`.${gCl.columnHeader}, .${gCl.cellCheckbox}, .${gCl.cell}`]: {
    ":focus, :focus-within": { outline: "none" },
  },
  [`.${gCl.columnHeaderCheckbox}, .${gCl.cellCheckbox}`]: {
    // minWidth: "36px !important",
    // width: "36px !important",
  },
  [`.${gCl.cell}`]: {
    "&.name-cell": { fontWeight: 600, color: "#253334", paddingLeft: 0 },
    "&:not(.name-cell)": {
      fontSize: 13.1,
      color: "#464255",
    },
  },
  [`.${gCl.iconButtonContainer}`]: {
    width: "auto",
    visibility: "visible",
    [`.${iconButtonClasses.root}`]: {
      padding: 0,
      marginLeft: 10,
      [`.${gCl.sortIcon}`]: { opacity: 1, width: 22, height: 22 },
    },
  },
}));
const FilterField = ({ label, ...props }: OutlinedInputProps) => {
  const newLabel = typeof label === "string" ? label.replace(/_/g, " ") : label;
  return (
    <Box sx={{ display: "inline-flex", alignItems: "center", mr: 4, my: 2.5 }}>
      <Box>
        <Typography
          sx={{
            textTransform: "capitalize",
            fontWeight: 500,
            marginBottom: "8px",
          }}
        >
          {newLabel}
        </Typography>
        <OutlinedInput
          {...props}
          placeholder={`Select ${newLabel}`}
          aria-describedby={`data-table-column-${label}-filter`}
          inputProps={{ "aria-label": `data-table-column-${label}-filter` }}
          sx={{
            pl: 0,
            flex: 1,
            fontSize: 14,
            borderRadius: 2,
            fieldset: { border: "1px solid #D9D9D9" },
            [`.${inputBaseClasses.input}`]: {
              minWidth: 200,
              height: 56,
              padding: "0 16px",
            },
            [`.${inputAdornmentClasses.root}`]: {
              mr: 0,
              px: 1.5,
              height: 1,
              bgcolor: "#FAFAFA",
              [`.${typographyClasses.root}`]: {
                fontSize: 14,
                color: "#595959",
                textTransform: "capitalize",
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
const ColumnFiltersBlock = (props: {
  filters: TableFilter[];
  filterChangeHandler: (params: FilterChangeParams) => void;
  searchHandler: () => void;
  setRowsListing1: () => void;
  setFilters1: () => void;
}) => {
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill,minmax(250px, 1fr))"
        gap={{ xs: 1, md: 2 }}
      >
        {props.filters.map(({ code, label, value }) => (
          <FilterField
            key={code}
            value={value}
            label={label || code}
            onChange={(e) =>
              props.filterChangeHandler({
                name: code,
                value: e.currentTarget.value,
              })
            }
          />
        ))}
      </Box>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 1,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "#202422",
            fontSize: "14px",
            borderColor: "#202422",
            fontWeight: 400,
            borderRadius: "40px",
            outline: "none",
            height: "40px",
          }}
          onClick={() => {
            props.setRowsListing1();
            props.setFilters1();
          }}
        >
          <ClearBlackIcon style={{ marginRight: "6px" }} />
          Clear
        </Button>
        <Button
          variant="contained"
          sx={{
            marginLeft: "12px",
            color: "#ffffff",
            fontSize: "14px",
            fontWeight: 400,
            backgorundColor: "#007AE5",
            borderRadius: "40px",
            outline: "none",
            height: "40px",
          }}
          onClick={props.searchHandler}
        >
          Search
        </Button>
      </Box>
    </>
  );
};
//
export const MyLeaveRequestTable = ({
  leavetableTitle,
  filtersList,
  headers,
  bodyRows,
  rowClickHandler,
  isLoading,
  selectionApproveHandler,
  selectionRejectHandler,
}: TablePropTypes) => {
  const [showDiv, setShowDiv] = useState(false);
  const skeletonProps = useMemo(() => {
    return {
      height: 40,
      width: 245,
    };
  }, []);
  const refinedFilters = useMemo(() => {
    return filtersList.map((el) =>
      typeof el === "string" ? { code: el, value: "" } : { ...el, value: "" }
    );
  }, [filtersList]);
  const [rowsListing, setRowsListing] = useState<any[]>([]);
  const [filters, setFilters] = useState<TableFilter[]>(refinedFilters);
  useEffect(() => {
    if (bodyRows.length > 0) setRowsListing(bodyRows);
  }, [bodyRows, bodyRows.length]);
  const filterChangeHandler = (params: FilterChangeParams) => {
    setFilters((prev) => {
      const update = [...prev];
      const matchIndex = update.findIndex((el) => el.code === params.name);
      if (matchIndex !== -1)
        update[matchIndex] = { ...update[matchIndex], value: params.value };
      return update;
    });
  };
  const setRowsListing1 = () => {
    setRowsListing(bodyRows);
  };
  const setFilters1 = () => {
    setFilters(refinedFilters);
  };
  const searchHandler = () => {
    const noFilterAdded = filters.every(({ value }) => !value);
    if (noFilterAdded) setRowsListing(bodyRows);
    else if (bodyRows.length > 0) {
      setRowsListing(() => {
        return bodyRows.filter((row) => {
          return Object.entries(row).some(([key, value]) => {
            return filters.some(
              (filter) =>
                filter.code === key &&
                filter.value &&
                formatStr(value).includes(formatStr(filter.value))
            );
          });
        });
      });
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Typography variant="h2" fontSize={24} fontWeight={700} mb={0}>
          {leavetableTitle}
        </Typography>
        <Box
          onClick={() => setShowDiv(!showDiv)}
          sx={{
            cursor: "pointer",
            width: 48,
            height: 48,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mx: 1,
          }}
        >
          {showDiv ? (
            <img src={ActiveFilter} alt="filterIcon" />
          ) : (
            <img src={Filter} alt="filterIcon" />
          )}
        </Box>
      </Box>
      {showDiv && (
        <Box component="section" mb={4}>
          <Box
            elevation={0}
            component={Paper}
            sx={{
              position: "relative",
              borderRadius: "20px",
              overflow: "hidden",
              py: 2,
              px: 2,
            }}
          >
            <ColumnFiltersBlock
              {...{
                filters,
                filterChangeHandler,
                searchHandler,
                setRowsListing1,
                setFilters1,
              }}
            />
          </Box>
        </Box>
      )}
      <Box
        elevation={0}
        component={Paper}
        sx={{ position: "relative", borderRadius: "12px", overflow: "hidden" }}
      >
        {/* <Box>
          <Stack
            flexWrap="wrap"
            className="top-header-block"
            justifyContent="space-between"
            sx={{
              rowGap: 1.5,
              columnGap: 1,
              mb: 2.5,
              [`.${buttonClasses.root}`]: {
                height: 32,
                borderRadius: "10px",
                lineHeight: 0.8,
              },
            }}
          > */}
        {/* <Stack
              flexWrap="wrap"
              alignItems="center"
              columnGap={2}
              rowGap={0.5}
            > */}
        {/* <Typography fontSize={17} fontWeight={700}>
                Search And Filter
              </Typography> */}
        {/* {selectedRowIdList.length > 1 &&
              selectionApproveHandler &&
              selectionRejectHandler ? (
                <Stack gap={1}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      selectionApproveHandler(selectedRowIdList as number[])
                    }
                    sx={{
                      bgcolor: "#19A46A",
                      ":hover": { bgcolor: "#127c50" },
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                    startIcon={
                      <Box sx={{ svg: { width: 15, height: "auto" } }}>
                        <TickIcon />
                      </Box>
                    }
                  >
                    Approve Selected
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      selectionRejectHandler(selectedRowIdList as number[])
                    }
                    sx={{
                      bgcolor: "#FF5454",
                      ":hover": { bgcolor: "#d84646" },
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                    startIcon={
                      <Box sx={{ svg: { width: 15, height: "auto" } }}>
                        <CrossIcon />
                      </Box>
                    }
                  >
                    Reject Selected
                  </Button>
                </Stack>
              ) : null} */}
        {/* </Stack> */}
        {/* <Stack gap={1}>
              <Button
                color="inherit"
                variant="outlined"
                sx={{
                  color: "#595959",
                  borderColor: "#595959",
                  fontWeight: 400,
                }}
                onClick={() => {
                  setRowsListing(bodyRows);
                  setFilters(refinedFilters);
                }}
              >
                Clean
              </Button>
              <Button
                variant="contained"
                sx={{ fontWeight: 400 }}
                onClick={searchHandler}
              >
                Search
              </Button>
            </Stack> */}
        {/* </Stack> */}
        {/* <ColumnFiltersBlock {...{ filters, filterChangeHandler }} /> */}
        {/* </Box> */}
        <StyledDataGrid
          autoHeight
          columns={headers}
          rows={rowsListing}
          columnHeaderHeight={64}
          rowCount={rowsListing?.length}
          initialState={{
            pagination: { paginationModel: { pageSize: 20, page: 0 } },
          }}
          // onRowClick={rowClickHandler}
          // checkboxSelection
          // rowSelectionModel={selectedRowIdList}
          // onRowSelectionModelChange={setSelectedRowIdList}
          hideFooter={rowsListing?.length <= 10}
          disableRowSelectionOnClick
          paginationMode="client"
          pageSizeOptions={[]}
          disableColumnMenu
          rowHeight={47.5}
          pagination
          slots={{
            columnSortedDescendingIcon: HiChevronUp,
            columnSortedAscendingIcon: HiChevronDown,
            noRowsOverlay: () =>
              isLoading || !rowsListing ? (
                <Stack
                  alignItems="center"
                  justifyContent="between"
                  gap={3}
                  ml={2}
                >
                  {["From", "To", "Type", "Comment", "Earnings", "Status"].map(
                    (item, index1) => {
                      return (
                        <Box key={index1} textAlign={"center"}>
                          <SkeletonLoading {...skeletonProps} count={10} />
                        </Box>
                      );
                    }
                  )}
                </Stack>
              ) : (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nothing Found Here
                </Stack>
              ),
          }}
        />
      </Box>
    </Fragment>
  );
};
