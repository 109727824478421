import { Stack } from "@mui/material";

import { AuthInput, CountriesInput, EmailInput, PhoneInput } from "components";
import { CompanyBuildingIcon } from "assets/svg";

//

const CompanyDetailStep = () => (
	<Stack direction="column" gap={0.875}>
		<AuthInput
			name="name"
			label="Company Name"
			required="Name Required"
			placeholder="Shifti Technologies PTY. LTD."
			endIcon={<CompanyBuildingIcon width={17} height={17} fill="#9BA1CC" />}
		/>

		<CountriesInput label="Company Location" />

		<EmailInput label="Company Email" />

		<PhoneInput label="Company Phone" />

		<AuthInput
			name="tax_number"
			label="Tax Number, ABN for Australia"
			required="Tax Number, ABN Required"
			placeholder="Tax Number"
			endIcon={<CompanyBuildingIcon width={17} height={17} fill="#9BA1CC" />}
		/>

		<AuthInput
			name="acn"
			label="ACN Optional"
			placeholder="ACN"
			endIcon={<CompanyBuildingIcon width={17} height={17} fill="#9BA1CC" />}
		/>
	</Stack>
);

export default CompanyDetailStep;
