import { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CopyToClipboard } from "assets/svg";

//

const CopyTextIcon = (props: { code: string }) => {
	const [isCopied, setCopied] = useState(false);

	const copyHandler = () => {
		navigator.clipboard.writeText(props.code);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 4000);
	};

	return (
		<Tooltip title={isCopied ? "Copied" : "Copy"} onClick={copyHandler} arrow>
			<IconButton size="small">
				<CopyToClipboard />
			</IconButton>
		</Tooltip>
	);
};

export default CopyTextIcon;
