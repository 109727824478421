import { useState } from "react";
import { Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";

import { SearchIcon } from "assets/svg";

const dummyList = [{ title: "How To Update Employee Pay Rate" }, { title: "Change Company Logo" }];

export const HeaderSearch = (props: { noShadow?: boolean }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const results = dummyList?.filter((i) => searchTerm?.trim() && i?.title?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase()));
    const isShowingList = results?.length > 0;

    return (
        <Box position="relative">
            <TextField
                fullWidth
                value={searchTerm}
                placeholder="Search here"
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                            <SearchIcon style={{ width: 12, height: 12 }} /> {/* Adjust size here */}
                        </InputAdornment>
                    ),
                }}
                sx={{
                    ".MuiInputBase-root": {
                        bgcolor: "white",
                        caretColor: "blue",
                        borderRadius: isShowingList ? "10px 10px 0 0" : { xs: "5px", sm: "10px" },
                        padding: "10px", // Padding for the input field
                        height: "36px", // Height of the input field
                        width: "355px", // Width of the input field
                        boxShadow: props.noShadow ? "none" : "0 20px 60px -12px rgb(0 0 0 / 0.08)",
                        border: "1px solid #E5E7EB", // Change border color
                    },
                    input: {
                        padding: "0px", // Adjusting inner padding to 0
                        fontSize: "13px", // Change text size
                    },
                    fieldset: { border: "none" },
                }}
            />

            {isShowingList && (
                <Box
                    position="absolute"
                    top="100%"
                    left={0}
                    right={0}
                    bgcolor="white"
                    boxShadow="0 4px 4px 0 #00000010"
                    borderRadius="0 0 5px 5px"
                    zIndex={50}
					width={"355px"}
					border={"1px solid #E5E7EB"}

                >
                    <Stack direction="column" gap={2} mx={3.25} py={1}>
                        {results?.map((item, i) => (
                            <Stack key={i} alignItems="center" gap={1} sx={{ cursor: "pointer" }}>
                                <Box sx={{ svg: { width: 14, height: 14 } }}>
                                    <SearchIcon style={{ width: 12, height: 12 }} /> {/* Adjust size here */}
                                </Box>
                                <Typography color="app.greyblue" sx={{ fontSize: "13px" }}>{item?.title}</Typography> {/* Set font size here */}
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            )}
        </Box>
    );
};

export default HeaderSearch;
