import { FormProvider, UseFormReturn } from "react-hook-form";
import { Button, Stack, Typography, Box, Grid } from "@mui/material";
import { SessionUser } from "types";
import { SettingInput } from "components";
import { TimezoneIcon } from "assets/svg";
type PropTypes = {
  form: UseFormReturn<SessionUser>;
  companyID: string;
  isAdmin: boolean;
  mutateChangePassword: Function;
};
const Password = ({ form, mutateChangePassword }: PropTypes) => {
  const changePasswordHandler = (vals: any) => {
    mutateChangePassword(vals);
  };
  return (
    <FormProvider {...form}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Password
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            Change your password
          </Typography>
        </Box>
        <Stack
          component="form"
          onSubmit={form.handleSubmit(changePasswordHandler)}
          gap={2}
        >
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Box>
                <Box
                  sx={{
                    alignItems: "center",
                    border: "1px solid #D1D5EB",
                    borderRadius: "10px",
                    paddingX: 3,
                    paddingY: 3,
                  }}
                >
                  <TimezoneIcon />
                  <Typography
                    variant="h3"
                    fontSize={22}
                    fontWeight={700}
                    lineHeight="24.5px"
                    my={2.25}
                  >
                    Change Password
                  </Typography>
                  <Typography>
                    To change your password, please fill in the fields below.
                    Your password must contain at least 8 characters, it must
                    also include at least one upper case letter. one lower case
                    letter, one number and one special character.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Stack
                direction="column"
                flex={0.6}
                justifyContent="space-between"
                rowGap={4}
              >
                <Stack direction="column" rowGap={2}>
                  <SettingInput
                    textFieldProps={{ type: "password" }}
                    name="password"
                    label="Current Password"
                    required="Current Password"
                  />
                  <SettingInput
                    textFieldProps={{ type: "password" }}
                    name="new_password"
                    label="New Password"
                    required="New Password"
                  />
                  <SettingInput
                    textFieldProps={{ type: "password" }}
                    name="confirm_password"
                    label="Confirm Password"
                    required="Confirm Password"
                  />
                </Stack>
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    // fullWidth
                    type="submit"
                    variant="contained"
                    color={
                      Object.keys(form.formState.errors)?.length
                        ? "error"
                        : "primary"
                    }
                    sx={{
                      height: 50,
                      fontWeight: 600,
                      fontSize: { xs: 18, sm: 20 },
                      px: 5,
                    }}
                  >
                    Change Password
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </FormProvider>
  );
};
export default Password;
