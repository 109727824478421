import React from "react";
import Skeleton from "react-loading-skeleton";
type PropTypes = {
  height?: number;
  width?: number;
  count?: number;
};
function SkeletonLoading({ height, width, count }: PropTypes) {
  return (
    <div>
      <Skeleton height={height} width={width} count={count} style={{ marginBottom: "5px" }} />
    </div>
  );
}
export default SkeletonLoading;
