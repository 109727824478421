// const useWeekRanges = (): Date[] => {
//   const today = new Date();
//   today.setHours(0, 0, 0, 0); // set clock time to midnight 12 am
//   const atIndexZero = today.getDate() - today.getDay();
//   const atIndexSix = atIndexZero + 6;
//   const sunday = new Date(today.setDate(atIndexZero));
//   const saturday = new Date(today.setDate(atIndexSix));
//   today.setDate(today.getDate() + ((0 - 1 - today.getDay() + 7) % 7) + 1);
//   const nextSundayy = today.toString();
//   today.setDate(today.getDate() + ((0 - 1 - today.getDay() + 6) % 7) + 1);
//   const nextSaturdayy = today.toString();
//   const nextSunday = new Date(nextSundayy);
//   const nextSaturday = new Date(nextSaturdayy);
//   return [sunday, saturday, nextSunday, nextSaturday];
// };
const useWeekRanges = (): Date[] => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // set clock time to midnight 12 am

  // Current week's Sunday
  const atIndexZero = today.getDate() - today.getDay();
  const sunday = new Date(today.getFullYear(), today.getMonth(), atIndexZero);

  // Current week's Saturday
  const atIndexSix = atIndexZero + 6;
  const saturday = new Date(today.getFullYear(), today.getMonth(), atIndexSix);

  // Next week's Sunday
  today.setDate(today.getDate() + ((0 - 1 - today.getDay() + 7) % 7) + 1);
  const nextSunday = new Date(today);

  // Next week's Saturday
  today.setDate(today.getDate() + 6);
  const nextSaturday = new Date(today);

  // Previous week's Sunday
  const prevSunday = new Date(sunday);
  prevSunday.setDate(sunday.getDate() - 7);

  // Previous week's Saturday
  const prevSaturday = new Date(saturday);
  prevSaturday.setDate(saturday.getDate() - 7);

  return [prevSunday, prevSaturday, sunday, saturday, nextSunday, nextSaturday];
};

export default useWeekRanges;
