import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Break, Shift } from "types"; // Ensure this import is correct
import dayjs from "dayjs";
type PropsTypes = {
  completed: number;
  breaks?: Break[] | undefined;
  item?: Shift;
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => {
  const defaultColor =
    theme.palette.grey[theme.palette.mode === "light" ? 200 : 800];
  return {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: defaultColor,
    },
  };
});
const LineProgressBar = ({ completed, breaks, item }: PropsTypes) => {
  const startingTime = item?.actual_start_time && item?.actual_start_time.replace('T', ' ');
  const endingTime = item?.actual_end_time && item?.actual_end_time.replace('T', ' ');

  const starting_time = dayjs(startingTime ? startingTime : item?.target_date + " " + item?.starting_time);
  const ending_time = dayjs(endingTime ? endingTime : item?.target_date + " " + item?.ending_time);
  const totalTime = ending_time.diff(starting_time, "minute");
  // Calculate the percentage of the total time elapsed
  const renderBreaks = () => {
    const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
    return breaks?.map((breakItem, index) => {
      const i_s_t = dayjs(startingTime ? startingTime : item?.target_date + " " + item?.starting_time);
      const b_s_t = dayjs(item?.target_date + " " + breakItem?.start_date_time);
      const b_e_t = breakItem?.end_date_time
        ? dayjs(item?.target_date + " " + breakItem?.end_date_time)
        : dayjs(now);
      const end_break = b_e_t.diff(i_s_t, "minute");
      const start_break = b_s_t.diff(i_s_t, "minute");
      const break_start = start_break;
      const break_end = end_break;
      const breakStartPercent = (break_start / totalTime) * 100;
      const breakEndPercent = (break_end / totalTime) * 100;
      const breakWidth = breakEndPercent - breakStartPercent;
      return (
        <Box
          key={index}
          sx={{
            position: "absolute",
            left: `${breakStartPercent}%`,
            width: `${breakWidth}%`,
            height: "100%",
            backgroundColor: "#84CC16",
            zIndex: 1,
            top: 0,
            borderRadius: 0.5,
          }}
        />
      );
    });
  };
  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <BorderLinearProgress variant="determinate" value={completed} />
      {renderBreaks()}
    </Box>
  );
};
export default LineProgressBar;
