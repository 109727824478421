import { PremiumPlanType } from "types";

export const subscriptionPlanPrices = { small: 3, medium: 4, large: 5.5, ultimate: 15 };
export const subscriptionMemberLimits = { small: 9, medium: 25, large: 60, ultimate: 100 };

export const featuresList = [
	"Rostering",
	"Availability Management",
	"Leave Management",
	"API Access",
	"Transparent Pay",
	"Biometric Clocking",
	"Schedule Template",
	"Tasking & Kanban",
	"24/7 Support",
	"Break Planing",
	"Shift Swapping",
	"Push Notification",
];

export const planFeatures: { [key in PremiumPlanType]: string[] } = {
	small: ["Rostering", "Leave Management", "API Access"],
	medium: ["Rostering", "Availability Management", "Leave Management", "Transparent Pay", "Biometric Clocking"],
	large: [
		"Rostering",
		"Availability Management",
		"Leave Management",
		"Transparent Pay",
		"Biometric Clocking",
		"Schedule Template",
		"Tasking & Kanban",
		"24/7 Support",
		"Shift Swapping",
	],
	ultimate: [], // all
};
