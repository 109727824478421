import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Session } from "@supabase/supabase-js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SessionUser } from "types";
import { getUserProfile } from "api/profiles";
import supabase from "supabase/supabaseClient";
import { getEmployeeInfo } from "api/employee";
import { signOutHandler } from "api/auth";
import { addAccountToList } from "utils/data-helpers";
//
const UserContext = createContext<SessionUser>({});
const UserContextProvider = (props: { children: ReactNode }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [session, setSession] = useState<Session | null>(null);
  const [isSessionLoading, setSessionLoading] = useState<boolean>(true);
  const atAuthPages = pathname.includes("/auth/");
  // fetch and save supabase session..
  const { mutate: mutateSignOut } = useMutation(signOutHandler);
  useEffect(() => {
    const check_login_events = supabase.channel("check_login_events");
    check_login_events.on(
      "postgres_changes",
      { event: "DELETE", schema: "public", table: "login_events" },
      (payload: any) => {
        (async () => {
          let randomID = localStorage.getItem("randomID");
          if (randomID) {
            const { data: session_check_Data } = await supabase
              .from("login_events")
              .select("*")
              .eq("session_check", randomID)
              .limit(1);
            if (session_check_Data && session_check_Data.length > 0) {
            } else {
              mutateSignOut();
            }
          }
        })();
      }
    );
    check_login_events.on("presence", { event: "sync" }, () => {});
    check_login_events.subscribe(async (status) => {});
    return () => {
      check_login_events.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sessionHandler = async () => {
      const { data: session_Data } = await supabase.auth.getSession();
      let randomID = localStorage.getItem("randomID");
      if (randomID) {
        const { data: session_check_Data } = await supabase
          .from("login_events")
          .select("*")
          .eq("session_check", randomID)
          .limit(1);
        if (
          session_check_Data &&
          session_check_Data.length > 0 &&
          session_Data.session
        ) {
          setSession(session_Data.session);
        } else {
          mutateSignOut();
        }
      }
      // await supabase.auth.getSession().then((res) => console.log("session", res));
      supabase.auth.onAuthStateChange((ev, session) => {
        setSession(session);
        if (ev === "SIGNED_IN") {
          const { user, access_token, refresh_token } = session || {};
          if (user?.id && access_token)
            addAccountToList(user.id, access_token, refresh_token);
        }
      });
      if (isSessionLoading) setSessionLoading(false);
    };
    sessionHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // auth gaurd..
  useEffect(() => {
    if (!isSessionLoading) {
      if (session && atAuthPages) history.push("/dashboard");
      if (!session && !atAuthPages) history.push("/auth/sign-in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, session, isSessionLoading]);
  // fetch user profile info...
  const user = session?.user;
  const profileQuery = useQuery(
    ["user-context-profile", user?.email],
    () => getUserProfile(user?.email || ""),
    {
      enabled: Boolean(user?.email && !atAuthPages),
    }
  );
  const employeeQuery = useQuery({
    queryKey: ["user-context-employee", user?.id],
    queryFn: () => getEmployeeInfo(user?.id || ""),
    enabled: Boolean(user?.id && !atAuthPages),
    retry: false,
  });
  const areQueriesLoading =
    profileQuery.isInitialLoading || employeeQuery.isInitialLoading;
  // jsx..
  return isSessionLoading || areQueriesLoading ? (
    <></>
    // <Loader iconSize={65} verticalPadding="40vh" />
  ) : (
    <UserContext.Provider
      value={{ ...profileQuery.data, ...employeeQuery.data, session }}
    >
      {props.children}
      <ToastContainer
        autoClose={4000}
        position="top-center"
        pauseOnFocusLoss
        pauseOnHover
      />
    </UserContext.Provider>
  );
};
export { UserContext, UserContextProvider };
