import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import App from "App";
import MuiThemeProvider from "theme/MuiThemeProvider";
import "styles/global.scss";

// handsontable
import 'handsontable/dist/handsontable.full.min.css';

//

if (process.env.NODE_ENV === "production") disableReactDevTools();

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    {/* <StrictMode> */}
    <BrowserRouter>
      <MuiThemeProvider>
        <App />
      </MuiThemeProvider>
    </BrowserRouter>
    {/* </StrictMode> */}
  </>
);
