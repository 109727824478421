import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiTwotoneSave } from "react-icons/ai";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  buttonClasses,
  inputBaseClasses,
  Box,
  Grid,
} from "@mui/material";
import { Company } from "types";
import { useDbImageHandler } from "hooks";
import {
  getCompany,
  getCompanyByInvite,
  getNewCompanyInviteCode,
  updateCompany,
} from "api/companies";
import {
  AvatarBlock,
  CopyTextIcon,
  Loader,
  SettingInput,
  LoadingOverlay,
} from "components";
import Timezone from "./company/Timezone";
import { EditIcon, ResetClockIcon } from "assets/svg";
import { getUpdateValues } from "utils/api-helpers";
import PaymentRatesEmployee from "./company/PaymentRatesEmployee";
//
type PropTypes = {
  form: UseFormReturn<Company>;
  companyID: string;
  isAdmin: boolean;
};
const CompanySettings = ({ form, companyID, isAdmin }: PropTypes) => {
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [deleteAvatar, setDeleteAvatar] = useState<boolean>(false);
  const [canEditInviteCode, setEditInviteCode] = useState<boolean>(false);
  const { dbImgHandler, deleteDbImg, areImageOperationsLoading } =
    useDbImageHandler("avatars", `${form.getValues("name")} logo`);
  /**  api calls.. **/
  const {
    refetch: refetchCompany,
    data: initialCompanyDetails,
    isInitialLoading: isCompanyLoading,
  } = useQuery(
    ["company-setting-details", companyID],
    () => getCompany({ id: companyID }),
    {
      enabled: Boolean(isAdmin && companyID),
    }
  );
  // form values sync with DB company record..
  useEffect(() => {
    if (initialCompanyDetails?.name) form.reset(initialCompanyDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCompanyDetails]);
  const { mutate: saveSettings, isLoading: isSavingSettings } = useMutation({
    mutationKey: ["company-settings-update"],
    mutationFn: (vals: Company) =>
      updateCompany({
        id: companyID,
        props: getUpdateValues(initialCompanyDetails, vals),
      }),
    onSuccess: () => refetchCompany(),
  });
  const { mutate: mutateValidateInvite, isLoading: isValidatingInvite } =
    useMutation(() => getCompanyByInvite(form.getValues("invite_code")), {
      onError: () => toast.success("You can save this code."), // success on 0 company returned..
      onSuccess: () => {
        toast.error("This code is currently in use.");
        form.setValue("invite_code", initialCompanyDetails?.invite_code);
      },
    });
  const { mutate: mutateNewInviteCode, isLoading: isGeneratingInviteCode } =
    useMutation(getNewCompanyInviteCode, {
      onSuccess: (code) => {
        form.setValue("invite_code", code);
        toast.success("New employee invite code generated.");
      },
    });
  // utilities..
  const onLogoRemoveClick = () => {
    if (deleteAvatar) setDeleteAvatar(true);
    if (logoFile) setLogoFile(null);
  };
  const submitHandler = (vals: Company) => {
    const logoPath = vals?.logo_url; // existing logo..
    if (logoFile) {
      dbImgHandler(logoFile, logoPath, (res: any) =>
        saveSettings({ ...vals, ...(!logoPath && { logo_url: res }) })
      );
    } else if (deleteAvatar && logoPath) {
      deleteDbImg([logoPath], {
        onSuccess: () => {
          saveSettings({ ...vals, logo_url: null });
          setDeleteAvatar(false);
        },
      });
    } else saveSettings(vals);
  };
  // jsx..
  return isCompanyLoading ? (
    <Loader />
  ) : (
    <>
    <FormProvider {...form}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Company Profile
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            Manage your company setting
          </Typography>
        </Box>
        <Stack
          component="form"
          onSubmit={form.handleSubmit(submitHandler)}
          gap={2}
        >
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Box>
                <AvatarBlock
                  setFile={setLogoFile}
                  removeHandler={onLogoRemoveClick}
                  isLoading={areImageOperationsLoading}
                  avatarSx={{ width: 130, height: 130, mb: 2.25 }}
                  file={
                    deleteAvatar ? null : logoFile || form.getValues("logo_url")
                  }
                  avatarProps={{
                    name: form.getValues("name"),
                    hasLocalFile: !!logoFile,
                  }}
                  sx={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    border: "1px solid #D1D5EB",
                    borderRadius: "10px",
                    paddingX: 3,
                    paddingY: 3,
                    mb: 2.25,
                  }}
                />
                <Box
                  sx={{
                    // flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #D1D5EB",
                    borderRadius: "10px",
                    paddingX: 3,
                    paddingY: 3,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontSize={20}
                    fontWeight={700}
                    lineHeight="24.5px"
                    mb={2.25}
                  >
                    Company Code
                  </Typography>
                  <SettingInput
                    name="invite_code"
                    adornmentSx={{ mr: 0 }}
                    icon={<CopyTextIcon code={form.watch("invite_code")} />}
                    textFieldProps={{
                      disabled: !canEditInviteCode,
                      sx: {
                        [`.${inputBaseClasses.root}`]: { pr: 1 },
                        fieldset: {
                          borderRadius: "10px",
                          borderWidth: canEditInviteCode
                            ? "3px !important"
                            : "1px",
                          borderColor: canEditInviteCode
                            ? "primary.main"
                            : "#D1D5EB",
                        },
                        "&": {
                          mt: 0.625,
                          width: "100%",
                          bgcolor: "white",
                          borderRadius: "10px",
                        },
                        input: {
                          WebkitTextFillColor: "#000000BF",
                          fontSize: 14,
                          fontWeight: 600,
                          textAlign: "center",
                          p: 1.7,
                        },
                      },
                    }}
                  />
                  <Stack gap={1.25} mt={2}>
                    <Button
                      variant="contained"
                      endIcon={
                        isValidatingInvite ? null : canEditInviteCode ? (
                          <AiTwotoneSave />
                        ) : (
                          <EditIcon />
                        )
                      }
                      sx={{
                        "&": {
                          width: "100%",
                          height: 50,
                          fontSize: 17,
                          fontWeight: 700,
                          borderRadius: "5px",
                        },
                        [`.${buttonClasses.endIcon} svg`]: {
                          width: 12,
                          height: 12,
                        },
                      }}
                      onClick={() => {
                        if (!isValidatingInvite) {
                          if (canEditInviteCode) mutateValidateInvite(); // save action..
                          setEditInviteCode((prev) => !prev);
                        }
                      }}
                    >
                      {isValidatingInvite ? (
                        <CircularProgress
                          size={18}
                          thickness={5}
                          sx={{ color: "white" }}
                        />
                      ) : canEditInviteCode ? (
                        "Save"
                      ) : (
                        "Edit"
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => mutateNewInviteCode()}
                      endIcon={
                        isGeneratingInviteCode ? null : <ResetClockIcon />
                      }
                      sx={{
                        width: "100%",
                        height: 50,
                        fontSize: 17,
                        fontWeight: 700,
                        borderRadius: "5px",
                      }}
                    >
                      {isGeneratingInviteCode ? (
                        <CircularProgress
                          size={18}
                          thickness={5}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        "Reset"
                      )}
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Stack
                minHeight="calc(100vh - 350px)"
                direction="column"
                flex={0.6}
                justifyContent="space-between"
                rowGap={4}
              >
                <Stack
                  direction="column"
                  rowGap={2}
                  onClick={() => refetchCompany()}
                >
                  <SettingInput
                    name="name"
                    label="Company Name"
                    required="Name Required"
                    typeCompany
                  />
                  <SettingInput
                    name="location"
                    label="Company Location"
                    required="Location Required"
                    typeCompany
                  />
                  <SettingInput
                    name="email"
                    label="Company Email"
                    required="Email Required"
                    textFieldProps={{ type: "email" }}
                    typeCompany
                  />
                  <SettingInput
                    name="phone"
                    label="Company Phone"
                    required="Phone Required"
                    textFieldProps={{ type: "tel" }}
                    typeCompany
                  />
                  <SettingInput
                    name="tax_number"
                    label="Tax Number, ABN for Australia"
                    required="Tax Number, ABN Required"
                    typeCompany
                  />
                  <SettingInput name="acn" label="ACN Optional" typeCompany />
                </Stack>
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    // fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isValidatingInvite || isGeneratingInviteCode}
                    color={
                      Object.keys(form.formState.errors)?.length
                        ? "error"
                        : "primary"
                    }
                    sx={{
                      height: 50,
                      fontWeight: 600,
                      fontSize: { xs: 18, sm: 20 },
                      px: 5,
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
			
      {/* <LoadingOverlay open={isSavingSettings || areImageOperationsLoading} /> */}
    </FormProvider>
    <Timezone form={form} companyID="" isAdmin />
    <PaymentRatesEmployee form={form} companyID="" isAdmin />
    </>
  );
};
export default CompanySettings;
