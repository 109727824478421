import { useQuery } from "@tanstack/react-query";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Button, Typography, Box, Grid } from "@mui/material";
import { Company, SessionUser } from "types";
import { getLoginDevices } from "api/auth";
import DevicesInfo from "./DevicesInfo";
//
type PropTypes = {
  form: UseFormReturn<Company>;
  user: SessionUser;
  isAdmin: boolean;
  deviceLogout: Function;
};
const YourDevices = ({ form, user, isAdmin, deviceLogout }: PropTypes) => {
  const { data } = useQuery(["login-devices", user.id], () =>
    getLoginDevices(user.id)
  );
  const deviceLogoutHandler = () => {
    deviceLogout();
  };
  return (
    <FormProvider {...form}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Your devices
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            today at 18:34, Chrome 198.123.33.33
          </Typography>
        </Box>
        <Box component="form">
          <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
            Total Active Devices
          </Typography>
          <Grid container spacing={2}>
            {data?.map((device: any, i: number) => (
              <DevicesInfo i={i} device={device} key={device.id} />
            ))}
          </Grid>
          <Box
            sx={{
              my: 3,
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
              Log Out all Devices
            </Typography>
            <Typography
              variant="h3"
              fontSize={14}
              fontWeight={400}
              lineHeight="26px"
            >
              today at 18:34, Chrome 198.123.33.33
            </Typography>
          </Box>
          <Button
            // fullWidth
            type="button"
            variant="contained"
            color={"primary"}
            sx={{
              height: 50,
              fontWeight: 600,
              fontSize: { xs: 18, sm: 20 },
              px: 10,
            }}
            onClick={deviceLogoutHandler}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
export default YourDevices;
