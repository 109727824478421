import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  FormControl,
  Stack,
} from "@mui/material";
import { FormInput } from "components";
import { inviteByEmail } from "api/auth";
import { getUserProfile } from "api/profiles";
// =======================================================>>
type PropTypes = {
  // open: boolean;
  companyID: string;
  // onClose: VoidFunction;
};
const InviteUserModal = ({ companyID, ...modalProps }: PropTypes) => {
  const form = useForm<any>({ defaultValues: { email: "" } });
  // useEffect(() => {
  //   if (!modalProps.open) form.reset();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modalProps.open]);
  const { mutate: mutateInvite, isLoading: isInviteLoading } = useMutation({
    mutationFn: (email: string) => inviteByEmail(email, companyID),
    onSuccess: () => {
      toast.success("Successfully sent the invitation.");
      // modalProps.onClose();
    },
    onError: (err: any) => {
      toast.error(err?.error_description || err?.message);
      form.setError("email", {
        message: err?.error_description || err?.message,
      });
    },
  });
  const { mutate: checkExistingUser, isLoading: isCheckLoading } = useMutation({
    mutationFn: getUserProfile,
    onSuccess: (data) => {
      if (data?.id) {
        form.setError("email", {
          message: "A user with this email address has already been registered",
        });
      } else {
        mutateInvite(form.watch("email"));
      }
    },
    onError: () => {
      form.setError("email", { message: "Faced issue. Try again." });
    },
  });
  //
  return (
    <Box
      sx={{
        width: 240,
        ".MuiPaper-root": { maxWidth: 380, borderRadius: "10px" },
      }}
    >
      <Stack>
        <DialogTitle
          sx={{
            fontSize: 13,
            fontWeight: 600,
            color: "#202422",
            marginBottom: 2,
          }}
        >
          Invite User
        </DialogTitle>
      </Stack>
      <FormProvider {...form}>
        <Box
          role="form"
          component="form"
          onSubmit={form.handleSubmit((vals) => checkExistingUser(vals?.email))}
          sx={{
            px: 3,
            pb: 4,
            input: { p: "11px" },
            fieldset: { borderRadius: "10px" },
            ".MuiFormLabel-root": { top: "-5px" },
          }}
        >
 <FormControl style={{ width: '193px' }}>
  <FormInput
    name="email"
    required="Email required"
    placeholder="Enter user email"
  />
</FormControl>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              height: 36,
              width:'193px',

              fontSize: 14,
              fontWeight: 600,
              borderRadius: "50px",
              py: "5px",
              mt: 2,
            }}
          >
            {isInviteLoading || isCheckLoading ? (
              <CircularProgress size={32} sx={{ color: "white" }} />
            ) : (
              "Invite"
            )}
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
};
export default InviteUserModal;
