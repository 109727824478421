import { Button, Dialog, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";

// =======================================================>>

type PropTypes = { open: boolean; onClose: VoidFunction; unassignHandler: VoidFunction };

const UnassignModal = ({ unassignHandler, ...modalProps }: PropTypes) => (
	<Dialog {...modalProps} fullWidth sx={{ ".MuiPaper-root": { maxWidth: 425, borderRadius: "10px" } }}>
		<IconButton onClick={modalProps.onClose} sx={{ position: "absolute", right: 10, top: 10, fontSize: 18, p: 0.25 }}>
			<MdOutlineClose />
		</IconButton>

		<Stack direction="column" alignItems="center" px={2} pb={3}>
			<DialogTitle sx={{ fontSize: 22, fontWeight: 700, lineHeight: "32px", textAlign: "center" }}>Confirm Employee Unassignment</DialogTitle>

			<Typography maxWidth={306} fontSize={{ sm: 18 }} fontWeight={600} textAlign="center">
				Are you sure about removing this Employee from your company.
			</Typography>

			<Stack width={1} gap={2} mt={4}>
				<Button variant="outlined" onClick={modalProps.onClose} sx={{ flex: 1, fontSize: 15, fontWeight: 600 }}>
					Cancel
				</Button>

				<Button variant="contained" color="error" onClick={unassignHandler} sx={{ flex: 1, fontSize: 15, fontWeight: 600 }}>
					Remove
				</Button>
			</Stack>
		</Stack>
	</Dialog>
);

export default UnassignModal;
