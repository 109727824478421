import { Notification, NotificationUpdate } from "types";
import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";
// import dayjs from "dayjs";

//

const shiftColumns = `*, notification_type:notification_type_id(name, title, message_template), shift:shift_id(*, assign_user:assign_user_id(first_name, last_name, avatar_url)), employee:user_id(first_name, last_name, avatar_url)`;

export const getNotifications = async (
  user_id: string
): Promise<Notification[]> => {
  return await reqHandler(() =>
    supabase
      .from("notifications")
      .select(shiftColumns)
			.order('id', { ascending: false })
      .match({ user_id: user_id, status: 1 })
  );
};

export const updateNotification = async (item: NotificationUpdate) => {
  const { id, ...data } = item;
  return await reqHandler(() => {
    return supabase
      .from("notifications")
      .update({ ...data }) // doesn't update without destructuring for unkown reason..
      .match({ id });
  });
};
