import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";
import { NewsPost, PostComment, PostReaction, ReactionType } from "types";

//

const profileCols = "first_name, last_name, avatar_url";
// const postColumns = `*, author:author_uid(${profileCols}), shared:shared_post_id(original_author:author_uid(${profileCols}))`;
const postColumns = `*, author:author_uid(${profileCols})`;

export const getUserPosts = async (uid: string): Promise<NewsPost[]> => {
	return await reqHandler(() => supabase.from("news_posts").select(postColumns).eq("author_uid", uid));
};

export const getCompanyPosts = async (company_id: string | null, uid: string): Promise<NewsPost[]> => {
	if (!company_id) return [];
	return await reqHandler(() => supabase.from("news_posts").select(postColumns).match({ company_id }).neq("author_uid", uid).is("is_public", true));
};

export const getSharedPost = async (postId: number): Promise<NewsPost[]> => {
	return await reqHandler(() => {
		return supabase.from("news_posts").select(`created_at, content, is_public, author:author_uid(${profileCols})`).eq("shared_post_id", postId);
	});
};

export const createNewsPost = async (post: any) => {
	return await reqHandler(() => supabase.from("news_posts").insert(post));
};

export const updatePost = async (id: number | string, props: any) => {
	return await reqHandler(() => {
		return supabase
			.from("news_posts")
			.update({ ...props }) // doesn't update without destructuring for unkown reason..
			.match({ id });
	});
};

export const deletePost = async (id: number | string) => {
	return await reqHandler(() => supabase.from("news_posts").delete().match({ id }));
};

// comments..

export const getPostComments = async (post_id: number) => {
	return await reqHandler(() => supabase.from("post_comments").select("comment, user_avatar, user_name").match({ post_id }));
};

export const addPostComment = async (comment: PostComment) => {
	return await reqHandler(() => supabase.from("post_comments").insert(comment));
};

// reactions..

export const getPostReactions = async (post_id: number | string) => {
	return await reqHandler(() => supabase.from("post_reactions").select("id, uid, reaction_type").match({ post_id }));
};

export const addPostReaction = async (reaction: PostReaction) => {
	return await reqHandler(() => supabase.from("post_reactions").insert(reaction));
};

export const updatePostReaction = async (params: { id: number; type: ReactionType }) => {
	return await reqHandler(() => supabase.from("post_reactions").update({ reaction_type: params?.type }).eq("id", params?.id));
};

export const deletePostReaction = async (id: number) => {
	return await reqHandler(() => supabase.from("post_reactions").delete().match({ id }));
};
