import { ReactElement } from "react";
import { InputAdornment, SxProps, TextFieldProps } from "@mui/material";

import { FormInput } from "components";

//

export const fieldStyles = {
	labelSx: { fontSize: 14, color: "#202422", lineHeight: "18px", mb: 1 },
	inputSx: {
		".MuiInputBase-root, .MuiSelect-select": { bgcolor: "white" },
		"&, fieldset": { borderRadius: "7px" },
		"input, .MuiSelect-select": {
			height: 60,
			fontSize: 14,
			paddingLeft: "20px",
			paddingTop: "18.5px",
			paddingBottom: "18.5px",
			boxSizing: "border-box",
		},
	},
};

export const authFieldStyles = {
	// labelSx: { fontSize: 14, color: "#202422", lineHeight: "18px", mb: 1 },
	labelSx: { color: "rgba(0, 0, 0, 0.6)", fontSize: 15, lineHeight: "22px", mb: 1 },
	inputSx: {
		".MuiInputBase-root, .MuiSelect-select": { bgcolor: "white" },
		"&, fieldset": { borderRadius: "10px", borderColor: "#EDEDED" },
		"input, .MuiSelect-select": {
			height: 50,
			fontSize: 14,
			paddingLeft: "20px",
			paddingTop: "18.5px",
			paddingBottom: "18.5px",
			boxSizing: "border-box",
			backgroundColor: "#F6F6F6",
			
		},
	},
};

type SettingInputProps = {
	name: string;
	label?: string;
	icon?: ReactElement;
	adornmentSx?: SxProps;
	typeCompany?: boolean;
	required?: string | boolean;
	textFieldProps?: TextFieldProps;
};

//

const SettingInput = ({ icon, textFieldProps, typeCompany, adornmentSx, ...props }: SettingInputProps) => (
	<FormInput
		{...props}
		noLabelStar={typeCompany}
		labelSx={{ ...fieldStyles.labelSx, ...(typeCompany && { fontSize: 18, fontWeight: 600, lineHeight: "24.5px" }) }}
		textFieldProps={{
			sx: fieldStyles.inputSx,
			...textFieldProps,
			InputProps: {
				endAdornment: icon ? (
					<InputAdornment position="end" sx={{ mr: 1.25, svg: { width: 15, height: 15, color: "#3844AC" }, ...adornmentSx }}>
						{icon}
					</InputAdornment>
				) : null,
			},
		}}
	/>
);

export default SettingInput;
