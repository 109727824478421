import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  Button,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Company } from "types";
import slack from "../../../assets/images/slack.png";
import google from "../../../assets/images/google.png";
import microsoft from "../../../assets/images/microsoft.png";
//
type PropTypes = {
  form: UseFormReturn<Company>;
  companyID: string;
  isAdmin: boolean;
};
const ThirdPartyIntegration = ({ form, companyID, isAdmin }: PropTypes) => {
  // jsx..
  return (
    <FormProvider {...form}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            3rd Party Integrations
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            You can manage and configure third-party integrations to enhance
            your shift management experience. Below are some of the integrations
            available:
          </Typography>
        </Box>
        <Box
          component="form"
          // onSubmit={form.handleSubmit(submitHandler)}
          // gap={2}
        >
          <Stack
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #ECECEC",
              marginTop: 3,
              borderRadius: 3,
              paddingY: 3,
              paddingX: 3,
            }}
          >
            <Stack sx={{ justifyContent: "start", alignItems: "center" }}>
              <img src={slack} alt="slack" />
              <Box sx={{ marginLeft: 2 }}>
                <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
                  Slack
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Connect your Slack workspace to receive real-time shift
                  updates and notifications.
                </Typography>
              </Box>
            </Stack>
            <Button
              // fullWidth
              type="submit"
              variant="contained"
              // disabled={isValidatingInvite || isGeneratingInviteCode}
              color={
                Object.keys(form.formState.errors)?.length ? "error" : "primary"
              }
              sx={{
                height: 50,
                fontWeight: 600,
                fontSize: { xs: 18, sm: 20 },
                px: 5,
              }}
            >
              Connect
            </Button>
          </Stack>
          <Stack
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #ECECEC",
              marginTop: 3,
              borderRadius: 3,
              paddingY: 3,
              paddingX: 3,
            }}
          >
            <Stack sx={{ justifyContent: "start", alignItems: "center" }}>
            <img src={google} alt="google" />
              <Box sx={{ marginLeft: 2 }}>
                <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
                  Google Calendar
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Sync your shift schedule with your Google Calendar to stay
                  organized.
                </Typography>
              </Box>
            </Stack>
            <Button
              // fullWidth
              type="submit"
              variant="contained"
              // disabled={isValidatingInvite || isGeneratingInviteCode}
              color={
                Object.keys(form.formState.errors)?.length ? "error" : "primary"
              }
              sx={{
                height: 50,
                fontWeight: 600,
                fontSize: { xs: 18, sm: 20 },
                px: 5,
              }}
            >
              Connect
            </Button>
          </Stack>
          <Stack
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #ECECEC",
              marginTop: 3,
              borderRadius: 3,
              paddingY: 3,
              paddingX: 3,
            }}
          >
            <Stack sx={{ justifyContent: "start", alignItems: "center" }}>
            <img src={microsoft} alt="microsoft" />
              <Box sx={{ marginLeft: 2 }}>
                <Typography sx={{ fontSize: 21, fontWeight: 700 }}>
                  Microsoft Teams
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Collaborate with your team seamlessly using Microsoft Teams
                  and access shift-related information.
                </Typography>
              </Box>
            </Stack>
            <Button
              // fullWidth
              type="submit"
              variant="contained"
              // disabled={isValidatingInvite || isGeneratingInviteCode}
              color={
                Object.keys(form.formState.errors)?.length ? "error" : "primary"
              }
              sx={{
                height: 50,
                fontWeight: 600,
                fontSize: { xs: 18, sm: 20 },
                px: 5,
              }}
            >
              Connect
            </Button>
          </Stack>
        </Box>
      </Box>
      {/* <LoadingOverlay open={isSavingSettings || areImageOperationsLoading} /> */}
    </FormProvider>
  );
};
export default ThirdPartyIntegration;
