import { AdminUserAttributes } from "@supabase/supabase-js";
import supabase from "supabase/supabaseClient";

import { errorToast, reqHandler } from "utils/api-helpers";

//
export const getResetPassLink = async (email: string) => {
  return await reqHandler(() => {
    return supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_DOMAIN}/auth/reset-password`,
    });
  });
};
export const signinHandler = async (values: any) => {
  // console.log("values", values);
  const { data, error } = await supabase.auth.signInWithPassword(values.vals);
  // console.log("data", data, error);
  if (error) {
    errorToast(error.message);
  }
  if (data) {
    saveUserAgent(
      data?.user?.id,
      values.ipData,
      values.userAgent,
      values.storedID
    );
  }
  return data;
};
export const signupHandler = async (values: any) => {
  // console.log("values", values);
  return await reqHandler(() => {
    return supabase.auth.signUp({
      ...values,
      options: { data: { ...values?.options?.data } },
    });
  });
};
export const signInWithGoogle = async () => {
  return await reqHandler(() => {
    return supabase.auth.signInWithOAuth({
      provider: "google",
      options: { queryParams: { access_type: "offline", prompt: "consent" } },
    });
  });
};
export const signOutHandler = async () =>
  await reqHandler(() => supabase.auth.signOut());
export const updateUser = async (props: { mainData?: any; metaData?: any }) => {
  return await reqHandler(() =>
    supabase.auth.updateUser({
      ...(props.mainData || {}),
      data: props.metaData || {},
    })
  );
};
export const inviteByEmail = async (email: string, cid: string | number) => {
  const { data, error } = await supabase
    .from("companies")
    .select("id, name, invite_code")
    .eq("id", cid)
    .single();
  if (error) {
    throw error;
  }
  return await reqHandler(async () => {
    await supabase.functions.invoke("send-company-join-invite-email", {
      body: {
        email: email,
        company_name: data.name,
        join_code: data.invite_code,
        link: `${process.env.REACT_APP_DOMAIN}/auth/sign-in`,
      },
    });
  });
};
export const createEmployee = async (data: AdminUserAttributes) =>
  await reqHandler(() => supabase.auth.admin.createUser(data));

export const changePassword = async (values: any) => {
  if (values.new_password !== values.confirm_password) {
    errorToast("Passwords do not match");
    return;
  }
  const { data, error } = await supabase.rpc("verify_user_password", {
    password: values.password,
  });
  if (error || data === false) {
    errorToast("Invalid password");
    return;
  }
  if (data === true) {
    return await reqHandler(() =>
      supabase.auth.updateUser({ password: values.new_password })
    );
  }
};
export const getLoginDevices = async (uid: string) => {
  // const { data, error } = await supabase.auth.getSession();
  // console.log("data", data);
  // if (error) {
  //   console.log("error", error);
  // }
  return await reqHandler(() =>
    supabase.from("login_events").select("*").match({ user_id: uid })
  );
};
export const saveUserAgent = async (
  uid?: string,
  ipData?: any,
  userAgent?: string,
  storedID?: any
) => {
  const { data: existingRecords, error } = await supabase
    .from("login_events")
    .select("id")
    .eq("session_check", storedID)
    .limit(1);

  if (error) {
  } else {
    if (existingRecords.length === 0) {
      await supabase.from("login_events").insert({
        user_id: uid,
        device_info: userAgent,
        ip_data: ipData,
        session_check: storedID,
      });
      // console.log("Record inserted successfully.");
    } else {
      // console.log("storedID already exists.");
    }
  }
};
export const inValidateOtherSessions = async (items: any) => {
  const { error } = await supabase
    .from("login_events")
    .delete()
    .not("session_check", "eq", items.random_id)
    .eq("user_id", items.uid);
  // console.log("error", error);
  if (!error) {
    return true;
  }
};
export const getLoginEventInfo = async (sid: string) => {
  return await reqHandler(() =>
    supabase.from("login_events").select("user_id").eq("session_check", sid)
  );
};
