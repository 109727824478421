import { useQuery } from "@tanstack/react-query";
import { Avatar, Box, Stack, SxProps } from "@mui/material";
import Avvvatars from "avvvatars-react";
import { downloadImageFile } from "api/image-files";

type PropTypes = {
  src: string | null | undefined;
  overlayContent?: string;
  keepDefaultSx?: boolean;
  hasLocalFile?: boolean;
  hasOverlay?: boolean;
  name: string | null;
  bucketName?: string;
  loaderSize?: number;
  overlaySx?: SxProps;
  isActive?: boolean;
  rootSx?: SxProps;
  sx?: SxProps;
  size?: number;
};

const DbImage = ({ src, name, hasLocalFile, size, ...props }: PropTypes) => {
  const isSrcPublicUrl = /^(https:\/\/|http:\/\/)/.test(src || "");
  const { data: urlData } = useQuery({
    queryKey: ["storage-image-download", src],
    queryFn: () =>
      src ? downloadImageFile(props.bucketName || "avatars", src) : null,
    enabled: Boolean(
      !isSrcPublicUrl &&
        !hasLocalFile &&
        typeof src === "string" &&
        src.length > 0
    ),
  });

  // Shared styles for both Avatar and Avvvatars components
  const sharedStyles = {
    width: { xs: 55, sm: 45 },
    height: { xs: 55, sm: 45 },
    mr: 3, // Ensure margin-right is consistent
    ...props.sx,
  };

  return (
    <Box
      position="relative"
      sx={{
        ...props.rootSx,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {src ? (
        <Avatar
          title={name || ""}
          alt={name || "Profile"}
          src={
            (isSrcPublicUrl || hasLocalFile) && src
              ? src
              : urlData
              ? URL.createObjectURL(urlData)
              : ""
          }
          sx={{
            ...(!props.keepDefaultSx && sharedStyles),
            fontSize: props.loaderSize,
            // Remove background color to prevent the blue ring
            bgcolor: "transparent",
            border: "none", // Ensure there's no border
          }}
        />
      ) : (
        <Box
          sx={{
            ...sharedStyles, // Apply the same styles to Avvvatars
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "transparent", // Remove any background color
            border: "none", // Ensure there's no border
          }}
        >
          <Avvvatars value={name || ""} size={size} />
        </Box>
      )}
      {props.isActive && (
        <Box
          position="absolute"
          bottom={-1}
          right={-1}
          width={9}
          height={9}
          borderRadius="50%"
          bgcolor="#54BFBF"
        />
      )}
      {props.hasOverlay && (
        <Stack
          position="absolute"
          alignItems="center"
          justifyContent="center"
          sx={{
            inset: 0,
            zIndex: 10,
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            bgcolor: "#0D99D5CC",
            fontWeight: 700,
            color: "white",
            fontSize: 24,
            ...props.overlaySx,
          }}
        >
          {props.overlayContent}
        </Stack>
      )}
    </Box>
  );
};

export default DbImage;