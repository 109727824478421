import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FaPhoneAlt, FaRegClosedCaptioning, FaRoad } from "react-icons/fa";
import { MdLink, MdWorkOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { SessionUser } from "types";
import { useDbImageHandler } from "hooks";
import { updateUserProfile } from "api/profiles";
import { updateUser } from "api/auth";
import {
  AvatarBlock,
  BadgesBlock,
  DateTimePicker,
  fieldStyles,
  Loader,
  SelectField,
  SettingInput,
  LoadingOverlay,
} from "components";
import { EmailMixedColorIcon, LocationIcon, ProfileIcon } from "assets/svg";
import { getFullName, refinePayload } from "utils/data-helpers";
import { userMetaPropKeys } from "constants/prop-keys-list";
import { getUpdateValues } from "utils/api-helpers";
//
type PropTypes = { form: UseFormReturn<SessionUser>; user: SessionUser };
type ArrayProps = "languages_list" | "skills_list";
const ProfileSettings = ({ form, user }: PropTypes) => {
  const userName = getFullName(user);
  const queryClient = useQueryClient();
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [deleteAvatar, setDeleteAvatar] = useState<boolean>(false);
  const [initialUserLoading, setInitialUserLoading] = useState<boolean>(true);
  const { dbImgHandler, deleteDbImg, areImageOperationsLoading } =
    useDbImageHandler("avatars", `${userName} avatar`);
  // mutations...
  // const { mutate: mutateGoogleLogin, isLoading: isGoogleLoginLoading } = useMutation(signInWithGoogle);
  const { mutate: mutateUserUpdate, isLoading: isUserUpdating } =
    useMutation(updateUser);
  const { mutate: mutateProfileUpdate, isLoading: isProfileUpdating } =
    useMutation(updateUserProfile, {
      onSuccess: () => {
        toast.success("Profile updated successfully 👏");
        queryClient.invalidateQueries({ queryKey: ["user-context-profile"] });
      },
    });
  // reset form values on user-context update..
  useEffect(() => {
    if (user && !isProfileUpdating) {
      form.reset(user);
      if (initialUserLoading) setInitialUserLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isProfileUpdating]);
  // utilities..
  const languagesList = form.watch("languages_list");
  const skillsList = form.watch("skills_list");
  const badgeRemoveHandler = (item: string, field: ArrayProps) => {
    const prev = [...(form.watch(field) || [])];
    const index = prev?.indexOf(item);
    if (index !== undefined && index > -1) prev?.splice(index, 1);
    form.setValue(field, prev);
  };
  const newBadgeHandler = (
    item: string,
    field: ArrayProps,
    list: string[] | null
  ) => {
    form.setValue(field, [...(list || []), item]);
  };
  const removeAvatar = () => {
    if (!deleteAvatar) setDeleteAvatar(true);
    if (avatarFile) setAvatarFile(null);
  };
  const submitHandler = () => {
    // first two properties useless at the moment..
    const { id, created_at, session, email, ...otherVals } = form.getValues();
    const metaData: any = {};
    const profileVals = refinePayload({
      dateProps: ["birth_date"],
      vals: otherVals,
    });
    const valsToUpdate = getUpdateValues(user, profileVals);
    // only selecting allowed and newly updated properties for auth.user metadata..
    userMetaPropKeys?.forEach((key) => {
      if (profileVals?.[key] !== session?.user?.user_metadata?.[key])
        metaData[key] = profileVals[key];
    });
    const handleUpdate = (avatar_url?: string | null) => {
      if (Object.keys(metaData).length > 0) mutateUserUpdate({ metaData });
      if (Object.keys(valsToUpdate).length > 0) {
        mutateProfileUpdate({
          email,
          props: {
            ...valsToUpdate,
            ...(avatar_url !== undefined && { avatar_url }),
          },
        });
      }
    };
    const currentAvatar = otherVals?.avatar_url;
    if (avatarFile)
      dbImgHandler(avatarFile, form.getValues("avatar_url"), (path) =>
        handleUpdate(path)
      );
    else if (deleteAvatar && currentAvatar) {
      deleteDbImg([currentAvatar], {
        onSuccess: () => {
          handleUpdate(null);
          setDeleteAvatar(false);
        },
      });
    } else handleUpdate();
  };
  // jsx..
  return initialUserLoading ? (
    <Loader />
  ) : (
    <FormProvider {...form}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Edit Profile
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            Manage your profile setting
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={form.handleSubmit(submitHandler)}
          // pt={2.5}
        >
          <Grid container spacing={4}>
            <Grid item xs={3} sx={{}}>
              <AvatarBlock
                avatarSx={{ width: 130, height: 130, mb: 2.25 }}
                avatarProps={{
                  name: userName || "",
                  hasLocalFile: !!avatarFile,
                }}
                file={
                  deleteAvatar ? null : avatarFile || form.watch("avatar_url")
                }
                removeHandler={removeAvatar}
                setFile={setAvatarFile}
                sx={{
                  border: "1px solid #E5E5E5",
                  borderRadius: 2,
                  paddingY: 2,
                  paddingX: 3,
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Box>
                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(2, minmax(0, 1fr))",
                  }}
                  columnGap={2.65}
                  rowGap={4}
                >
                  <SettingInput
                    name="first_name"
                    label="First Name"
                    icon={<ProfileIcon />}
                    required="First Name Required"
                  />
                  <SettingInput
                    name="last_name"
                    label="Surname"
                    icon={<ProfileIcon />}
                    required="Last Name Required"
                  />
                  <SettingInput
                    name="id"
                    label="Personal ID Number"
                    textFieldProps={{ disabled: true }}
                    required
                  />
                  <DateTimePicker
                    notRequired
                    minDate={false}
                    maxDate={new Date()}
                    name="birth_date"
                    label="Date of Birth"
                    inputSx={{
                      ...fieldStyles.inputSx,
                      ".MuiInputAdornment-root": { marginRight: 1.4 },
                    }}
                    labelSx={fieldStyles.labelSx}
                  />
                  {/* <SelectField {...fieldStyles} name="religion" label="Religion" options={["Christian", "Hindu", "Muslim", "Buddhist", "Athiest", "Agnostic"]} /> */}
                  <SelectField
                    {...fieldStyles}
                    name="gender"
                    label="Gender"
                    options={["Male", "Female", "Other"]}
                  />
                  <SettingInput
                    name="website"
                    label="Website Link"
                    icon={<MdLink />}
                  />
                  <SettingInput
                    name="caption"
                    label="Profile Caption"
                    icon={<FaRegClosedCaptioning />}
                  />
                  <SettingInput
                    name="profession"
                    label="Profession"
                    icon={<MdWorkOutline />}
                  />
                  <Box gridColumn={{ sm: "span 2 / span 2" }}>
                    <SettingInput
                      textFieldProps={{ multiline: true, rows: 4 }}
                      name="about_me"
                      label="About Me"
                    />
                  </Box>
                  <BadgesBlock
                    label="Languages"
                    list={languagesList}
                    removeBadge={(item) =>
                      badgeRemoveHandler(item, "languages_list")
                    }
                    addBadge={(item) =>
                      newBadgeHandler(item, "languages_list", languagesList)
                    }
                  />
                  <BadgesBlock
                    label="Skills"
                    list={skillsList}
                    removeBadge={(item) =>
                      badgeRemoveHandler(item, "skills_list")
                    }
                    addBadge={(item) =>
                      newBadgeHandler(item, "skills_list", skillsList)
                    }
                  />
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  color="#202422"
                  fontSize={22}
                  fontWeight={700}
                  mt={{ xs: 5, sm: 4.3 }}
                >
                  Contact Details
                </Typography>
                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(2, minmax(0, 1fr))",
                  }}
                  columnGap={2.65}
                  rowGap={4}
                  mt={2.8}
                >
                  <SettingInput
                    textFieldProps={{ type: "email" }}
                    name="email"
                    label="Email address"
                    icon={<EmailMixedColorIcon />}
                    required="Email Required"
                  />
                  <SettingInput
                    name="phone"
                    label="Telephone Number"
                    icon={<FaPhoneAlt />}
                  />
                  <SettingInput
                    name="location"
                    label="Current Home Address"
                    icon={<LocationIcon />}
                  />
                  <SettingInput
                    textFieldProps={{ type: "number" }}
                    name="postcode"
                    label="Postcode"
                    icon={<FaRoad />}
                  />
                </Box>
              </Box>
              <Box
                // maxWidth={300}
                mx={{ xs: "auto", sm: 0 }}
                mt={{ xs: 5, sm: 3.5 }}
                sx={{
                  // width: "100%",
                  // justifyContent: "center",
                  // alignItems: "center",
                  textAlign: "right",
                }}
              >
                <Button
                  // fullWidth
                  type="submit"
                  variant="contained"
                  color={
                    Object.keys(form.formState.errors)?.length
                      ? "error"
                      : "primary"
                  }
                  sx={{
                    height: 60,
                    fontWeight: 600,
                    fontSize: { xs: 18, sm: 20 },
                    mt: 3.5,
                    px: 10,
                  }}
                >
                  Save Changes
                </Button>
              </Box>
              <LoadingOverlay
                open={
                  isUserUpdating ||
                  isProfileUpdating ||
                  areImageOperationsLoading
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </FormProvider>
  );
};
export default ProfileSettings;
