import { Backdrop, CircularProgress, CircularProgressProps, SxProps } from "@mui/material";

//

type PropTypes = { open: boolean; isAbsolute?: boolean; loaderProps?: CircularProgressProps; sx?: SxProps };

const WindowOverlay = ({ open, isAbsolute, loaderProps, sx }: PropTypes) => (
	<Backdrop
		open={open}
		sx={{ color: "#fff", zIndex: 1300, backdropFilter: "blur(2px)", bgcolor: "#00000050", ...(isAbsolute && { position: "absolute" }), ...sx }}
	>
		<CircularProgress color="inherit" size={70} thickness={4} {...loaderProps} />
	</Backdrop>
);

export default WindowOverlay;
