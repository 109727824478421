import { CompanyEmployees, Employee } from "types";
import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";

//
type CompanyEmployeesParams = { extraProfileProps?: string };
export const getAllEmployees = async ({ extraProfileProps = "" }: CompanyEmployeesParams): Promise<CompanyEmployees[]> => {
	const extraColumns = extraProfileProps ? `, ${extraProfileProps}` : "";
	// console.log("jj",cid, uid);
	let query = supabase
			.from("employees")
			.select(`role, profile:employee_id(id, email, first_name, last_name, avatar_url${extraColumns}), company:company_id(id,logo_url)`);

	// console.log("query",query);
	return await reqHandler(() => query);
};

export const getEmployeeInfo = async (employee_id: string, select?: string) => {
	return await reqHandler(
		() => {
			return supabase
				.from("employees")
				.select(select || "employee_id, company_id, role")
				.match({ employee_id })
				.single();
		},
		{ disableToast: true }
	);
};

export const postEmployeeJoinCompany = async ({ code, employee_id }: { code: string, employee_id: string }) => {
	const company = await reqHandler(() => supabase.from("companies").select('*').match({ invite_code: code }).single(), { disableToast: true });
	if (company.invite_code === code) {
		return await reqHandler(() => supabase.from("employees").insert({ employee_id: employee_id, company_id: company.id, role: 'employee' }));
	}
};

export const updateEmployee = async (props:Employee ) => {
	const {employee_id, company_id, role} = props as Employee;
	return await reqHandler(() => supabase.from("employees").update({'role':role.toLowerCase()}).eq("employee_id",employee_id).eq( "company_id", company_id));
};
