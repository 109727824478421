import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";

//

const TooltipSx = {
	".custom-tooltip": {
		height: 20,
		minWidth: 40,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 10,
		fontWeight: 600,
		color: "white",
		bgcolor: "primary.main",
		borderRadius: "5px",
		px: 1.25,
	},
};

const chartOptions = {
	chart: {
		zoom: { enabled: false },
		toolbar: { show: false },
	},
	dataLabels: { enabled: false },
	stroke: {
		width: 4,
		colors: ["#007AE5"],
		curve: "smooth" as "smooth",
	},
	markers: {
		colors: "white",
		strokeColors: "#007AE5",
		strokeWidth: 5,
		hover: { size: 7 },
	},
	tooltip: {
		custom: ({ series, seriesIndex, dataPointIndex }: any) => {
			return `<div class="custom-tooltip">$${series[seriesIndex][dataPointIndex]}</div>`;
		},
	},
	title: {
		text: "Chart Graph",
		style: {
			fontSize: "15px",
			fontWeight: 700,
			color: "#393C53",
			fontFamily: "Nunito, Helvatica, sans-serif",
		},
	},
	grid: { borderColor: "#9ba1cc70", strokeDashArray: 6 },
	fill: {
		type: "gradient",
		gradient: {
			shadeIntensity: 1,
			opacityFrom: 0.002,
			opacityTo: 0.7,
			stops: [0, 100],
		},
	},
	xaxis: {
		// type: "datetime" as "datetime",
	},
};

//

type PropTypes = {
	height: number;
	heading: string;
	data: number[][];
	chartType: "area" | "line";
	xAxisType?: "datetime" | "category" | "numeric" | undefined;
};

const ChartGraph = ({ data, chartType, height, heading, xAxisType }: PropTypes) => (
	<Box sx={TooltipSx}>
		<ReactApexChart
			{...{ height }}
			type={chartType}
			series={[{ data }]}
			options={{
				...chartOptions,
				title: { ...chartOptions.title, text: heading },
				xaxis: { type: xAxisType || "datetime" },
			}}
		/>
	</Box>
);

export default ChartGraph;
