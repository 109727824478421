import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";
import { PrevAccount } from "types";

//

export const getUserProfile = async (email: string, select?: string) => {
	return await reqHandler(() => {
		return supabase
			.from("profiles")
			.select(select || "*")
			.match({ email })
			.maybeSingle();
	});
};

// used to fetch previous logged in accounts..
export const getAccountsInfo = async (uidList: string[]): Promise<PrevAccount[]> => {
	return await reqHandler(() => {
		return supabase
			.from("profiles")
			.select("id, email, avatar_url, first_name, last_name, employees(role, company:company_id(id, name))")
			.in("id", uidList);
	});
};

export const updateUserProfile = async ({ email, props }: { email: string; props: any }) => {
	return await reqHandler(() => supabase.from("profiles").update(props).match({ email }));
};
