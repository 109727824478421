import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Button, Stack, Typography, Box, Grid } from "@mui/material";
import { Company } from "types";
import { useDbImageHandler } from "hooks";
import {
  getCompany,
  getCompanyByInvite,
  getNewCompanyInviteCode,
  updateCompany,
} from "api/companies";
import { SettingInput, LoadingOverlay } from "components";
import { getUpdateValues } from "utils/api-helpers";
//
type PropTypes = {
  form: UseFormReturn<Company>;
  companyID: string;
  isAdmin: boolean;
};
const RecoveryMethod = ({ form, companyID, isAdmin }: PropTypes) => {
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [deleteAvatar, setDeleteAvatar] = useState<boolean>(false);
  const { dbImgHandler, deleteDbImg, areImageOperationsLoading } =
    useDbImageHandler("avatars", `${form.getValues("name")} logo`);
  const { refetch: refetchCompany, data: initialCompanyDetails } = useQuery(
    ["company-setting-details", companyID],
    () => getCompany({ id: companyID }),
    {
      enabled: Boolean(isAdmin && companyID),
    }
  );
  useEffect(() => {
    if (initialCompanyDetails?.name) form.reset(initialCompanyDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCompanyDetails]);
  const { mutate: saveSettings, isLoading: isSavingSettings } = useMutation({
    mutationKey: ["company-settings-update"],
    mutationFn: (vals: Company) =>
      updateCompany({
        id: companyID,
        props: getUpdateValues(initialCompanyDetails, vals),
      }),
    onSuccess: () => refetchCompany(),
  });
  const { mutate: mutateValidateInvite, isLoading: isValidatingInvite } =
    useMutation(() => getCompanyByInvite(form.getValues("invite_code")), {
      onError: () => toast.success("You can save this code."), // success on 0 company returned..
      onSuccess: () => {
        toast.error("This code is currently in use.");
        form.setValue("invite_code", initialCompanyDetails?.invite_code);
      },
    });
  const { mutate: mutateNewInviteCode, isLoading: isGeneratingInviteCode } =
    useMutation(getNewCompanyInviteCode, {
      onSuccess: (code) => {
        form.setValue("invite_code", code);
        toast.success("New employee invite code generated.");
      },
    });
  // utilities..
  const onLogoRemoveClick = () => {
    if (deleteAvatar) setDeleteAvatar(true);
    if (logoFile) setLogoFile(null);
  };
  const submitHandler = (vals: Company) => {
    const logoPath = vals?.logo_url; // existing logo..
    if (logoFile) {
      dbImgHandler(logoFile, logoPath, (res: any) =>
        saveSettings({ ...vals, ...(!logoPath && { logo_url: res }) })
      );
    } else if (deleteAvatar && logoPath) {
      deleteDbImg([logoPath], {
        onSuccess: () => {
          saveSettings({ ...vals, logo_url: null });
          setDeleteAvatar(false);
        },
      });
    } else saveSettings(vals);
  };
  // jsx..
  return (
    <FormProvider {...form}>
      <Box
        sx={{ backgroundColor: "#fff", mt: 5, py: 3, px: 3, borderRadius: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontSize={22}
            fontWeight={700}
            lineHeight="24.5px"
            sx={{
              paddingY: 0.5,
              paddingX: 1,
              borderRadius: "5.5px",
              borderLeft: "5px solid",
              borderLeftColor: "#FBB328",
            }}
          >
            Recovery Method
          </Typography>
          <Typography
            variant="h3"
            fontSize={14}
            fontWeight={400}
            lineHeight="26px"
            sx={{
              paddingX: 1.7,
            }}
          >
            Fill out your recovery information
          </Typography>
        </Box>
        <Stack
          component="form"
          onSubmit={form.handleSubmit(submitHandler)}
          gap={2}
        >
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Box>
                <Box
                  sx={{
                    alignItems: "center",
                    paddingX: 3,
                    paddingY: 3,
                  }}
                >
                  <Typography variant="h3" fontSize={22} fontWeight={700}>
                    Phone Number
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    paddingX: 3,
                    paddingY: 3,
                  }}
                >
                  <Typography variant="h3" fontSize={22} fontWeight={700}>
                    Email Address
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    paddingX: 3,
                    paddingY: 3,
                  }}
                >
                  <Typography variant="h3" fontSize={22} fontWeight={700}>
                    Reverse Codes
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Stack
                direction="column"
                flex={0.6}
                justifyContent="space-between"
                rowGap={4}
              >
                <SettingInput
                  name="phone"
                  label=""
                  icon={
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        textDecoration: "underline",
                      }}
                    >
                      Edit
                    </Typography>
                  }
                />
                <SettingInput
                  textFieldProps={{ type: "email" }}
                  name="email"
                  label=""
                  icon={
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        textDecoration: "underline",
                      }}
                    >
                      Edit
                    </Typography>
                  }
                  required="Email Required"
                />
                <SettingInput
                  name="codes"
                  label=""
                  icon={
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        textDecoration: "underline",
                      }}
                    >
                      Generate new codes
                    </Typography>
                  }
                />
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isValidatingInvite || isGeneratingInviteCode}
                    color={
                      Object.keys(form.formState.errors)?.length
                        ? "error"
                        : "primary"
                    }
                    sx={{
                      height: 50,
                      fontWeight: 600,
                      fontSize: { xs: 18, sm: 20 },
                      px: 5,
                    }}
                  >
                    Update Timezone Settings
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <LoadingOverlay open={isSavingSettings || areImageOperationsLoading} />
    </FormProvider>
  );
};
export default RecoveryMethod;
