import { Box, Stack, Typography, Divider } from "@mui/material";
import { AvailableActivities, LatestActivities } from "sections";
import { useContext } from "react";
import { UserContext } from "context/UserContext";
//


//

const AttentionBoard = () => {
	// queries..
    const user = useContext(UserContext);

	// jsx..

	return (
		<>
	
			
		</>
	);
};

export default AttentionBoard;
