import { lazy } from "react";

const EmployeeLeaveRequest = lazy(() => import("../pages/employee/EmployeeLeaveRequest"));
const CompanyLeaveRequest = lazy(() => import("../pages/company/CompanyLeaveRequest"));
const MyLeaveRequest = lazy(() => import("../pages/company/MyLeaveRequest"));
const EmployeeScheduler = lazy(() => import("../pages/employee/EmployeeScheduler"));
const EmployeeDashboard = lazy(() => import("../pages/employee/EmployeeDashboard"));
const CompanyScheduler = lazy(() => import("../pages/company/CompanyScheduler"));
const MySchedule = lazy(() => import("../pages/company/MySchedule"));
const CompanyDashboard = lazy(() => import("../pages/company/CompanyDashboard"));
const EmployeeShifts = lazy(() => import("../pages/employee/EmployeeShifts"));
const CompanyShifts = lazy(() => import("../pages/company/CompanyShifts"));
const MyShifts = lazy(() => import("../pages/company/MyShifts"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const PasswordReset = lazy(() => import("../pages/auth/PasswordReset"));
const VerifyEmail = lazy(() => import("../pages/auth/VerifyEmail"));
const UsersList = lazy(() => import("../pages/company/UsersList"));
const EmailSent = lazy(() => import("../pages/auth/EmailSent"));
const Signin = lazy(() => import("../pages/auth/Signin"));
const Signup = lazy(() => import("../pages/auth/Signup"));
// const MultiAuthentication =lazy(() => import("../pages/auth/MultiAuthentication"));
export const Profile = lazy(() => import("../pages/Profile"));
export const Page404 = lazy(() => import("../pages/Page404"));
export const Settings = lazy(() => import("../pages/Settings"));

//

const RoutesConf = {
	admin: [
		{ path: "/users", Component: UsersList },
		{ path: "/", Component: CompanyDashboard },
		{ path: "/shifts", Component: CompanyShifts },
		{ path: "/schedule", Component: CompanyScheduler },
		{ path: "/dashboard", Component: CompanyDashboard },
		{ path: "/leave-request", Component: CompanyLeaveRequest },
		{ path: "/my-shifts", Component: MyShifts },
		{ path: "/my-leave", Component: MyLeaveRequest },
		{ path: "/my-schedule", Component: MySchedule },
	],
	manager: [
		{ path: "/users", Component: UsersList },
		{ path: "/", Component: CompanyDashboard },
		{ path: "/shifts", Component: CompanyShifts },
		{ path: "/schedule", Component: CompanyScheduler },
		{ path: "/dashboard", Component: CompanyDashboard },
		{ path: "/leave-request", Component: CompanyLeaveRequest },
		{ path: "/my-shifts", Component: MyShifts },
		{ path: "/my-leave", Component: MyLeaveRequest },
		{ path: "/my-schedule", Component: MySchedule },
	],
	employee: [
		{ path: "/", Component: EmployeeDashboard },
		{ path: "/shifts", Component: EmployeeShifts },
		{ path: "/schedule", Component: EmployeeScheduler },
		{ path: "/dashboard", Component: EmployeeDashboard },
		{ path: "/leave-request", Component: EmployeeLeaveRequest },
		{ path: "/my-shifts", Component: MyShifts },
		{ path: "/my-leave", Component: MyLeaveRequest },
		{ path: "/my-schedule", Component: MySchedule },
	],
	auth: [
		{ path: "/auth/sign-in", Component: Signin },
		{ path: "/auth/sign-up", Component: Signup },
		{ path: "/auth/verify-email", Component: VerifyEmail },
		{ path: "/auth/reset-email-sent", Component: EmailSent },
		{ path: "/auth/reset-password", Component: PasswordReset },
		{ path: "/auth/forgot-password", Component: ForgotPassword },
	],
};

export default RoutesConf;
