import { Box, Stack, Typography } from "@mui/material";
import { SessionUser } from "types";

//

const InfoBlock = ({ title, value, isLast }: { title: string; value: string | null; isLast?: boolean }) => (
	<Stack mb={isLast ? 0 : 2}>
		<Typography width={78} color="app.greyblue">
			{title}:
		</Typography>
		<Typography>{value || "-"}</Typography>
	</Stack>
);

//

export const PersonalInfo = ({ user }: { user: SessionUser }) => (
	<Box bgcolor="white" px={3} pt={3} pb={{ xs: 4, sm: 2.75 }} borderRadius="10px">
		<Typography variant="h3" color="#1E3A56" fontSize="14px" fontWeight={600}>
			Personal Information:
		</Typography>

		<Stack flexWrap="wrap" gap={2} mt={1.5} sx={{ ".MuiTypography-root": { fontSize: "13px", fontWeight: 600 } }}>
			<Box flex={1} minWidth={225}>
				<InfoBlock title="Phone" value={user?.phone} />
				<InfoBlock title="Gender" value={user?.gender} />
				<InfoBlock title="Website" value={user?.website} isLast />
			</Box>

			<Box flex={1} minWidth={225}>
				<InfoBlock title="Birthday" value={user?.birth_date} />
				<InfoBlock title="Email" value={user?.email} />
				<InfoBlock title="Location" value={user?.location} isLast />
			</Box>
		</Stack>
	</Box>
);

export const AboutDesc = ({ value }: { value: string | null }) => (
	<Box bgcolor="white" px={3} pt={2.5} pb={{ xs: 1.75, sm: 3 }} borderRadius="10px">
		<Typography variant="h3" color="#1E3A56" fontSize="14px" fontWeight={600}>
			About
		</Typography>

		<Typography color="#253334" fontSize="14px" mt={1.25}>
			{value ? (
				<Box component="span" color="#253334">
					{value}
				</Box>
			) : (
				<Box component="span" color="#b0d3d6">
					Write a little something about yourself...
				</Box>
			)}
		</Typography>
	</Box>
);
