import { useQuery } from "@tanstack/react-query";

import { PrevAccount } from "types";
import { getAccountsInfo } from "api/profiles";
import { getPrevAccounts } from "utils/data-helpers";

//

type ReturnType = { prevLoggedProfiles: PrevAccount[] | undefined; refetchPrevProfiles: VoidFunction; prevProfilesLoading: boolean };

const usePrevAccounts = (uid: string): ReturnType => {
	const accountList = getPrevAccounts();
	const loggedOutAccounts = accountList?.filter((acc: any) => acc?.id !== uid);
	const accountIdList = loggedOutAccounts?.map((acc: any) => acc?.id);

	const { data, refetch, isFetching } = useQuery({
		queryKey: ["logged-accounts-list", uid, accountIdList?.length],
		queryFn: () => getAccountsInfo(accountIdList),
		enabled: accountIdList.length > 0,
		retry: false,
	});

	return { prevLoggedProfiles: data, refetchPrevProfiles: refetch, prevProfilesLoading: isFetching };
};

export default usePrevAccounts;
