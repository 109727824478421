import { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  DoubleUserIcon,
  ActiveDoubleUserIcon,
  CompanyBuildingIcon,
  ActiveCompanyBuildingIcon,
} from "assets/svg";
//
const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 125,
    height: 82,
    display: "flex",
    alignItems: "center",
    fontSize: 9,
    lineHeight: "11px",
    textAlign: "center",
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.5)",
    boxShadow: "0 4px 8px 0 #00000025",
    border: "1px solid #00000040",
    borderRadius: "5px",
  },
}));
const CheckBlock = (props: {
  name: string;
  isSelected: boolean;
  onClick: VoidFunction;
  icon: ReactElement;
  activeIcon: ReactElement;
  infoText: string;
}) => {
  return (
    <Stack
      onClick={props.onClick}
      sx={{
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: 4,
        border: props.isSelected
          ? `1px solid var(--Black-B20, #75B7F1)`
          : `1px solid var(--Black-B20, #F6F6F6)`,
        background: props.isSelected
          ? "var(--Primary-Color-2-P20, #F5FAFE)"
          : "var(--Black-B10, #FBFBFB)",
        height: "200px",
      }}
    >
      <Box position="relative">
        {props.isSelected === true ? props.activeIcon : props.icon}
        <InfoTooltip title={props.infoText}>
          <IconButton
            sx={{
              // position: "absolute",
              // top: 0,
              // right: -15,
              // width: 14.25,
              // height: 14.25,
              color: "#9ba1cc66",
              p: 0,
              svg: { height: "auto" },
            }}
          >
            {/* <HiInformationCircle /> */}
          </IconButton>
        </InfoTooltip>
      </Box>
      <Typography
        fontWeight={500}
        fontSize={18}
        color={props.isSelected ? "#007AE5" : "#949695"}
        lineHeight="26px"
        mt={1}
      >
        {props.name}
      </Typography>
      {/* <Radio
        checked={props.isSelected}
        inputProps={{ "aria-label": props.name }}
        sx={{
          p: 0,
          mt: 2.5,
          [`.${svgIconClasses.root}:first-of-type`]: { fill: "#0000003B" },
          [`&.${radioClasses.checked} .${svgIconClasses.root}:last-of-type`]: {
            transform: "scale(1.2)",
          },
        }}
      /> */}
    </Stack>
  );
};
//
const UserRoleStep = ({
  isAdmin,
  setRole,
}: {
  isAdmin: boolean;
  setRole: (param: boolean) => void;
}) => {
  return (
    <Stack justifyContent="space-between" mt="24px" gap={2}>
      <CheckBlock
        name="Employee"
        isSelected={!isAdmin}
        onClick={() => setRole(false)}
        infoText="An Employee Account is used by an employee to view and access their shift information and other aspects"
        icon={<DoubleUserIcon />}
        activeIcon={<ActiveDoubleUserIcon />}
      />
      <CheckBlock
        name="Company"
        isSelected={isAdmin}
        onClick={() => setRole(true)}
        infoText="A Company Account Requires Payment and Sign up to use, after this the company is thenable to invite users to roster and manage"
        icon={<CompanyBuildingIcon />}
        activeIcon={<ActiveCompanyBuildingIcon />}
      />
    </Stack>
  );
};
export default UserRoleStep;
