import supabase from "supabase/supabaseClient";
import { errorToast, reqHandler } from "utils/api-helpers";

//

export const downloadImageFile = async (bucket: string, path: string) => {
	return await reqHandler(() => supabase.storage.from(bucket).download(path), { dontThrowErr: true });
};

export const uploadImage = async (bucket: string, imgFile: File | null, imgName?: string) => {
	if (!imgFile) throw new Error("You must select an image to upload.");
	const imgExtension = imgFile?.name?.split(".").pop();
	const fileName = `${imgName || imgFile?.name}__${Math.random().toString().slice(2, 7)}.${imgExtension}`;

	let { error: uploadError } = await supabase.storage.from(bucket).upload(fileName, imgFile);

	if (uploadError) errorToast(uploadError);
	else return fileName;
};

export const updateImage = async (bucket: string, imgFile: File | null, name: string) => {
	if (!imgFile) throw new Error("You must select an image to upload.");

	return await reqHandler(() => supabase.storage.from(bucket).update(name, imgFile, { cacheControl: "3600", upsert: true }));
};

export const deleteImage = async (bucket: string, pathList: string[]) => {
	return await reqHandler(() => supabase.storage.from(bucket).remove(pathList));
};
