import { Stack } from "@mui/material";

import {
  AuthInput,
  CountriesInput,
  EmailInput,
  PhoneInput,
  TimezonesInput,
  DateTimePicker,
  authFieldStyles,
} from "components";
import { ProfileIcon } from "assets/svg";

//

const UserDetailsStep = () => (
  <Stack direction="column" spacing={1}>
    <AuthInput
      label="First Name"
      name="first_name"
      required="First Name Required"
			placeholder="John"
      endIcon={<ProfileIcon />}
    />

    <AuthInput
      label="Last Name"
      name="last_name"
      required="Last Name Required"
			placeholder="Doe"
      endIcon={<ProfileIcon />}
    />

    <EmailInput />

    <PhoneInput />
    <DateTimePicker
      notRequired
      minDate={false}
      maxDate={new Date()}
      name="birth_date"
      label="Date of Birth"
      inputSx={{
        ...authFieldStyles.inputSx,
        ".MuiInputAdornment-root": { marginRight: 0},
      }}
      labelSx={authFieldStyles.labelSx}
    />
		<AuthInput
      label="Personal Address"
      name="personal_address"
      required="Personal Address Required"
			placeholder="Example Street, 123"
      endIcon={<ProfileIcon />}
    />
    <CountriesInput label="Address" />
    <TimezonesInput label="Timezone" />
  </Stack>
);

export default UserDetailsStep;
