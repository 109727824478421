import { Box } from "@mui/material";
import PhoneField from "react-phone-input-2";
import { Controller, useFormContext } from "react-hook-form";
import { BsFillTelephoneFill } from "react-icons/bs";
import { red } from "@mui/material/colors";

import "react-phone-input-2/lib/material.css";

import FieldControl from "./FieldControl";

//

const PhoneInput = ({ label = "Phone" }: { label?: string }) => {
  const form = useFormContext();
  return (
    <Controller
      name="phone"
      control={form.control}
      rules={{ required: "Phone number required" }}
      render={({ field, fieldState: { error } }) => (
        <FieldControl {...{ label, error }}>
          <Box position="relative">
            <PhoneField
              {...field}
              country="au"
              enableSearch
              specialLabel=""
              placeholder="+61 676 876 543"
              searchStyle={{ width: "calc(100% - 10px)" }}
              inputStyle={{
                height: 50,
                width: "100%",
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 30,
                borderRadius: "10px",
                borderColor: error ? red[500] : "#EDEDED",
                backgroundColor: "#F6F6F6",
              }}
              autoFormat={false}
            />

            <Box position="absolute" right={12} top={16} color="app.greyblue">
              <BsFillTelephoneFill />
            </Box>
          </Box>
        </FieldControl>
      )}
    />
  );
};

export default PhoneInput;
