import { useState, useContext, useMemo } from "react";
import {
  Box,
  Typography,
  Stack,
  MenuItem,
  Grid,
  Paper,
  buttonClasses,
} from "@mui/material";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { MyLeaveRequestTable } from "components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { UserContext } from "context/UserContext";
import { LeaveRequest, RecordStatus } from "types";
import { Loader, StatusBtn, PopoverMenu } from "components";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { BsThreeDots } from "react-icons/bs";
import {
  deleteRequest,
  getLeaveRequestList,
  getLeaveHistoryRequestList,
} from "api/leave-request";
import { dateFormatConvertor, getLeaveStatus } from "utils/records-helpers";
//
type PropTypes = { form: UseFormReturn<LeaveRequest>; leave: any };
const MyLeave = ({ form, leave }: PropTypes) => {
  const { id: uid } = useContext(UserContext);
  const [initialUserLoading] = useState<boolean>(false);
  const leavesQuery = useQuery(["leave-request-list", uid], () =>
    getLeaveRequestList({ employee_id: uid })
  );
  const leavesHistoryQuery = useQuery(["leave-history-request-list", uid], () =>
    getLeaveHistoryRequestList({ employee_id: uid })
  );
  const delMutation = useMutation(["delete-request"], deleteRequest, {
    onSuccess: () => leavesQuery.refetch(),
  });
  // const tableRows = leavesQuery?.data?.map((row, i: number) => <LeaveRequestRow key={i} row={row} mutateDelete={delMutation.mutate} isUserEmployee />);
  // const tableRowsHistory = leavesHistoryQuery?.data?.map((row, i: number) => <LeaveRequestRow key={i} row={row} mutateDelete={delMutation.mutate} isUserEmployee />);

  const gridRows = useMemo(() => {
    return leavesQuery.data?.map(
      ({
        id,
        manager: { first_name, last_name },
        leaving_from_date,
        resume_date,
        employee: { email },
        is_approved,
        type_of_leave,
        comment,
        ...user
      }) => ({
        id,
        manager: first_name + " " + last_name,
        leaving_from_date: dateFormatConvertor(leaving_from_date),
        resume_date: dateFormatConvertor(resume_date),
        employee: email,
        status: is_approved ? "approved" : "pending",
        user,
        type_of_leave,
        comment
      })
    );
  }, [leavesQuery.data]);
  const gridRowsHistory = useMemo(() => {
    return leavesHistoryQuery.data?.map(
      ({
        id,
        manager: { first_name, last_name },
        leaving_from_date,
        resume_date,
        employee: { email },
        is_approved,
        type_of_leave,
        comment,
        ...user
      }) => ({
        id,
        manager: first_name + " " + last_name,
        leaving_from_date: dateFormatConvertor(leaving_from_date),
        resume_date: dateFormatConvertor(resume_date),
        employee: email,
        status: is_approved ? "approved" : "pending",
        user,
        type_of_leave,
        comment,
      })
    );
  }, [leavesHistoryQuery.data]);

  const gridColumns: GridColDef[] = useMemo(
    () => [
      // gridProfileColumn,
      {
        field: "leaving_from_date",
        headerName: "From",
        flex: 1,
        cellClassName: "date-cell",
      },
      {
        field: "resume_date",
        headerName: "To",
        flex: 1,
        cellClassName: "date-cell",
      },
      // { field: "manager", headerName: "Manager", flex: 1 },
      // {
      //   field: "employee",
      //   headerName: "Employee",
      //   flex: 1,
      //   cellClassName: "email-cell",
      { field: "type_of_leave", headerName: "Type", flex: 1 },
      { field: "comment", headerName: "Comment", flex: 1 },
      { field: "earnings", headerName: "Earnings", flex: 1 },
      // },
      {
        flex: 1,
        field: "status",
        headerName: "Status",
        renderCell: (props: GridRenderCellParams<String>) => {
          //@ts-ignore
          const leaveStatus: RecordStatus = getLeaveStatus(props.row);
          return (
            <Stack
              width={1}
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <StatusBtn status={leaveStatus} />

              {/* <PopoverMenu
                btnChild={<BsThreeDots />}
                menuId={`user-${props.id}-row-menu`}
                btnId={`user-${props.id}-row-menu-button`}
                btnSx={{ fontSize: 20, borderRadius: "50%", p: 0.5 }}
                menuChild={(closePopover) => {
                  const Item = (props: {
                    id: any;
                    text: string;
                    clickHandler: VoidFunction;
                  }) => (
                    <MenuItem
                      onClick={() => {
                        // clickHandler();
                        delMutation.mutate(props?.id);
                        closePopover();
                      }}
                    >
                      {props.text}
                    </MenuItem>
                  );
                  //
                  return (
                    <div>
                      <Item
                        id={props?.id}
                        text="Delete"
                        clickHandler={() => {}}
                      />
                    </div>
                  );
                }}
              /> */}
            </Stack>
          );
        },
      },
    ],
    [delMutation]
  );
  const data = [
    { title: "Sick Leave", description: "12 Hours" },
    { title: "Annual Leave", description: "30 Hours" },
    { title: "Personal Leave", description: "08 Hours" },
  ];
  // jsx..
  return initialUserLoading ? (
    <Loader />
  ) : (
    <>
      <Box
        elevation={0}
        component={Paper}
        sx={{
          position: "relative",
          borderRadius: "10px",
          overflow: "hidden",
          px: 3,
          py: 3,
          mb: 3,
        }}
      >
        <Stack
          flexWrap="wrap"
          className="top-header-block"
          justifyContent="space-between"
          sx={{
            rowGap: 1.5,
            columnGap: 1,
            [`.${buttonClasses.root}`]: {
              height: 32,
              borderRadius: "10px",
              lineHeight: 0.8,
            },
          }}
        >
          <Stack flexWrap="wrap" alignItems="center" columnGap={2} rowGap={0.5}>
            <Box>
              <Typography
                variant="h2"
                fontSize={24}
                fontWeight={700}
                lineHeight="32px"
              >
                Leave Balance
              </Typography>
              <Typography
                variant="h2"
                fontSize={16}
                fontWeight={400}
                lineHeight="32px"
              >
                Manage Your Leave Balances
              </Typography>
            </Box>
          </Stack>
          <Box
            justifyContent={"flex-end"}
            alignItems={"center"}
            display={"flex"}
          >
            <Grid container>
              {data.map((item, index) => (
                <Grid
                  item
                  xs={4}
                  key={index}
                  sx={{
                    borderRight:
                      index !== data.length - 1 ? "1px solid #ccc" : "none",
                    paddingLeft: 3,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "120px", textAlign: "left" }}>
                    <Typography variant="body2" color={"#484B4A"}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "120px", textAlign: "left" }}>
                    <Typography
                      variant="h6"
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Box>
      <FormProvider {...form}>
        <Box component="form" pt={2.5}>
          <Box component="section">
            <MyLeaveRequestTable
              leavetableTitle={"Current Leaves"}
              headers={gridColumns}
              bodyRows={gridRows || []}
              filtersList={[
                "leaving_from_date",
                "resume_date",
                "type_of_leave",
                "comment",
                "status",
              ]}
              isLoading={leavesQuery.isFetching}
            />
          </Box>

          <Box component="section" mt={4}>
            {/* <Typography variant="h2" fontSize={24} fontWeight={700} mb={1.25}>
							Leave History
						</Typography> */}
            <MyLeaveRequestTable
              leavetableTitle={"Previous Leaves"}
              headers={gridColumns}
              bodyRows={gridRowsHistory || []}
              filtersList={[
                "leaving_from_date",
                "resume_date",
                "type_of_leave",
                "comment",
                "status",
              ]}
              isLoading={leavesHistoryQuery.isFetching}
            />
            {/* <SimpleTable bodyContent={tableRowsHistory} headers={getLeaveHeaders("Manager")} isLoading={leavesHistoryQuery.isLoading} filtersList={["manager", "from", "to", "email address", "status"]} /> */}
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};
export default MyLeave;
