import { ReactElement } from "react";
import { FieldError } from "react-hook-form";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";

//

const FieldControl = (props: { children: ReactElement; label: string; error?: FieldError }) => (
	<FormControl>
		<FormLabel error={!!props.error} sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 15, lineHeight: "22px", mb: 1 }}>
			{props.label}
		</FormLabel>

		{props.children}

		{props.error && <FormHelperText error>{props.error?.message}</FormHelperText>}
	</FormControl>
);

export default FieldControl;
