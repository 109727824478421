import { useContext, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Grid, Stack, Button, Checkbox, IconButton, Typography, DialogContent, FormControlLabel } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
//
import { UserContext } from "context/UserContext";
import { postLeaveRequest } from "api/leave-request";
import { CompanyEmployees, LeaveRequest } from "types";
import { DateTimePicker, SelectField, LoadingOverlay } from "components";
import { LeaveRequestDefaults } from "constants/FormDefaultValues";
import { getUpdateValues } from "utils/api-helpers";
import { datesToString } from "utils/data-helpers";

//

type PropTypes = {
	mutateUpdate: Function;
	editLeave: LeaveRequest;
	closeModal: VoidFunction;
	isUpdateLoading: boolean;
	refetchRequests: Function;
	employeesData: CompanyEmployees[] | undefined;
};

const LeaveRequestForm = (props: PropTypes) => {
	const user = useContext(UserContext);

	const form = useForm<LeaveRequest>({ defaultValues: LeaveRequestDefaults });

	useEffect(() => {
		if (props.editLeave) form.reset(props.editLeave);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.editLeave]);

	// mutations..

	const postMutation = useMutation(postLeaveRequest, {
		onSuccess: () => {
			props.refetchRequests();
			props.closeModal();
		},
	});

	// const { mutate: mutateInvite, isLoading: isInviteLoading } = useMutation(inviteByEmail);

	// utilities..

	const isEmployeeSelected = !!form.watch("employee_id");
	const employeeEmailOptions = props.employeesData?.map((e) => e?.profile?.email) || [];

	const onSubmit: SubmitHandler<LeaveRequest> = (data) => {
		const { company_id, email, first_name, last_name, avatar_url } = user;

		const valuesToSend: LeaveRequest = {
			...data,
			company_id,
			...datesToString(data, ["leaving_from_date", "resume_date"]),
			notify_manager: { email, name: `${first_name} ${last_name}`, avatar_url },
		};
		console.log(valuesToSend)

		if (props.editLeave) {
			props.mutateUpdate({ id: props.editLeave?.id, props: getUpdateValues(props.editLeave, valuesToSend) });
		} else {
			postMutation.mutate(valuesToSend);
		}
	};

	// jsx elements..

	return (
		<>
			<Stack justifyContent="space-between">
				<Stack alignItems="center" gap={1.5} sx={{ px: 4.25, pt: 3.25 }}>
					<Typography variant="h5" fontSize="1.5rem" fontWeight={700}>
						Create Leave Requests
					</Typography>
					<Typography fontSize="1rem" fontWeight={600} color="primary.main" sx={{ cursor: "pointer" }} onClick={() => form?.reset()}>
						Clear
					</Typography>
				</Stack>

				<IconButton onClick={props.closeModal} sx={{ height: "30px", fontSize: "20px", p: 0.5, mt: 1, mr: 1 }}>
					<MdOutlineClose />
				</IconButton>
			</Stack>

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<DialogContent
						sx={{
							pt: 1.5,
							px: 4.25,
							overflow: "hidden",
							fieldset: { borderRadius: "10px" },
							".MuiFormLabel-asterisk": { display: "none" },
							label: { fontSize: 18, fontWeight: 600, mb: 0.65 },
						}}
					>
						<Grid
							container
							columns={2}
							spacing={{ xs: 3.75, sm: 2.5 }}
							sx={{
								".MuiFormLabel-root": { color: "#202422", fontSize: "18px", fontWeight: 600, mb: 0.6 },
								".MuiInputBase-root": {
									pr: 3,
									".MuiInputBase-input": { pl: 2.5 },
									fieldset: { borderRadius: "10px" },
									"svg.MuiSelect-icon": { right: 17, fontSize: "22px" },
								},
							}}
						>
							<Grid item xs={2} sm={1}>
								<DateTimePicker name="leaving_from_date" label="From" />
							</Grid>

							<Grid item xs={2} sm={1}>
								<DateTimePicker
									name="resume_date"
									label="To"
									validations={{
										validate: (resume: string) => {
											const from = form.getValues("leaving_from_date");
											if (!from) return true;
											else if (new Date(from).getTime() >= new Date(resume).getTime()) {
												return "Must be after date From";
											}
										},
									}}
								/>
							</Grid>

							<Grid item xs={2} sm={1}>
								<SelectField label="Availability" name="availability" options={["120 hr", "60 hr"]} required />
							</Grid>

							<Grid item xs={2} sm={1}>
								<SelectField label="Leave type" name="type_of_leave" options={["Monthly", "Weekly", "Daily"]} required />
							</Grid>

							{/* <Grid item xs={2} sm={1}>
								<SelectField label="-" name="notify_manager" options={[]} disabled />
							</Grid> */}

							<Grid item xs={2} sm={1}>
								<SelectField name="employee_email" label="Employee" options={employeeEmailOptions} required />
							</Grid>

							{/* <Grid item xs={2} sm={1}>
								<SelectField
									label="Notify manager"
									name="notify_manager"
									options={["Rohan Kulkarni", "Mike Wilkon"]}
									required
								/>
							</Grid> */}

							<Grid item xs={2} sm={1}>
								<Controller
									name="is_approved"
									control={form.control}
									render={(params) => (
										<FormControlLabel
											sx={{ pt: 4.8 }}
											labelPlacement="start"
											disabled={!isEmployeeSelected}
											label={`Is Approved ${isEmployeeSelected ? "" : "(Select Employee)"}`}
											control={
												<Checkbox
													{...params?.field}
													checked={params?.field?.value}
													onChange={(e) => params?.field?.onChange(e.target.checked)}
												/>
											}
										/>
									)}
								/>
							</Grid>
						</Grid>

						<Button
							type="submit"
							variant="contained"
							fullWidth
							sx={{
								minHeight: "44px",
								fontSize: { xs: "20px", sm: "18px" },
								borderRadius: "10px",
								mt: 4.5,
							}}
						>
							{props.editLeave ? "Update" : "Create"}
						</Button>
					</DialogContent>
				</form>
			</FormProvider>

			{/* loader overlay */}

			<LoadingOverlay
				open={postMutation.isLoading || props.isUpdateLoading}
				// open={isPostLoading || isUpdateLoading || isProfileLoading || isInviteLoading}
			/>
		</>
	);
};

export default LeaveRequestForm;
