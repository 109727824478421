import { Button, Typography } from "@mui/material";
type Props = {
  unScheduleShiftHandler: Function;
};
function NoShiftToday({ unScheduleShiftHandler }: Props) {
  return (
    <div>
 <Typography
  variant="h2"
  fontSize={24}
  fontWeight={700}
  mb={1.25}
  style={{ textAlign: 'center'}}
>
  No Shift Scheduled For Today
</Typography>

      <Button
        variant="contained"
        sx={{ fontWeight: 700, fontSize: 20, px: 10, py: 2 }}
        onClick={() => unScheduleShiftHandler()}
      >
        Start Unscheduled Shift
      </Button>
    </div>
  );
}
export default NoShiftToday;
