import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Stack, Typography, CircularProgress, Button, Container } from "@mui/material";
import { postEmployeeJoinCompany } from "api/employee";
import { FormInput } from "components";
import { FormProvider, useForm } from "react-hook-form";
import BuildingOffice from "assets/images/building-office.png";
import { UserContext } from "context/UserContext";

const NoAccess = () => {
	const user = useContext(UserContext);
	const form = useForm<any>({ defaultValues: {} });
	const history = useHistory();
	const { mutate, isLoading } = useMutation(postEmployeeJoinCompany, {
		onSuccess: () => {
			toast.success("Join company successfully");
			history.go(0)
		},
	});

	const submitHandler = (vals: any) => {
		const { code } = vals;
		mutate({
			code,
			employee_id: user.id,
		});
	};

	return (
		<Stack direction="column" alignItems="center" gap={2} px={{ sm: 4 }} pt={10} pb={5}>
			<Typography fontSize={{ xs: 24, sm: 24 }} maxWidth={500} fontWeight={700} align="center" lineHeight={1.35}>
				You cant Access this Page without joining your employers company on Shifti
			</Typography>
			<img alt="building-office" src={BuildingOffice} />
			<Typography fontSize={{ xs: 20, sm: 20 }} fontWeight={400} align="center" lineHeight={1.35}>
				Do You Have A Invite Code? Enter Here
			</Typography>
			<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submitHandler)}>
			<Container maxWidth="sm">
				<FormInput name="code" required="code required" inLabel="Company code" />
				<Button
					fullWidth
					type="submit"
					disabled={isLoading}
					variant="contained"
					color="primary"
					sx={{ height: 60, fontWeight: 600, fontSize: { xs: 18, sm: 20 }, mt: 3.5 }}
				>
					{isLoading ? <CircularProgress size={18} /> : "Join Company"}
				</Button>
				<Typography fontSize={{ xs: 15, sm: 15 }} align="center" marginTop={2} >
					Go Back to Dashboard
				</Typography>
			</Container>
			</form>
			</FormProvider>
		</Stack>
	)
};

export default NoAccess;
