import { CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Map, { MapRef, Marker, Popup, ViewState } from "react-map-gl";
import { Geocoder } from ".";
import "styles/maps.scss";

//

type PropTypes = {
	selectedResult: any;
	noSearchBar?: boolean;
	style?: CSSProperties;
	setSelectedResult: Dispatch<SetStateAction<any>>;
};

const MapToDisplay = ({ selectedResult, setSelectedResult, noSearchBar, style }: PropTypes) => {
	const coords = selectedResult?.geometry?.coordinates;

	const mapRef = useRef<MapRef>(null);
	const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
	const [mapView, setMapView] = useState<Partial<ViewState>>({ longitude: coords?.[0] || 151.20929, latitude: coords?.[1] || -33.86882, zoom: 16 });

	// syncing 'view coordinates' and 'selectedResult.geometry'

	useEffect(() => {
		if (coords?.length === 2 && (mapView.longitude !== coords[0] || mapView.latitude !== coords[1])) {
			setMapView((prev: any) => ({ ...prev, longitude: coords[0], latitude: coords[1] }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coords?.[0], coords?.[1]]);

	// map jsx..

	return (
		<Map
			{...mapView}
			ref={mapRef}
			onMove={(e) => setMapView(e.viewState)}
			mapStyle="mapbox://styles/mapbox/light-v11"
			mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
			style={style}
			minZoom={2}
		>
			{noSearchBar ? null : <Geocoder map={mapRef.current} selectedResult={selectedResult} setSelectedResult={setSelectedResult} />}

			{coords?.length === 2 && (
				<>
					<Marker
						anchor="bottom"
						longitude={coords[0]}
						latitude={coords[1]}
						onClick={(e) => {
							e.originalEvent.stopPropagation();
							setPopupOpen(true);
						}}
					/>

					{isPopupOpen && (
						<Popup anchor="top" longitude={coords?.[0]} latitude={coords?.[1]} onClose={() => setPopupOpen(false)}>
							<p>{selectedResult?.place_name}</p>
						</Popup>
					)}
				</>
			)}
		</Map>
	);
};

export default MapToDisplay;
