import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import { HiClock } from "react-icons/hi";
import { BsWallet } from "react-icons/bs";
import { Availability, LeaveRequest, Shift } from "types";
import { timeWithDayjs } from "utils/data-helpers";
import {
  getStatusColor,
  getShiftStatus,
  getLeaveStatus,
  getCalenderLeaveStatusTextColor,
  getCalenderLeaveStatusBackgroundColor,
  getCalenderShiftStatusBackgroundColor,
  getCalenderShiftStatusTextColor,
  getAvailabilityStatus,
} from "utils/records-helpers";
import { BlackClockIcon, GreenTickMarkIcon, GreyPlusIcon } from "assets/svg";
import StatusBtn from "components/StatusBtn";

//
type PropTypes = {
  shift?: Shift | any;
  leave?: LeaveRequest;
  availability?: Availability;
  wrapperProps?: any;
};
const HorizontalScheduleCard = ({
  shift,
  leave,
  availability,
  ...props
}: PropTypes) => {
  // console.log("leave", leave);
  // const status = shift
  //   ? getShiftStatus(shift)
  //   : leave
  //   ? getLeaveStatus(leave)
  //   : null;
  const status = shift
    ? getShiftStatus(shift)
    : leave
    ? getLeaveStatus(leave)
    : availability
    ? getAvailabilityStatus(availability)
    : null;
  // console.log("status", status);
  const statusColor = status ? getStatusColor(status) : null;
  // console.log("statusColor", statusColor);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box
        {...props.wrapperProps}
        minHeight="47px"
        borderRadius={{ xs: "8px", sm: "8px" }}
        borderLeft={`2px solid ${getCalenderLeaveStatusTextColor("sickLeave")}`}
        sx={{
          bgcolor: "#ffffff",
          px: 1,
          py: 1,
          width: "100%",
          height: "fit-content",
          borderTop: "1px solid #E5E5E5",
          borderBottom: "1px solid #E5E5E5",
          borderRight: "1px solid #E5E5E5",
          cursor: "pointer",
        }}
        paddingTop={"4px"}
        onDragStart={(ev: any) =>
          ev.dataTransfer.setData("schedule-event-id", shift?.id || leave?.id)
        }
        draggable
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            mb: "4px",
          }}
        >
          {/* <Typography
            sx={{
              width: "fit-content",
              padding: "6px 8px",
              color: shift
                ? getCalenderShiftStatusTextColor("pending")
                : getCalenderLeaveStatusTextColor("sickLeave"),
              backgroundColor: shift
                ? getCalenderShiftStatusBackgroundColor("pending")
                : getCalenderLeaveStatusBackgroundColor("sickLeave"),
              borderRadius: "20px",
              fontSize: "10px",
              fontWeight: 500,
              lineHeight: "12.3px",
            }}
          >
            Sick Leave
          </Typography> */}
          {status && (
            <StatusBtn
              status={status}
              sx={{ width: "fit-content", fontSize: 10 }}
            />
          )}
          {/* <GreenTickMarkIcon /> */}
          {/* <Typography
              color="#25333466"
              fontSize={13}
              fontWeight={900}
              lineHeight="11px"
            >
              {props.leave ? "Leave(Requested)" : status}
            </Typography> */}
        </Box>
        {shift && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "14px",
              mb: "4px",
            }}
          >
            {shift?.title && (
              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight="14px"
                color={"#202422"}
                mb={"0px"}
              >
                {shift?.title}
              </Typography>
            )}
            <Box justifyContent="space-between" gap={1} height={"14px"}>
              {shift && (
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsWallet size={15} color="green" />
                  <Box
                    sx={{
                      ml: 0.5,
                    }}
                  >
                    <Typography
                      fontSize={10}
                      lineHeight="11px"
                      fontWeight="400"
                      mb={"0px"}
                    >
                      ${shift?.total_price}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {shift && (
          <Typography
            color={"#6C6E6D"}
            fontSize={12}
            fontWeight={600}
            lineHeight="16.4px"
            mb={"5px"}
          >
            {shift?.location.substring(0, 20).concat("...")}
          </Typography>
        )}
        {/* {props.shift?.location && (
            <Typography
              color={"#6C6E6D"}
              fontSize={12}
              fontWeight={600}
              lineHeight="16.4px"
              mb={"10px"}
            >
              {props.shift?.location.substring(0, 20).concat("...")}
            </Typography>
          )} */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: "5px",
          }}
        >
          <BlackClockIcon />
          <Box sx={{ svg: { height: 40, color: "#3F41494D" }, ml: 1 }}>
            {shift ? (
              <Typography
                color="#253334E5"
                fontSize={12}
                fontWeight={400}
                lineHeight="12.3px"
              >
                {timeWithDayjs(shift.target_date+" "+shift?.starting_time).format("hh:ss a")}
                {" - "}
                {timeWithDayjs(shift.target_date+" "+shift?.ending_time).format("hh:ss a")}
              </Typography>
            ) : leave ? (
              <Typography
                color="#253334E5"
                fontSize={12}
                fontWeight={400}
                lineHeight="12.3px"
              >
                {dayjs(leave?.leaving_from_date).format("D MMM")}
                {" - "}
                {dayjs(leave?.resume_date).format("D MMM")}
              </Typography>
            ) : availability ? (
              // Assuming 'availability' is a variable holding your availability data
              <Typography
                color="#253334E5"
                fontSize={12}
                fontWeight={400}
                lineHeight="12.3px"
              >
                {/* Render your availability data here */}
                {`${availability.starting_ending_time}`}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "22px",
          height: "22px",
          backgroundColor: "#f6f6f6",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
        }}
      >
        <GreyPlusIcon />
      </Box>
    </Box>
  );
};
export default HorizontalScheduleCard;
