import { Dialog, DialogProps, Typography, Box, Button } from "@mui/material";

//

type PropTypes = DialogProps & { publishHander: VoidFunction };

const UnsavedChangesModal = ({ publishHander, ...props }: PropTypes) => (
	<Dialog {...props} fullWidth sx={{ ".MuiPaper-root": { width: 416, borderRadius: "19.4px", px: 2.5, pt: 2.5, pb: 3.75 } }}>
		<Box maxWidth={300} width="100%" mx="auto" textAlign="center">
			<Typography variant="h2" fontSize={24} fontWeight={700}>
				Publish Changes
			</Typography>

			<Typography fontSize={18} fontWeight={600} mt={1.75}>
				You havent Published your recent Changes to avoid losing your progress please click publish now or discard
			</Typography>

			<Button fullWidth variant="contained" onClick={publishHander} sx={{ height: 60, fontSize: 20, fontWeight: 600, borderRadius: "10px", mt: 1.75 }}>
				Publish Changes Now
			</Button>

			<Button
				fullWidth
				color="error"
				variant="text"
				onClick={(ev) => props.onClose && props.onClose(ev, "backdropClick")}
				sx={{ height: 30, fontSize: 14, fontWeight: 600, textDecoration: "underline", mt: 1 }}
			>
				Discard Changes
			</Button>
		</Box>
	</Dialog>
);

export default UnsavedChangesModal;
