import { Button } from "@mui/material";
export interface SimpleDialogProps {
  endShiftHandler: Function;
}
const EndShift = (props: SimpleDialogProps) => {
  const endShiftHandler = () => {
    props.endShiftHandler();
  };
  return (
    <Button
      variant="contained"
      color="error"
      sx={{
        fontWeight: 600,
        fontSize: { xs: 13, sm: 14, md: 16, lg: 18, xl: 20 },
        px: { xs: 0.5, sm: 1, md: 2, lg: 3, xl: 4 },
        py: { xs: 1.5, xl: 1.5 },
        color: "#FFF",
        backgroundColor:'#EF4444',
        width:'169px',
        height: '52px',
        borderRadius:'8px',
        boxShadow: 'none' // Add this line to ensure no box sha
      }}
      onClick={endShiftHandler}
    >
      End Shift
    </Button>
  );
};
export default EndShift;
