import { CompanyEmployees, CompanyUpdate } from "types";
import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";

//

type CompanyEmployeesParams = { cid: string; uid?: string; extraProfileProps?: string };
type Manager = { profiles: { id: string; first_name: string; last_name: string; avatar_url: string } };

export const getCompanyEmployees = async ({ cid, uid, extraProfileProps = "" }: CompanyEmployeesParams): Promise<CompanyEmployees[]> => {
    const extraColumns = extraProfileProps ? `, ${extraProfileProps}` : "";
    // console.log("jj",cid, uid);
    let query = supabase
        .from("employees")
        .select(`role, profile:employee_id(id, email, first_name, last_name, avatar_url${extraColumns}), company:company_id(id,logo_url)`)
        .match({ company_id: cid });

    // console.log("query",query);
    return await reqHandler(() => query);
};


export const getCompanyManagers = async (company_id: string): Promise<Manager[]> => {
	return await reqHandler(() =>
		supabase.from("employees").select("profiles(id, email, first_name, last_name, avatar_url)").match({ company_id, role: "admin" })
	);
};

export const getCompany = async ({ id, select = "*" }: { id: string; select?: string }) => {
    // Ensure that the "name" column is included in the select parameter
    const updatedSelect = select === "*" ? select : `${select}, name`;

    return await reqHandler(() => supabase.from("companies").select(updatedSelect).match({ id }).single(), { disableToast: true });
};

export const updateCompany = async ({ id, props }: { id: string; props: CompanyUpdate }) => {
	return await reqHandler(() => supabase.from("companies").update(props).match({ id }));
};

export const getCompanyByInvite = async (invite_code: string) => {
  try {
    return await reqHandler(() => supabase.from("companies").select("id").match({ invite_code }).single(), { disableToast: true });
  } catch (error) {
    console.error("Error in getCompanyByInvite:", error);
    throw error;
  }
};

export const getNewCompanyInviteCode = async () => {
	return await reqHandler(() => supabase.rpc("generate_invite_code"), { disableToast: true });
};

export const getCompanyIdByUserId = async (uid: string) => {
	try {
	  return await reqHandler(() => supabase.from("employees").select("company_id").match({ employee_id: uid }).single());
	} catch (error) {
	  console.error("Error in getCompanyIdByUserId:", error);
	  throw error;
	}
  };
