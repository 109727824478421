/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { UseFormReturn } from "react-hook-form";
import {
	Stack,
	Typography,
	Box
} from "@mui/material";
import { v4 as uuid } from 'uuid';
import { size } from "lodash";
import { Close } from "@mui/icons-material";

// handson table
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';

import { UserContext } from "context/UserContext";
import { LeaveRequest } from "types";
import { postAvailability, getEmployeeAvailability, updateAvailability } from "api/leave-request";
import supabase from "supabase/supabaseClient";

import { LoadingOverlay } from "components";

// scss file for customizing availability table
import '../../styles/availability.scss';
import { toast } from "react-toastify";


// registers the all necessary modules of handsontable
registerAllModules();

type PropTypes = { form: UseFormReturn<LeaveRequest> };

type TableHeaderType = {
	[index: string | number]: string
}
type MergedCellType = { row: number, col: number, rowspan: number, colspan: number, availability_status?: string }


type MergedCellInfoType = {
	mergedCells: MergedCellType[],
	newlyMergedCell: Record<string, never>,
	time: string,
	status: string,
	dayOfWeek: string
}
const Availability = ({ form }: PropTypes) => {
	const user = useContext(UserContext);
	const [showSelectedAvailabilityModal, setShowSelectedAvailabiltyModal] = useState<boolean>(false);
	const availableBtnRef = useRef<HTMLButtonElement>(null);
	const unAvailableBtnRef = useRef<HTMLButtonElement>(null);
	const hotRef = useRef<any>(null)!;
	const [mergedCellsInfo, setMergedCellsInfo] = useState<MergedCellInfoType>({
		mergedCells: [],
		newlyMergedCell: {},
		time: '',
		status: 'Available',
		dayOfWeek: ''
	});
	
	const [selectedClearAbleCell, setSelectedClearAbleCell] = useState<any>({});
	const [isDisable, setIsDisable] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState(false);

	const [tableColumnWidth, setTableColumnWidth] = useState<number>(0);
	const [rowCol] = useState<string[][]>([
		['1 AM', '', '', '', '', '', '', ''],
		['2 AM', '', '', '', '', '', '', ''],
		['3 AM', '', '', '', '', '', '', ''],
		['4 AM', '', '', '', '', '', '', ''],
		['5 AM', '', '', '', '', '', '', ''],
		['6 AM', '', '', '', '', '', '', ''],
		['7 AM', '', '', '', '', '', '', ''],
		['8 AM', '', '', '', '', '', '', ''],
		['9 AM', '', '', '', '', '', '', ''],
		['10 AM', '', '', '', '', '', '', ''],
		['11 AM', '', '', '', '', '', '', ''],
		['12 PM', '', '', '', '', '', '', ''],
		['1 PM', '', '', '', '', '', '', ''],
		['2 PM', '', '', '', '', '', '', ''],
		['3 PM', '', '', '', '', '', '', ''],
		['4 PM', '', '', '', '', '', '', ''],
		['5 PM', '', '', '', '', '', '', ''],
		['6 PM', '', '', '', '', '', '', ''],
		['7 PM', '', '', '', '', '', '', ''],
		['8 PM', '', '', '', '', '', '', ''],
		['9 PM', '', '', '', '', '', '', ''],
		['10 PM', '', '', '', '', '', '', ''],
		['11 PM', '', '', '', '', '', '', ''],
		['12 AM', '', '', '', '', '', '', ''],
	]);


	const tableHeader: TableHeaderType = {
		0: 'Time/hr',
		1: 'Monday',
		2: 'Tuesday',
		3: 'Wednesday',
		4: 'Thursday',
		5: 'Friday',
		6: 'Saturday',
		7: 'Sunday',
	}

	const getAvailabilityUnavailability = (item: any): string => {
		const selectedDayTimeContent = `
		<div style="display: flex;position: relative;height: 20px; justify-content: center">
		<div style="position: relative; margin-top: -19px; margin-left: -40px">
		<svg width="17" height="18" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5074_273)"><path d="M3.24588 7.24592C1.45304 7.24592 0 5.79288 0 4.00003C0 2.20719 1.45304 0.75415 3.24588 0.75415C5.03873 0.75415 6.49177 2.20719 6.49177 4.00003C6.49177 5.79288 5.03873 7.24592 3.24588 7.24592ZM2.94158 2.27566V4.00003C2.94158 4.10147 2.9923 4.19656 3.07725 4.25362L4.29446 5.06509C4.43393 5.15892 4.62285 5.12088 4.71668 4.98014C4.8105 4.8394 4.77246 4.65175 4.63172 4.55792L3.55019 3.83774V2.27566C3.55019 2.10702 3.41452 1.97136 3.24588 1.97136C3.07725 1.97136 2.94158 2.10702 2.94158 2.27566Z" fill="#FFFFFF"/>
		</g>
		<defs>
		<clipPath id="clip0_5074_273">
		<rect width="6.49177" height="6.49177" fill="white" transform="translate(0 0.75415)"/>
		</clipPath>
		</defs>
		</svg>
		</div>
		 <span>${item?.starting_ending_time || item?.time || ''}</span>
		 </div>
		 <div style="position: relative;  margin-top: -40px; text-align: center; height: 20px;">
		 <h3 id="status" style="text-align: center; position: absolute; top: 10px;left: 52%; transform: translate(-50%, -50%)">${item?.availability_status || item?.status || ''}</h3>
		 </div>
		 `
		return selectedDayTimeContent;
	}

	const customRenderer = (instance: any, TD: any) => {
		// Access the desired element by its ID or other selectors
		if (TD) {
			const myDiv = TD.querySelector('#status'); // Example: Access by ID
			TD.style.background = myDiv?.textContent === 'Available' ? '#00B25D' : '#E50000';
			TD.style.color = 'white'
		}
	}


	// getting saved availability/unavailability status
	const fetchAvailabilityInfo = async () => {
		try {
			const data = await getEmployeeAvailability({ employee_id: user.id });
			const preparedData = data?.map((item) => {
			const { col, colspan, row, rowspan, id, availability_status, day_of_week, starting_ending_time } = item;
			const newValue = getAvailabilityUnavailability({ ...item });
			rowCol[row][col] = newValue;
			return { id, row, col, rowspan, colspan, availability_status, day_of_week, starting_ending_time, renderer: customRenderer };
			})
			setMergedCellsInfo((prevData: any) => {
				return { ...prevData, mergedCells: [...preparedData,] };
			})
		} catch (error) {
			console.log(error)
		}
		setIsDisable(false);
		setIsLoading(false);
		setShowSelectedAvailabiltyModal(false);
		setMergedCellsInfo((prevData: any) => ({ ...prevData, status: 'Available' }))
	}



	const handleAfterSelectionEnd = (startingRow: number, startingCol: number, endingRow: number, endingCol: number) => {
		const isSelectedCellMerged = mergedCellsInfo?.mergedCells?.filter((item) => item?.row === startingRow && item?.col === startingCol);
		const hasAnyMergedColumn = mergedCellsInfo?.mergedCells?.filter((item) => item?.col === startingCol);

		if ((startingCol === endingCol && startingCol > 0 && !(startingRow > -1 && size(hasAnyMergedColumn) && size(isSelectedCellMerged) ))) {
			const newlyMergedCell = { row: startingRow === -1 ? 0 : startingRow, col: startingCol, rowspan: endingRow - (startingRow === -1 ? 0 : startingRow) + 1, colspan: 1, id: uuid().slice(0, 8) };
			const startingPeriod = startingRow >= 11 ? 'pm' : 'am';
			const startingTime = startingRow >= 12 ? `${startingRow - 11}:00${startingPeriod}` : `${(startingRow === -1 ? 0 : startingRow) + 1}:00${startingPeriod}`;

			const endingPeriod = endingRow >= 11 && endingRow < 23 ? 'pm' : 'am';
			const endingTime = endingRow > 11 ? `${endingRow - 11}:00${endingPeriod}` : `${endingRow + 1}:00${endingPeriod}`

			const dayOfWeek = tableHeader[startingCol]
			const time = startingRow >= 12 ? `${startingTime?.length <= 1 ? '0' : ''}${startingTime} - ${endingTime?.length <= 1 ? '0' : ''}${endingTime}` : `${startingTime?.length <= 1 ? '0' : ''}${startingTime} - ${endingTime?.length <= 1 ? '0' : ''}${endingTime}`

			setMergedCellsInfo((prevData: any) => ({ ...prevData, mergedCells: [...prevData?.mergedCells, { ...newlyMergedCell }], newlyMergedCell, time, dayOfWeek }))
			setSelectedClearAbleCell({ ...newlyMergedCell })

			// if (((!size(hasAnyMergedColumn) || !size(isSelectedCellMerged)) && (!size(isSelectedCellMerged) && startingRow > -1)) || (startingRow < 0 && !size(hasAnyMergedColumn) && !size(isSelectedCellMerged))) {
			// 	setIsDisable(false);
			// }else {
			// 	setIsDisable(true);
			// }
			
		} else {
			setSelectedClearAbleCell({...mergedCellsInfo?.mergedCells?.find(item => item?.row === startingRow && item?.col === startingCol), content: true} || {});
			// setIsDisable(true);
		}

		if (startingCol === 0 || startingCol !== endingCol) {
			const hot = hotRef.current.hotInstance;
			hot.deselectCell();
		}


		if (startingCol > 0 && startingCol === endingCol) {
			setShowSelectedAvailabiltyModal(true);
		}
	}

	const hanldeDeletingRecords = async (targetCol: string, filterValue: string | number) => {

		const { error } = await supabase
			.from('availability')
			.delete()
			.eq(targetCol, filterValue);
		if (!error) {
			fetchAvailabilityInfo();
			setShowSelectedAvailabiltyModal(false);
		}
		return error;
	}


	const clearMergedCell = () => {
		if (size(selectedClearAbleCell)) {
			const newValue = '';
			// removing selected availability/unavaility inside the selected/merged cells
			rowCol[selectedClearAbleCell?.row][selectedClearAbleCell?.col] = newValue;

			setMergedCellsInfo((prevData: any) => {
				return { ...prevData, mergedCells: [...prevData?.mergedCells?.filter((item: any) => item?.id !== selectedClearAbleCell?.id)] };
			});
			if (selectedClearAbleCell?.rowspan === 24) {
				hanldeDeletingRecords('day_of_week', tableHeader[selectedClearAbleCell?.col]);
			}else {
				hanldeDeletingRecords('id', selectedClearAbleCell?.id);
			}
		}
		
		setShowSelectedAvailabiltyModal(false);
		setMergedCellsInfo((prevData: any) => ({ ...prevData, status: 'Available' }))
	}

	// handle status of availability/unavailability based on user selection
	const handleAvailableUnavailableStatus = (e: any, status: string): any => {
		e?.target.classList.add('active');
		setMergedCellsInfo((prevData: any) => ({ ...prevData, status: status }));
		if (size(selectedClearAbleCell) && selectedClearAbleCell?.content) {
			setSelectedClearAbleCell((prevData: any) => ({...prevData, availability_status: status}))
		}
		if (status === 'Available') {
			unAvailableBtnRef.current?.classList.remove('active')
		} else {
			availableBtnRef.current?.classList.remove('active')
		}
	}

	const clearEmptyMergedCell = () => {
		const hot = hotRef?.current?.hotInstance;
		const isCurrentCellEmpty = hot?.getCell(mergedCellsInfo?.newlyMergedCell?.row, mergedCellsInfo?.newlyMergedCell?.col)?.innerHTML || '';

		if (!isCurrentCellEmpty && !selectedClearAbleCell?.content) {
			clearMergedCell()
			setMergedCellsInfo((prevData: any) => ({ ...prevData, status: 'Available' }))
		}
	}

	const handleModalClose = () => {
		clearEmptyMergedCell();
		setShowSelectedAvailabiltyModal(false);
		setMergedCellsInfo((prevData: any) => ({ ...prevData, status: 'Available' }))
	}

	const handleCloseModalOnOutsideClick = (e: any) => {
		if (e.target === e.currentTarget) {
			handleModalClose();
		}
	}

	const postMutation = useMutation(postAvailability, {
		onSuccess: () => {
			fetchAvailabilityInfo();
			toast("Availability status has been added successfully!")
		},
	});

	const updateMutation = useMutation(updateAvailability, {
		onSuccess: () => {
			setMergedCellsInfo((prevData: any) => ({...prevData, mergedCells: []}))
			fetchAvailabilityInfo();
			toast("Availability status has been updated successfully!")
		}
	})

	const handleUpdatingAvailabilityInfo = (object: any) => {
		if (size(object) && object?.content) {
			setIsDisable(true);
			updateMutation.mutate(object);
		}
	}

	const updateMergedData = () => {
		setIsDisable(true);
		// mutations........
		const valueToSend = {
			day_of_week: mergedCellsInfo?.dayOfWeek,
			starting_ending_time: mergedCellsInfo?.time,
			employee_id: user?.id,
			availability_status: mergedCellsInfo?.status,
			row: mergedCellsInfo?.newlyMergedCell?.row,
			col: mergedCellsInfo?.newlyMergedCell?.col,
			rowspan: mergedCellsInfo?.newlyMergedCell?.rowspan,
			colspan: mergedCellsInfo?.newlyMergedCell?.colspan,
		}
		postMutation.mutate(valueToSend);
	}


	useEffect(() => {
		const hot = hotRef.current.hotInstance;
		hot.updateSettings({
			mergeCells: mergedCellsInfo?.mergedCells
		});
	});


	useEffect(() => {
		setIsLoading(true);
		const availabilityTable = document.querySelector('.handsontable') as HTMLElement;
		availabilityTable.style.backgroundColor = 'white';
		availabilityTable.style.minWidth = '1400px';
		const tableDiv = document.querySelector('.handsontable')?.clientWidth as number;
		setTableColumnWidth(tableDiv / 8);

		window.addEventListener('resize', ()=> {
			setIsLoading(true);
			const availabilityTable = document.querySelector('.handsontable') as HTMLElement;
			availabilityTable.style.backgroundColor = 'white';
			availabilityTable.style.minWidth = '1400px';
			const tableDiv = document.querySelector('.handsontable')?.clientWidth as number;
			setTableColumnWidth(tableDiv / 8);
			fetchAvailabilityInfo();
		})

		fetchAvailabilityInfo();
	}, [])

	

	// jsx..
	return (
		<>
			<div style={{ zIndex: '234345345345', position: 'absolute' }}>
				<LoadingOverlay open={isLoading} />
			</div>

			{
				showSelectedAvailabilityModal ? (
					<div className="modal-backdrop" onClick={handleCloseModalOnOutsideClick}>
						<div className="selected-availability-modal">
							<form action="">
								<div className="buttons">
									{
										size(selectedClearAbleCell) && selectedClearAbleCell?.content ? (
											<>
												<button type="button" ref={availableBtnRef} onClick={(e) => handleAvailableUnavailableStatus(e, 'Available')} className={selectedClearAbleCell.availability_status === 'Available' ? 'active' : ''}>Available</button>
												<button type="button" ref={unAvailableBtnRef} onClick={(e) => handleAvailableUnavailableStatus(e, 'Unavailable')} className={selectedClearAbleCell.availability_status !== 'Available' ? 'active' : ''}>Unavailable</button>
											</>
										) : (
											<>
												<button type="button" ref={availableBtnRef} onClick={(e) => handleAvailableUnavailableStatus(e, 'Available')} className="active">Available</button>
												<button type="button" ref={unAvailableBtnRef} onClick={(e) => handleAvailableUnavailableStatus(e, 'Unavailable')} className="">Unavailable</button>
											</>
										)
									}
								</div>
								<div className="selected-day-time">
									<span className="day">{selectedClearAbleCell?.day_of_week || mergedCellsInfo?.dayOfWeek || 'Monday'}</span>
									<span className="time">{selectedClearAbleCell?.starting_ending_time || mergedCellsInfo?.time || ''}</span>
								</div>
								<div className="save-btn">
									{
										size(selectedClearAbleCell) && selectedClearAbleCell?.content ? (
									<button type="button" onClick={() => handleUpdatingAvailabilityInfo(selectedClearAbleCell)} disabled={isDisable}>Update</button>
										) : (
									<button type="button" onClick={() => updateMergedData()} disabled={isDisable}>Save</button>
										)
									}
								</div>
								<div className="clear-btn">
									<button type="button" onClick={() => clearMergedCell()} disabled={!size(selectedClearAbleCell)}>Clear</button>
								</div>
							</form>
							<button type="button" className="close-btn" onClick={handleModalClose}>
								<Close className="icon" />
							</button>
						</div>
					</div>
				) : ""
			}
			<Box mt={{ xs: 5, sm: 2.5 }}>
				<Typography variant="h1" fontSize={24} fontWeight={700}>
					Availability
				</Typography>
				{/* <Stack alignItems="center" justifyContent={{ sm: "flex-end" }} gap={1}> */}
				<Stack flex={1} alignItems="center" justifyContent="space-between" gap={2} mb={1}>
					<Typography fontSize={24} fontWeight={700}>
						<Box component="span" display={{ sm: "none" }}>
							Schedule
						</Box>
					</Typography>
				</Stack>
			</Box>


			<div id="availability-table">
				<div className="table-content">
					<HotTable
						ref={hotRef}
						data={rowCol}
						width="100%"
						height="auto"
						afterSelectionEnd={(startingRow, startingCol, endingRow, endingCol) => handleAfterSelectionEnd(startingRow, startingCol, endingRow, endingCol)}
						colHeaders={(index) => {
							return tableHeader[index];
						}}
						rowHeaders={false}
						rowHeights={40}
						className="no-border-table"
						tableClassName="availability-table"
						cell={mergedCellsInfo?.mergedCells?.length ? [
							...mergedCellsInfo?.mergedCells
						] : []}
						columns={[
							{ renderer: 'html' },
							{ renderer: 'html' },
							{ renderer: 'html' },
							{ renderer: 'html' },
							{ renderer: 'html' },
							{ renderer: 'html' },
							{ renderer: 'html' },
							{ renderer: 'html' }
						]}
						colWidths={[tableColumnWidth, tableColumnWidth, tableColumnWidth, tableColumnWidth, tableColumnWidth, tableColumnWidth, tableColumnWidth, tableColumnWidth]}
						licenseKey='non-commercial-and-evaluation'
					/>
				</div>
				<hr />
			</div>

		</>

	);
};

export default Availability;



