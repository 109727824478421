import { PremiumPlanType, SubscriptionInsert } from "types";
import supabase from "supabase/supabaseClient";
import { reqHandler } from "utils/api-helpers";

//

export const fetchSubscription = async (
  company_id: string,
  select?: string
): Promise<{ title: PremiumPlanType; price: number; member_count: number }> => {
  select = select || "title, price, member_count";
  return await reqHandler(() =>
    supabase
      .from("subscriptions")
      .select(select)
      .match({ company_id })
      .is("cancelled_at", null)
      .single()
  );
};

export const createSubscription = async (row: SubscriptionInsert) =>
  await reqHandler(() => supabase.from("subscriptions").insert(row));
