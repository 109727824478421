import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
import { FormFieldCommonProps } from "types";

//

interface SelectFieldProps extends FormFieldCommonProps {
  options: string[] | number[] | object[];
  required?: string | boolean;
  disabled?: boolean;
  value?: any;
}

//

const SelectField = ({
  inputSx,
  label,
  labelSx,
  options,
  required,
  validations,
  value,
  ...props
}: SelectFieldProps) => {
  const form = useFormContext();

  return (
    <Controller
      {...props}
      control={form.control}
      rules={{ required, ...validations }}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl
            sx={{ "label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" } }}
          >
            <FormLabel sx={labelSx} required={!!required}>
              {label}
            </FormLabel>

            <Select
              {...field}
              value={field?.value ?? ""}
              IconComponent={FaAngleDown}
              sx={{
                "&.MuiInputBase-root svg.MuiSelect-icon": {
                  fontSize: "20px",
                  right: "21px",
                },
                ...inputSx,
              }}
              error={!!error}
            >
              {field?.value && (
                <MenuItem
                  value={""}
                  sx={{ opacity: 0.8, fontSize: 13, height: 25 }}
                >
                  Remove Selection
                </MenuItem>
              )}

              {options?.length > 0 ? (
                options?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option?.value || option}>
                    {typeof option === "object" ? option?.label : option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Nothing here..</MenuItem>
              )}
            </Select>

            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default SelectField;
