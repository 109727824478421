import { Box, IconButton, Stack, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import { SessionUser } from "types";

//

export const BadgeBtn = (props: { name: string; onRemove?: VoidFunction }) => (
	<Stack
		justifyContent="center"
		alignItems="center"
		gap="8px"
		py="7px"
		pl="15px"
		pr="13px"
		bgcolor="#EEEFF4"
		borderRadius="3px"
		sx={{ transition: "transform 200ms", ":hover": { transform: "scale(1.04)" } }}
	>
		<Typography component="span" fontSize={12} sx={{ userSelect: "none" }}>
			{props.name}
		</Typography>

		{props.onRemove && (
			<IconButton sx={{ fontSize: 12, p: "2px" }} onClick={props.onRemove}>
				<MdClose />
			</IconButton>
		)}
	</Stack>
);

const LanguagesAndSkills = ({ user }: { user: SessionUser }) => (
	<Stack flexWrap="wrap" rowGap={3.5} columnGap={2} bgcolor="white" px={3} pt={3.75} pb={5} borderRadius="10px">
		{["Languages", "Skills"].map((item, i) => {
			const badgesList = user?.[i === 0 ? "languages_list" : "skills_list"];
			//
			return (
				<Box key={i} flex={1} minWidth={225}>
					<Typography variant="h3" color="#393C53" fontSize="14px" fontWeight={700}>
						{item}
					</Typography>

					<Stack flexWrap="wrap" gap="5px" mt={1.75}>
						{badgesList && badgesList?.length > 0 ? (
							badgesList?.map((val: string, i: number) => <BadgeBtn key={i} name={val} />)
						) : (
							<Typography component="span" fontSize={14} color="#b0d3d6">
								Add some of your {item} here...
							</Typography>
						)}
					</Stack>
				</Box>
			);
		})}
	</Stack>
);

export default LanguagesAndSkills;
