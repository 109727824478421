import { Box, Button, Stack, Typography } from "@mui/material";
import { ApplePayLogo, GooglePayLogo, PaypalLogo, StripeLogo } from "assets/svg";

//

const BtnSx = { width: 84, height: 58, borderRadius: "4px", border: "1px solid", borderColor: "primary.main", boxShadow: "0 4px 4px 0 #00000040" };

const PaymentMethodStep = () => (
	<div>
		<Stack justifyContent="center" gap={1.5}>
			<Button onClick={() => {}} sx={BtnSx}>
				<PaypalLogo />
			</Button>
			<Button onClick={() => {}} sx={BtnSx}>
				<ApplePayLogo />
			</Button>
			<Button onClick={() => {}} sx={BtnSx}>
				<GooglePayLogo />
			</Button>
		</Stack>

		<Stack alignItems="center" gap={1.25} mt={4.5}>
			<Box flex={1} borderTop="2px solid #818393" />
			<Typography>or Pay With Card</Typography>
			<Box flex={1} borderTop="2px solid #818393" />
		</Stack>

		<Stack alignItems="center" gap="5px" width="fit-content" mx="auto" color="white" bgcolor="#9fa2b5" borderRadius="5px" px="7px" mt={1.5}>
			<Typography variant="caption" fontWeight={600} letterSpacing="1px">
				{" "}
				Powered by
			</Typography>
			<StripeLogo />
		</Stack>

		{/* <LinkPaymentMethod /> */}
	</div>
);

export default PaymentMethodStep;
