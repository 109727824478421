import { toast } from "react-toastify";

//

export const errorToast = (err: any) => {
  toast.error(
    typeof err === "string" ? err : err?.error_description || err?.message
  );
};

export const reqHandler = async (
  query: Function,
  options?: {
    returnProp?: string;
    errMsg?: string;
    disableToast?: boolean;
    dontThrowErr?: boolean;
  }
) => {
  const response = await query();

  if (response?.error) {
    if (options?.dontThrowErr) return null;
    else if (options?.disableToast) throw response.error;
    else errorToast(options?.errMsg || response.error);
  } else return response?.[options?.returnProp || "data"];
};

export const getUpdateValues = (prev: any, cur: any) => {
  const result: any = {};

  Object.entries(cur)?.forEach(([key, value]) => {
    if (value !== prev?.[key]) {
      result[key] = value;
    }
  });

  return result;
};
