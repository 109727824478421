import { toast } from "react-toastify";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import supabase from "supabase/supabaseClient";
import { Break } from "types";
import { v4 as uuidv4 } from "uuid";

//

const saveAccounts = (val: object[]) =>
  localStorage.setItem("accounts-list", JSON.stringify(val));

export const getPrevAccounts = () =>
  JSON.parse(localStorage.getItem("accounts-list") || "[]");

export const addAccountToList = (
  uid: string,
  access_token: string,
  refresh_token?: string
) => {
  const prevAccounts = getPrevAccounts();
  const alreadyAdded = prevAccounts.some((acc: any) => acc?.id === uid);

  if (!alreadyAdded) {
    const currentAccount = { id: uid, access_token, refresh_token };

    if (prevAccounts?.length === 0) saveAccounts([currentAccount]);
    else saveAccounts([...prevAccounts, currentAccount]);
  }
};

export const removeAccountFromList = (uid: string) => {
  const prevAccounts = getPrevAccounts();
  const indexToRemove = prevAccounts.findIndex((acc: any) => acc?.id === uid);

  if (indexToRemove !== -1) {
    prevAccounts.splice(indexToRemove, 1);
    saveAccounts(prevAccounts);
  }
};

export const switchAccountInOneClick = (
  clickedAccID: string,
  navigateToSignin: VoidFunction
) => {
  const prevAccounts = getPrevAccounts();
  const match = prevAccounts?.find(
    (prevAcc: any) => prevAcc?.id === clickedAccID
  );

  if (match?.access_token) {
    const { access_token, refresh_token } = match || {};
    supabase.auth.setSession({ access_token, refresh_token })?.then((res) => {
      // catch method wasn't working for some reason..
      if (res?.error?.status === 400) {
        toast.error(
          "This particular session has expired. You must re-login with your credentials."
        );
        removeAccountFromList(clickedAccID);
        navigateToSignin();
      }
    });
  }
};

export const formatTimeStr = (time: string): string => {
  const dateString = new Date().toDateString();
  return new Date(`${dateString} ${time}`).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const datesToString = (obj: any, dateProps: string[]) => {
  let result: any = {};
  dateProps.forEach((prop) => {
    result[prop] = dayjs(obj?.[prop])?.format("YYYY-MM-DD");
  });
  return result;
};

export const refinePayload = (params: { vals: any; dateProps?: string[] }) => {
  let result: any = { ...params?.vals };

  // transforming date objects to string...
  if (params?.dateProps) {
    params.dateProps.forEach((prop) => {
      if (dayjs(result?.[prop]).isValid()) {
        result[prop] = dayjs(result?.[prop]).format("YYYY-MM-DD");
      }
    });
  }

  // trimming string values
  Object.entries(result)?.forEach(([key, value]) => {
    if (typeof value === "string") result[key] = value?.trim();
  });

  return result;
};

export const nullifyEmptyStrings = (obj: any) => {
  Object.keys(obj)?.forEach((key) => {
    if (obj[key] === "") obj[key] = null;
  });

  return obj;
};

export const formatDate = (
  date: string | undefined | null,
  style?: "medium" | "full" | "long" | "short" | undefined
) => {
  return date
    ? new Date(date).toLocaleDateString("en-US", {
        dateStyle: style || "medium",
      })
    : "";
};

export const inDateRange = (date: number, type: OpUnitType) => {
  const rangeStart = Number(dayjs().startOf(type));
  const rangeEnd = Number(dayjs().endOf(type));

  return date >= rangeStart && date <= rangeEnd;
};

export const timeWithDayjs = (time: string): Dayjs => {
  return dayjs(time).isValid()
    ? dayjs(time)
    : dayjs(`${new Date().toLocaleDateString()} ${time}`);
};

export const formatStr = (str: any): string => {
  if (typeof str !== "string") str = (str || "")?.toString();
  return str.trim().toLowerCase();
};

export const isTermIncluded = (
  textToSearch: string | null,
  searchTerm: string
) => {
  if (!textToSearch) return true;
  return formatStr(textToSearch)?.includes(formatStr(searchTerm));
};

export const getFullName = (user: any) =>
  `${user?.first_name || ""} ${user?.last_name || ""}`?.trim() || null;

export const twoDatePercentage = (
  startShiftDate: string,
  endShiftDate: string
): number => {
  let countDownDate = new Date(endShiftDate).getTime(); // Aug 26, 2023 15:37:25
  let startDate = new Date(startShiftDate).getTime(); // Aug 24, 2023, 21:31:00
  // Get todays date and time
  let now = new Date().getTime();

  // Find the distance between now and the count down date
  let distanceWhole = countDownDate - startDate;
  let distanceLeft = countDownDate - now;

  // Time calculations for minutes and percentage progressed
  let minutesLeft = Math.floor(distanceLeft / (1000 * 60));
  let minutesTotal = Math.floor(distanceWhole / (1000 * 60));
  let progress = Math.floor(
    ((minutesTotal - minutesLeft) / minutesTotal) * 100
  );
  return progress;
};
export const checkBreakTimeConsume = (
  data: any,
  break_allowance: any
): boolean => {
  let pendingBreak = false;
  let breakCount = 0;
  let todayDate = dayjs().format("YYYY-MM-DD");
  data?.forEach((item: Break, key: number) => {
    const date1 = dayjs(todayDate + " " + item?.end_date_time);
    let pedingBreakTime = date1.diff(
      todayDate + " " + item?.start_date_time,
      "m"
    );
    breakCount += pedingBreakTime;
  });
  if (breakCount >= break_allowance) {
    pendingBreak = true;
  }
  return pendingBreak;
};

export const replaceText = (text: string, obj: any, start = "{", end = "}") => {
  return text.replace(
    new RegExp(`${start}(.+?)${end}`, "g"),
    (_: any, part: any) => {
      return part.split(".").reduce((o: any, k: any) => (o || {})[k], obj);
    }
  );
};
export const timeDifference = (
  notification_type: any,
  startdateTime: any,
  endDateTime: any
) => {
  // console.log(notification_type,startdateTime, endDateTime);
  const date1 = dayjs(startdateTime);
  // const date1 = dayjs("2023-08-24 21:39:00");
  const date2 = dayjs(endDateTime);
  // const date2 = dayjs("2023-08-24 21:37:00");
  let totalSeconds = date1.diff(date2, "s");
  // totalSeconds = dayjs().diff(user.appointment, 'second');

  let totalHours = Math.floor(totalSeconds / (60 * 60));
  totalSeconds = totalSeconds - totalHours * 60 * 60;

  let totalMinutes = Math.floor(totalSeconds / 60);
  totalSeconds = totalSeconds - totalMinutes * 60;
  return totalHours + " hours " + totalMinutes + " minutes";
};

export const getUserAgent = (userAgentString: string) => {
  // const userAgentString = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36";
  // const userAgentString = "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1";
  let platform = "";
  const parts = userAgentString.split(") ");

  // Extracting browser information
  const browser = parts[parts.length - 2];
  const browserVersion = parts[parts.length - 1];

  // Extracting platform information
  // const platform = parts[0].split('(')[1];

  // Extracting engine information
  const engine = parts[parts.indexOf("AppleWebKit/537.36")];
  const engineVersion = parts[parts.indexOf("Safari/537.36")];

  const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
    userAgentString.toLowerCase()
  );
  const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(
    userAgentString.toLowerCase()
  );

  if (isMobile) {
    platform = "Mobile";
  } else if (isTablet) {
    platform = "Tablet";
  } else {
    platform = "Desktop";
  }
  return {
    browser,
    browserVersion,
    platform,
    engine,
    engineVersion,
  };
};

export const generateRandomId = () => {
  
  let storedID = localStorage.getItem("randomID");

  // If no ID exists, generate one and store it in localStorage
  if (!storedID) {
    storedID = uuidv4();
    localStorage.setItem("randomID", storedID);
  }
}