import React, { Fragment, memo } from "react";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { AiOutlineStop } from "react-icons/ai";
import {
  EarlyStartButton,
  LateStartButton,
  StartButton,
  StartBreak,
  EndShift,
  EndBreak,
} from "components/shift";
import { Break, Shift } from "types";
import { LineProgressBar, PopoverMenu } from "components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Skeleton from "react-loading-skeleton";
import { HiOutlineEye } from "react-icons/hi";
import { TbArrowBadgeUp, TbArrowsExchange } from "react-icons/tb";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

dayjs.extend(relativeTime); // Extend dayjs with the relativeTime plugin

export interface Props {
  state: any;
  progressvalue: number;
  shiftBreaks: Break[] | undefined;
  item: Shift | undefined;
  visibleProgress: string[];
  shiftDatetime: string;
  submitHandler: (value: string) => void;
  endShiftHandler: (value: string) => void;
  startShiftBreakHandler: (value: string) => void;
  endShiftBreakHandler: (value: string) => void;
}

const ButtonDisplayLogic = memo((props: Props) => {
  const {
    state,
    visibleProgress,
    progressvalue,
    shiftBreaks,
    item,
    shiftDatetime,
    submitHandler,
    endShiftHandler,
    startShiftBreakHandler,
    endShiftBreakHandler,
  } = props;

  const filterNotEndedBreak = (date: string): boolean => {
    return !date;
  };

  const notEndedBreak = shiftBreaks?.filter((s) =>
    filterNotEndedBreak(s?.end_date_time)
  )[0];

  return (
    <Box>
      <Typography fontSize={15} fontWeight={400} height={30} textAlign="center">
        {state.shiftStartText && shiftDatetime ? (
          <Typography>
            {state.shiftFlag !== "5"
              ? ` ${state.shiftStartText} ${dayjs(
                  item?.actual_start_time
                    ? item?.actual_start_time.replace("T", " ")
                    : shiftDatetime
                ).fromNow()}`
              : null}
            {state.shiftFlag === "5"
              ? ` ${state.shiftStartText} ${dayjs(
                  item?.target_date + " " + notEndedBreak?.start_date_time
                ).fromNow()}`
              : null}
          </Typography>
        ) : (
          <Skeleton width={100} height={10} />
        )}
      </Typography>

      {visibleProgress.includes(state.shiftFlag) && (
        <Box sx={{ mb: 1.5 }}>
          <LineProgressBar
            completed={progressvalue}
            breaks={shiftBreaks}
            item={item}
          />
        </Box>
      )}

      <Stack justifyContent={"center"} alignItems={"center"} gap={1}>
        {item?.actual_start_time ? (
          <Box sx={{ "& > *:not(:last-child)": { marginRight: 2 } }}>
            {state.shiftFlag === "5" && (
              <EndBreak endShiftBreakHandler={endShiftBreakHandler} />
            )}
            {(state.shiftFlag === "6" ||
              state.shiftFlag === "4" ||
              state.shiftFlag === "9") && (
              <StartBreak startShiftBreakHandler={startShiftBreakHandler} />
            )}
            {(state.shiftFlag === "6" ||
              state.shiftFlag === "8" ||
              state.shiftFlag === "9") && (
              <EndShift endShiftHandler={endShiftHandler} />
            )}
          </Box>
        ) : state.shiftFlag === "3" ? (
          <LateStartButton submitHandler={submitHandler} />
        ) : state.shiftFlag === "2" ? (
          <StartButton submitHandler={submitHandler} />
        ) : (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <EarlyStartButton submitHandler={submitHandler} />
          </Box>
        )}
        {["0", "1", "3"].includes(state.shiftFlag) && (
          <PopoverMenu
            btnChild={
              <Box
                sx={{
                  backgroundColor: "#F6F6F6",
                  py: { xs: 0.5, xl: 2.4 },
                  px: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                  borderRadius: "10px",
                  width: {
                    xs: "1em",
                    sm: "1.2em",
                    md: "1.5em",
                    lg: "2.0em",
                    xl: "2.4em",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                  transition: "background-color 0.3s",
                  "&:hover, &:active": {
                    backgroundColor: "#ADD8E6",
                  },
                }}
              >
                <PiDotsThreeOutlineVerticalFill color="#626262" />
              </Box>
            }
            menuId={`start-menu`}
            btnId={`start-button`}
            btnSx={{ fontSize: 23, p: 0.5 }}
            menuChild={() => (
              <Fragment>
                <MenuItem>
                  <HiOutlineEye />
                  View Shift Details
                </MenuItem>
                <MenuItem>
                  <TbArrowsExchange />
                  Swap Shift
                </MenuItem>
                <MenuItem>
                  <TbArrowBadgeUp />
                  Offer Shift
                </MenuItem>
                <MenuItem>
                  <AiOutlineStop />
                  Can’t make it
                </MenuItem>
              </Fragment>
            )}
          />
        )}
      </Stack>
    </Box>
  );
});

export default ButtonDisplayLogic;