import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Stack,Box } from "@mui/material";
import { PopoverMenu } from "components";
import { NotificationIcon1 } from "assets/svg";
import { NotificationIcon1Active } from "assets/svg";
import supabase from "supabase/supabaseClient";
import { UserContext } from "context/UserContext";
import { getNotifications } from "api/notification";
import { Notification } from "types";
import NotificationCard from "./NotificationCard";
import { useHistory } from "react-router-dom";
import { updateNotification } from "api/notification";
const HeaderNotifications = () => {
  const history = useHistory();
  const user = useContext(UserContext);
  const [showBadge, setShowBadge] = useState(false);
  const [showCount, setShowCount] = useState(0);
  const [dataNotification, setDataNotification] = useState<Notification[]>([]);
  const { data: notifications, refetch: refetchNotifications } = useQuery<
    Notification[]
  >({
    queryKey: ["notifications-list", user?.id],
    enabled: !!user.id,
    queryFn: () => getNotifications(user?.id),
  });
  useEffect(() => {
    if (notifications) {
      setDataNotification(notifications);
    }
  }, [notifications]);
  useEffect(() => {
    const check_notification = supabase.channel("check_notification");
    check_notification.on(
      "postgres_changes",
      { event: "*", schema: "public", table: "notifications" },
      (payload: any) => {
        if (payload?.new?.user_id === user.id) {
          refetchNotifications();
        }
      }
    );
    check_notification.on("presence", { event: "sync" }, () => {
      let counts = 0;
      dataNotification?.map((acc: any, curr: any): number => {
        if (acc.is_read === false) {
          counts = counts + 1;
        }
        return counts;
      });
      if(counts > 0) {
        setShowBadge(true);
      }
      setShowCount(counts);
    });
    check_notification.subscribe(async (status) => {});
    return () => {
      check_notification.unsubscribe();
    };
  }, [user.id, refetchNotifications, showCount, dataNotification]);

  // const filterCountShift = (
  //   user_id: string
  // ): boolean => {
  //   console.log("user_id", user_id);
  //   return (
  //     (user_id !== null)
  //   );
  // };

  // const previousShifts = notifications?.filter(
  //   (s) =>
  //     !filterCountShift(
  //       s?.user_id
  //     )
  // );

  const onSuccess = () => {
    history.push("/my-shifts");
  };
  const { mutate: mutateUpdateNotification } = useMutation(
    ["update-notification"],
    updateNotification,
    { onSuccess }
  );
  // console.log("notifications", notifications);
  // console.log("total", total);
  return (
    <PopoverMenu
      menuId="header-notifications-menu"
      btnId="header-notifications-button"
      menuSx={{
        "& .MuiPaper-root": {
          // width: 420,
          marginTop: 0.5,
          borderRadius: "15px",
          boxShadow: "0 4px 4px #00000020",
          
        },
        "& .MuiMenu-list": { p: 0, ".MuiMenuItem-root": { p: 0 } },
      }}
      btnSx={{
        minWidth: 0,
        width: 32,
        height: 32,
        borderRadius: "50%",
        p: 1,
        backgroundColor: ' #F5F5F5',
     
      }}
      btnChild={
        <Box
        sx={{
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "5px", // Add 5px of padding
        }}
      >
        {(() => {
          if (showBadge === true) {
            return <NotificationIcon1Active width="16" height="16" />;
          } else {
            return <NotificationIcon1 width="16" height="16" />;
          }
        })()}
      </Box>

      }
      menuChild={(closePopover) => (
        <div>
          <Stack
            key={2}
            onClick={() => closePopover()}
            direction="column"
            gap={1}
            py={1}
            pl={1}
            pr={0.5}
            overflow={"scroll"}
            height={"295px"}
            // pb={2.5}
          >
            {dataNotification?.map((notification, i) => (
              <NotificationCard
                notification={notification}
                i={i}
                key={i}
                {...{ mutateUpdateNotification }}
              />
            ))}
          </Stack>
        </div>
      )}
    />
  );
};
export default HeaderNotifications;
