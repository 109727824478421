// import { ReactElement } from "react";
import { Button, SxProps, buttonClasses } from "@mui/material";
// import { BsArrowRightCircle, BsCheckCircle } from "react-icons/bs";

import { RecordStatus } from "types";
// import { PendingIcon } from "assets/svg";
import { getStatusColor, getStatusBackgroundColor } from "utils/records-helpers";

//

// const statusIcons: { [status in RecordStatus]: ReactElement | null } = {
// 	ongoing: <BsArrowRightCircle />,
// 	approved: <BsCheckCircle />,
// 	pending: <PendingIcon />,
// 	unassigned: null,
// 	active: null,
// };

const StatusBtn = (props: { sx?: SxProps; status: RecordStatus; }) => (
	<Button
		disableRipple
		variant="contained"
		// startIcon={statusIcons[props.status]}
		sx={{
			// width: { xs: "100%", sm: '100%' },
			minHeight: { xs: 30, sm: 26.5 },
			"&, &:hover": { bgcolor: getStatusColor(props.status), boxShadow: "none" },
			[`.${buttonClasses.startIcon}`]: { mr: 0.65, svg: { width: 14, height: 14 } },
			textTransform: "uppercase",
			borderRadius: "50px",
			fontWeight: 600,
			fontSize: 11,
			py: 0,
			px: 2,
			color: getStatusBackgroundColor(props.status),
			...props.sx,
		}}
	>
		{props.status}
	</Button>
);

export default StatusBtn;
