import { Box, Stack, Typography } from "@mui/material";
import { HiClock } from "react-icons/hi";

import { WalletIcon } from "assets/svg";
import { timeWithDayjs } from "utils/data-helpers";
import { getShiftStatus, getStatusColor } from "utils/records-helpers";

//

const VerticalScheduleCard = ({ shift }: { shift: any }) => {
  const status = getShiftStatus(shift);
  const statusColor = getStatusColor(status);

  const timeRange = (
    <Stack
      alignItems="center"
      justifyContent={{ sm: "center" }}
      sx={{ gap: "2px", svg: { width: 10, height: 10, color: "#3F41494D" } }}
    >
      <HiClock />
      <Typography color="#253334E5" fontSize={9} lineHeight="12.3px">
        {timeWithDayjs(shift?.starting_time).format("hh:ss")}
        {" - "}
        {timeWithDayjs(shift?.ending_time).format("hh:ss")}
      </Typography>
    </Stack>
  );

  // jsx..

  return (
    <Stack
		height="112px"
      direction="column"
      justifyContent="space-around"
      borderRadius={{ xs: "8px", sm: "5px" }}
      border={{ xs: `1px solid ${statusColor}`, sm: "none" }}
      borderTop={{ sm: `6px solid ${statusColor}` }}
      bgcolor={`${statusColor}30`}
      textAlign={{ sm: "center" }}
      sx={{
        // mx: "auto",
        // px: 0.5,
        // pb: { xs: 3, sm: 3.5 },
        // pt: 3,
        userSelect: "none",
      }}
    >
      <Stack
        flex={{ xs: 1, sm: "auto" }}
        direction="column"
        justifyContent="space-evenly"
        gap={0.625}
      >
        <Box display={{ xs: "none", sm: "block" }}>{timeRange}</Box>

        <Typography fontSize={12} fontWeight={600} lineHeight="16.4px">
          {shift?.title}
        </Typography>

        <Stack
          alignItems="center"
          justifyContent={{ sm: "center" }}
          sx={{
            gap: "2.4px",
            svg: { width: 9, height: 9, color: "#3F41494D" },
          }}
        >
          <WalletIcon />
          <Typography fontSize={8} lineHeight="11px">
            ${shift?.total_price}
          </Typography>
        </Stack>

        <Box display={{ xs: "block", sm: "none" }}>{timeRange}</Box>
      </Stack>

      {/* <Box display={{ xs: "none", sm: "block" }} mt={2.25}> */}
      <Box mt={{ xs: 1, sm: 2.25 }}>
        <Typography
          color="#25333466"
          fontSize={8}
          fontWeight={700}
          textTransform="capitalize"
          lineHeight="11px"
        >
          {status}
        </Typography>
      </Box>
    </Stack>
  );
};

export default VerticalScheduleCard;
