import { ReactElement } from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Import Inter font
import 'typeface-inter';

// =====================================================>>

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		xxs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
	}

	interface PaletteOptions {
		app: { greyblue: string };
	}
}

//

const MuiTheme = createTheme({
	breakpoints: { values: { xs: 0, xxs: 380, sm: 600, md: 900, lg: 1200, xl: 1536 } },
	palette: { primary: { main: "#007AE5" }, app: { greyblue: "#9BA1CC" }, secondary: { main: "#FBB328" } },
	typography: {
		fontFamily: [
			"Inter",
			"Nunito",
			"Roboto",
			'"Helvetica Neue"',
			'"Segoe UI"',
			"-apple-system",
			"BlinkMacSystemFont",
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				"*": { fontFamily: "'Inter', sans-serif", caretColor: "blue" },
				body: { overflow: "hidden" }, // important for app layout. Using inner scroll instead...
				a: { textDecoration: "none" },
			},
		},
		MuiStack: { defaultProps: { direction: "row" } },
		MuiAlert: { defaultProps: { variant: "filled" } },
		MuiDialog: { defaultProps: { keepMounted: true } },
		MuiTextField: { defaultProps: { fullWidth: true } },
		MuiFormControl: { defaultProps: { fullWidth: true } },
		MuiMenuItem: { defaultProps: { disableRipple: true } },
		MuiSnackbar: { defaultProps: { autoHideDuration: 2000 } },
		MuiButton: { styleOverrides: { root: { borderRadius: "10px", textTransform: "capitalize" } } },
		MuiCircularProgress: { defaultProps: { thickness: 4 }, styleOverrides: { circle: { strokeLinecap: "round" } } },
	},
});

//

const MuiThemeProvider = (props: { children: ReactElement }) => (
	<ThemeProvider theme={MuiTheme}>
		<CssBaseline />
		{props.children}
	</ThemeProvider>
);

export default MuiThemeProvider;
