import { Link } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { VerifyEmailIcon } from "assets/svg";

const VerificationStep = () => (
	<Stack direction="column" alignItems="center" gap={4} mt={-3} color="#00000099">
		<Box>We sent you a verification Link to verify your email address. Check Example@shifti.com.au and enter the code below.</Box>

		<VerifyEmailIcon />

		<Box>
			By Continuing, You Agree To Our{" "}
			<Box component={Link} to="#" color="primary.main" fontWeight={600}>
				Terms Of Service.
			</Box>
		</Box>
	</Stack>
);

export default VerificationStep;
